<template>
  <loading-screen v-if="showLoadingScreen" :loadingItems="loadingItems" />
  <div class="payments">
    <div class="payments__header">
      <h3 class="payments__heading">طرق الدفع المحفوظة</h3>
      <a class="btn btn-primary btn-wide" @click="isModalVisible = true"
        >إضافة بطاقة جديدة</a
      >
    </div>
    <div class="payments__body grid grid-1x2" v-if="cards">
      <div
        class="payments__payment-card"
        v-for="{ Id, HolderName, Number, IsPreferred, CreditCardType } in cards"
        :key="Id"
      >
        <div class="payment-card__data">
          <div class="payment-card__type">
            <img
              :src="require(`@/assets/images/card-type-${CreditCardType}.png`)"
              alt=""
            />
          </div>
          <div class="payment-card__info">
            <div class="payment-card__name">{{ HolderName }}</div>
            <div class="payment-card__numb">
              {{ Number }}
            </div>
          </div>
        </div>
        <div class="payment-card__actions">
          <div class="payment-card__toggle-default">
            <span>بطاقة افتراضية</span>
            <SwitchBtn @toggle="toggleDefault(Id)" :on="IsPreferred" />
          </div>
          <button class="btn btn-dark" @click="confirmRemoveCard(Id)">
            حذف
          </button>
        </div>
      </div>
    </div>
    <div class="payments__body grid grid-1x2" v-else>
      <!-- loader cards -->
      <div class="payments__payment-card">
        <div class="payment-card__data">
          <div class="payment-card__type">
            <img :src="require(`@/assets/images/card-type-0.png`)" alt="" />
          </div>
          <div class="payment-card__info">
            <div class="payment-card__name">
              <skeleton-text effect="wave">mohamed </skeleton-text>
            </div>
            <div class="payment-card__numb">
              <skeleton-text effect="wave">123456789123456</skeleton-text>
            </div>
          </div>
        </div>
        <div class="payment-card__actions">
          <div class="payment-card__toggle-default">
            <span>بطاقة افتراضية</span>
            <SwitchBtn @toggle="toggleDefault(Id)" :on="IsPreferred" />
          </div>
          <div class="btn btn-dark">حذف</div>
        </div>
      </div>
      <div class="payments__payment-card">
        <div class="payment-card__data">
          <div class="payment-card__type">
            <img :src="require(`@/assets/images/card-type-1.png`)" alt="" />
          </div>
          <div class="payment-card__info">
            <div class="payment-card__name">
              <skeleton-text effect="wave">mohamed </skeleton-text>
            </div>
            <div class="payment-card__numb">
              <skeleton-text effect="wave">123456789123456</skeleton-text>
            </div>
          </div>
        </div>
        <div class="payment-card__actions">
          <div class="payment-card__toggle-default">
            <span>بطاقة افتراضية</span>
            <SwitchBtn @toggle="toggleDefault(Id)" :on="IsPreferred" />
          </div>
          <div class="btn btn-dark">حذف</div>
        </div>
      </div>
      <!-- loader cards -->
    </div>
  </div>
  <MModal
    class="modal-add-payment-card"
    v-show="isModalVisible"
    @close="isModalVisible = false"
  >
    <!-- Modal header -->
    <template #header> إضافة بطاقة جديدة </template>

    <!-- Modal body -->
    <template #body>
      <!-- <div
        class="loader"
        style="top: 50%; right: 50%; transform: translate(-50%, -50%)"
        v-if="posting"
      ></div>
       <PaymentCardFormApi
        v-else
        @showModal="isModalVisible = true"
        @submitCard="postCard"
      /> -->
      <PaymentCardForm @showModal="isModalVisible = true" />
    </template>

    <!-- <template #footer>
      <a class="btn btn-info btn-wide" @click="submitForm"> حفظ </a>
    </template> -->
  </MModal>
  <m-modal
    class="modal-warning"
    v-show="showWarningModal"
    @close="showWarningModal = false"
    hasFooter
  >
    <!-- Modal header -->
    <template #header> تأكيد الحذف </template>

    <!-- Modal body -->
    <template #body> هل أنت متأكدة من حذف البطاقة؟ </template>

    <template #footer>
      <div class="modal__action">
        <a class="btn btn-primary" @click="removeCard"> موافق </a>
        <a class="btn btn-dark" @click="showWarningModal = false">
          إلغاء الطلب
        </a>
      </div>
    </template>
  </m-modal>
</template>

<script>
import SwitchBtn from "@/components/common/SwitchBtn.vue";
import MModal from "@/components/common/MModal.vue";
import PaymentCardForm from "@/components/payment/PaymentCardForm.vue";
import PaymentService from "@/services/PaymentService";
import { SkeletonText } from "skeleton-elements/vue";
import getCardType from "@/util/cardType";
import { useMsal } from "@/composition-api/useMsal";
import LoadingScreen from "@/components/common/LoadingScreen.vue";

export default {
  components: {
    SwitchBtn,
    MModal,
    PaymentCardForm,
    LoadingScreen,
    SkeletonText,
  },
  setup() {
    const { accounts } = useMsal();
    return { accounts };
  },
  data() {
    return {
      cards: null,
      isModalVisible: false,
      formSubmited: false,
      showWarningModal: false,
      deleteCardId: null,
      posting: false,
      showLoadingScreen: false,
      loadingItems: [],
    };
  },
  async mounted() {
    this.getPaymentCards();
    const tokenizeResponse = this.$route.query;
    console.log("this is tokenizeResponse:>", tokenizeResponse);
    if (Object.keys(tokenizeResponse).length) {
      this.handleTokenizationQuery(tokenizeResponse);
    }
  },
  methods: {
    getCardType(number) {
      return getCardType(number);
    },
    async toggleDefault(Id) {
      this.cards = this.cards
        .map((c) => {
          if (c.IsPreferred) {
            c.IsPreferred = false;
          }
          return c;
        })
        .map((card) => {
          if (card.Id == Id) {
            card.IsPreferred = !card.IsPreferred;
          }
          return card;
        });
      const status = await PaymentService.setPreferredCard(Id);
      // don't forget the fail scinario
    },
    confirmRemoveCard(Id) {
      this.deleteCardId = Id;
      this.showWarningModal = true;
    },
    async removeCard() {
      const status = await PaymentService.removePaymentCard(this.deleteCardId);
      if (status == 200) {
        this.getPaymentCards();
        this.$toast.success("تم مسح البطاقة بنجاح!");
      }
      this.showWarningModal = false;
    },
    async postCard(cardData) {
      this.posting = true;
      const response = await PaymentService.postCard(cardData);
      this.isModalVisible = false;
      if (response.status == "18") {
        this.getPaymentCards();
        this.$toast.success("تم إضافة البطاقة بنجاح!");
      } else if (response.status == "00") {
        this.isModalVisible = true;
        this.errorMessage = response.response_message;
      }
      this.posting = false;
    },
    async handleTokenizationQuery(tokenizeResponse) {
      const { status, response_message } = tokenizeResponse;
      this.$router.replace({ query: null });
      this.showLoadingScreen = true;
      this.loadingItems = [
        { status: "doing", text: "ارسال بيانات البطاقة إلى بوابة الدفع" },
        { status: "hold", text: "تأكيد بيانات البطاقة مع دفاتر" },
      ];
      if (status == "18") {
        this.loadingItems = [
          { status: "done", text: "ارسال بيانات البطاقة إلى بوابة الدفع" },
          { status: "doing", text: "تأكيد بيانات البطاقة مع دفاتر" },
        ];
        let {
          token_name: alias,
          card_number,
          expiry_date: expiryDate,
          card_holder_name: holderName,
          card_bin,
        } = tokenizeResponse;
        // const cardNumber = `${card_number.slice(6)}${card_bin}`; //put card_bin then ********
        let cardData = {
          alias,
          holderName: holderName,
          number: card_number.replace(card_bin, "******"),
          expiryDate,
          CreditCardType: getCardType(card_bin),
        };

        const response = await PaymentService.postPaymentCard(cardData);
        if (response == 200) {
          this.getPaymentCards();
          setTimeout(() => {
            this.$toast.success("تم إضافة البطاقة بنجاح!");
            this.loadingItems = [
              {
                status: "done",
                text: "ارسال بيانات البطاقة إلى بوابة الدفع",
              },
              { status: "done", text: "تأكيد بيانات البطاقة مع دفاتر" },
            ];
          }, 1000);
          setTimeout(() => {
            this.showLoadingScreen = false;
          }, 1500);
        } else {
          this.showLoadingScreen = false;
          console.log("setpaymentcard error:>", response);
          this.$toast.error("نأسف لوجود مشكلة");
          this.isModalVisible = true;
        }
      } else if (status == "00") {
        this.isModalVisible = true;
        this.showLoadingScreen = false;
        this.$toast.error("نأسف لوجود مشكلة");
        console.log("tokenization error:>", response_message);
        this.errorMessage = response_message;
      }
    },
    async getPaymentCards() {
      this.cards = await PaymentService.getSavedCards();
    },
    async setPaymentCard(cardData) {
      const response = await PaymentService.postPaymentCard(cardData);
      return response;
    },
    async pay() {
      //pay to payfort by send this data to
      const transactionResponse = await PaymentService.payToGateway(amount);
      // const res = await PaymentService.depositBalance(amount, transactionId);
      // console.log(res);
    },
  },
};
</script>

<style>
</style>