<template>
  <div class="copy-url-comp">
    <label> {{ label }} </label>
    <div class="link-copy__group">
      <div class="link-copy__input">
        <input type="text" v-show="url" :value="url" disabled />
        <input
          type="text"
          v-show="!url"
          value="please provide a Url there is no any one"
          disabled
        />
        <a @click="copyUrl(url)" v-if="!withoutBtn" class="btn btn-primary"
          >نسخ</a
        >
      </div>
    </div>
  </div>
</template>

<script>
async function copyp() {}
export default {
  props: {
    url: String,
    label: String,
    withoutBtn: Boolean,
  },
  methods: {
    async copyUrl(url) {
      try {
        navigator.clipboard.writeText(url);
        return await navigator.clipboard.readText();
      } catch (err) {
        console.log("from value", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 1.6rem;
  font-weight: bold;
}
.link-copy {
  &__group {
    margin-top: 0.8rem;
    margin-bottom: 3rem;
  }
  &__input {
    display: flex;
    background-color: #fff;
    padding: 1px;
    border: 1px solid #bbbbbb;
    border-radius: 4px;
    max-width: 45rem;
    input {
      border: 0;
      font-size: 1.7rem;
      color: #acacac;
      // background-color: #000;
      padding-right: 2rem;
      padding-left: 2rem;
      overflow-x: hidden;
      text-overflow: ellipsis;
      direction: ltr;
    }
    .btn {
      padding: 0.6rem 2rem;
      font-size: 1.3rem;
      font-weight: bold;
      display: flex;
      align-items: center;
    }
  }
}
</style>