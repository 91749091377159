<template>
  <div class="navbar">
    <a @click="$emit('side-toggle')" class="navbar__side-toggle">
      <i class="fas fa-bars"></i>
    </a>
    <router-link to="/" class="navbar__logo">
      <img src="@/assets/images/logo.png" alt="Dafater Sa" />
    </router-link>
    <div class="navbar__links">
      <router-link to="/support" class="navbar__support-link">
        <i class="fas fa-phone"></i>
        <span>الدعم الفني</span>
      </router-link>
      <div class="navbar__profile">
        <a @click="dropMenu = !dropMenu" class="navbar__profile-link">
          <!-- <div class="profile__avatar">
            <img src="@/assets/images/avatar.jpeg" :alt="user.name" />
            <span class="profile__avatar--online"></span>
          </div> -->
          <span v-if="!accounts[0]">{{ user.name }}</span>
          <span v-else>{{
            accounts[0].idTokenClaims.extension_CompanyName
          }}</span>
          <i
            class="fas fa-chevron-down"
            :style="{ transform: dropMenu ? `rotate(180deg)` : `rotate(0deg)` }"
          ></i>
        </a>
        <div
          :class="
            dropMenu
              ? 'navbar__profile-dropdown opend'
              : 'navbar__profile-dropdown'
          "
        >
          <router-link to="/subscriptions/profile" class="dropdown__link">
            <i class="fas fa-address-card"></i>
            حسابي الشخصي
          </router-link>
          <a @click="logout" class="dropdown__link">
            <i class="fas fa-door-open"></i>
            تسجيل الخروج
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMsal } from "@/composition-api/useMsal";
import { loginRequest } from "@/authConfig";
import Service from "@/services/AccountService";
import SubscriptionService from "@/services/SubscriptionService";
export default {
  setup() {
    const { instance, accounts, inProgress } = useMsal();
    return {
      instance,
      accounts,
      inProgress,
    };
  },
  emits: ["side-toggle"],
  data() {
    return {
      user: {
        name: "محمد عبدالمنعم",
      },
      dropMenu: false,
    };
  },
  mounted() {
    // this.getUserData(); // uncomment this to make signup but I make it in guard
    // console.log(this.accounts[0]);\
  },
  watch: {
    $route() {
      this.dropMenu = false;
    },
  },
  methods: {
    logout() {
      sessionStorage.removeItem("signed");
      this.instance.logoutRedirect();
    },
    // async getUserData() {
    //   this.user = this.accounts[0];

    //   if (this.accounts.length > 0) {
    //     const { idTokenClaims, name, tenantId, localAccountId } =
    //       this.accounts[0];
    //     if (idTokenClaims.newUser) {
    //       this.user = {
    //         Email: idTokenClaims.emails[0],
    //         NameIdentifier: localAccountId,
    //         UserName: name,
    //         CompanyName: idTokenClaims.extension_CompanyName,
    //         Mobile: "0" + idTokenClaims.extension_PhoneNumber,
    //         CountryCode: idTokenClaims.country.slice(0, 2).toUpperCase(),
    //         Tinumber: tenantId,
    //       };
    //       const profile = await Service.signUp(this.user);
    //       if (profile) {
    //         this.instance.loginRedirect(loginRequest);
    //       }
    //     } else {
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss">
.dropdown__link {
  color: #fff !important;
}
</style>
