<template>
  <div class="form-step">
    <!-- <div class="duration">
      <div class="duration-tabs">
        <a
          @click="duration('annaul')"
          ref="annaul"
          class="btn duration-tab active"
        >
          سنوي
        </a>
        <a @click="duration('monthly')" ref="monthly" class="btn duration-tab">
          شهري
        </a>
        <div class="duration-off" v-show="showOff">
          <span class="c-primary">وفر 30%</span>
          <img src="@/assets/images/up-arrow.png" alt="" />
        </div>
      </div>
    </div> -->
    <div class="container flex-col text-center">
      <label for="coupon" class="c-primary mb-0 mt-3 fw-n fs-18"
        >لديك رمز ترويجي!</label
      >
      <div class="form-group">
        <input
          type="text"
          id="coupon"
          style="width: 30%; min-width: 20rem; margin: 3rem auto"
          ref="searchField"
          @keyup="onSearching"
        />
        <button
          class="btn btn-primary"
          type="button"
          @click="getCouponPlan($refs.searchField)"
        >
          ابحث
        </button>
      </div>

      <div
        v-if="
          (plansAcceptedCoupon == null || !plansAcceptedCoupon.length) &&
          (couponCode != null || couponCode != '') &&
          startedTyping
        "
        class="c-danger"
      >
        عفواً لايوجد باقة لهذا الرمز الترويجي
      </div>
      <div class="packages">
        <template v-if="!allPlans && startedTyping">
          <div class="loader" style="font-size: 1.2rem; margin-top: 1rem"></div>
        </template>
        <template v-else>
          <div
            class="package-card single mx-2"
            v-for="(plan, i) in plansAcceptedCoupon"
            :key="i"
          >
            <div class="package-card__header">
              <h3 class="mb-0">{{ plan.Name.ArabicValue }}</h3>
            </div>
            <div class="package-card__body">
              <!-- <h4>السنة الأولى</h4>
            <div>
              <div class="package-card__price">
                <span class="price">6,100</span>
                <span>{{ getCurrency() }}</span>
              </div>
              <p>
                التجديد
                <span class="fw-bl">2,135</span>
                {{ getCurrency() }}/سنواياً
              </p>
            </div> -->
            </div>
            <div class="package-card__action">
              <button
                v-if="servicePlanId == plan.Id"
                class="btn btn-info btn-wide"
                disabled
              >
                تم اختيار الباقة
              </button>
              <button
                v-else
                class="btn btn-primary btn-wide"
                @click="subscribe(plan.Id, couponCode)"
                type="button"
              >
                اشترك الآن
              </button>
              <!-- hide plan advantage for now -->
              <!-- <div class="mt-2">
            <i class="fas fa-info-circle"></i>
            <a class="link mx-1 c-text" @click="openAdvantageModal(basicPlanId)"
              >مزايا الباقة</a
            >
          </div> -->
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="packages" v-if="!egypt">
      <div class="package-card">
        <div class="package-card__header">
          <h3 class="mb-0">ERP Basic</h3>
        </div>
        <div class="package-card__body">
          <h4>السنة الأولى</h4>
          <div>
            <div class="package-card__price">
              <span class="price">6,100</span>
              <span>{{ getCurrency() }}</span>
            </div>
            <p>
              التجديد
              <span class="fw-bl">2,135</span>
              {{ getCurrency() }}/سنواياً
            </p>
          </div>
        </div>
        <div class="package-card__action">
          <button
            v-if="servicePlanId == basicPlanId"
            class="btn btn-info btn-wide"
            disabled
          >
            تم اختيار الباقة
          </button>
          <button
            v-else
            class="btn btn-primary btn-wide"
            @click="subscribe(basicPlanId)"
            type="button"
          >
            اشترك الآن
          </button>
          <!-- hide plan advantage for now -->
          <!-- <div class="mt-2">
            <i class="fas fa-info-circle"></i>
            <a class="link mx-1 c-text" @click="openAdvantageModal(basicPlanId)"
              >مزايا الباقة</a
            >
          </div> -->
        </div>
      </div>
      <div class="package-card common">
        <div class="package-card__cap bg-text text-center">الأكثر استخدامآ</div>
        <div class="package-card__header">
          <h3 class="mb-0 c-primary">ERP Advanced</h3>
        </div>
        <div class="package-card__body">
          <h4>السنة الأولى</h4>
          <div>
            <div class="package-card__price">
              <span class="price">18,600</span>
              <span>{{ getCurrency() }}</span>
            </div>
            <p>
              التجديد
              <span class="fw-bl">5,010</span>
              {{ getCurrency() }}/سنواياً
            </p>
          </div>
        </div>
        <div class="package-card__action">
          <button
            disabled
            class="btn btn-info btn-wide"
            v-if="servicePlanId == advancedPlanId"
          >
            تم اختيار الباقة
          </button>
          <button
            v-else
            class="btn btn-primary btn-wide"
            @click="subscribe(advancedPlanId)"
            type="button"
          >
            اشترك الآن
          </button>
          <!-- hide plan advantage for now -->
          <!-- <div class="mt-2">
            <i class="fas fa-info-circle"></i>
            <a
              class="link mx-1 c-text"
              @click="openAdvantageModal(advancedPlanId)"
              >مزايا الباقة</a
            >
          </div> -->
        </div>
      </div>
      <div class="package-card">
        <div class="package-card__header">
          <h3 class="mb-0">ERP Enterprise</h3>
        </div>
        <div class="package-card__body">
          <p>يمكنك اضافة العديد من المزايا المخصصة لك</p>
        </div>
        <div class="package-card__action">
          <a
            style="display: block"
            class="btn btn-primary btn-wide"
            href="https://dafater.sa/try-now-enterprise/"
            target="_blank"
          >
            تواصل معنا
          </a>
        </div>
      </div>
    </div>
    <div class="packages" v-else>
      <div class="package-card single">
        <div class="package-card__header">
          <h3 class="mb-0">الأساسية</h3>
        </div>
        <div class="package-card__body">
          <h4>السنة الأولى</h4>
          <div>
            <div class="package-card__price">
              <span class="price">3,600</span>
              <span>{{ getCurrency() }}</span>
            </div>
            <p>
              التجديد
              <span class="fw-bl">2,135</span>
              {{ getCurrency() }}/سنواياً
            </p>
          </div>
        </div>
        <div class="package-card__action">
          <button
            v-if="servicePlanId == basicPlanId"
            class="btn btn-info btn-wide"
            disabled
          >
            تم اختيار الباقة
          </button>
          <button
            v-else
            class="btn btn-primary btn-wide"
            @click="subscribe(basicPlanId)"
            type="button"
          >
            اشترك الآن
          </button>
          <!-- <div class="mt-2">
            <i class="fas fa-info-circle"></i>
            <a class="link mx-1 c-text" @click="openAdvantageModal(basicPlanId)"
              >مزايا الباقة</a
            >
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <AdvantageModal
    :showModal="showAdvantageModal"
    :features="features"
    :loading="isFeatureLoading"
    @close="showAdvantageModal = false"
  />
  <button
    class="toggle btn btn-primary"
    @click="showPlanIdForm = false"
    style="display: none"
    type="button"
  >
    planId
  </button>
  <m-modal v-show="showPlanIdForm" @close="showPlanIdForm = false">
    <template #header>أضف رقم الباقة</template>
    <template #body>
      <input type="text" v-model="planId" @change.prevent="changePlanId" />
      <button class="btn btn-info" @click="subscribe(planId)">اشترك</button>
    </template>
  </m-modal>
</template>

<script>
import AdvantageModal from "./AdvantageModal.vue";
import SubscriptionService from "@/services/SubscriptionService";
import MModal from "../common/MModal.vue";
import { getCurrency, getCountry } from "@/util/utils";
export default {
  components: {
    AdvantageModal,
    MModal,
  },
  data() {
    return {
      showOff: true,
      servicePlanId: null,
      basicPlanId: 154,
      advancedPlanId: 159,
      showAdvantageModal: false,
      features: null,
      isFeatureLoading: false,
      planId: null,
      showPlanIdForm: false,
      egypt: false,
      allPlans: null,
      couponCode: null,
      plansAcceptedCoupon: null,
      startedTyping: false,
    };
  },
  watch: {
    servicePlanId(newValue) {
      this.$emit("getSelectedPlanId", newValue);
    },
    egypt(newValue) {
      this.basicPlanId = newValue ? 128 : 154;
    },
  },
  mounted() {
    if (getCountry() == "Egypt") {
      this.egypt = true;
    }
    this.getAllPlans();
  },
  methods: {
    async getAllPlans() {
      this.allPlans = await SubscriptionService.getServicePlans();
      // console.log("thsi is plans:", this.allPlans);
      // const result = this.allPlans.filter(
      //   (plan) => plan.HasPromotionCode && plan.TrialPlan
      // );
      // console.log("thsi is the result from allplans:>", result);
    },
    getCouponPlan(c) {
      console.log("thsi si c:>", c.value);
      this.startedTyping = true;
      this.couponCode = c.value;
      const plans = this.allPlans.filter((plan) =>
        plan.PromotionCodes.some((pc) => pc.Code == this.couponCode)
      );
      this.plansAcceptedCoupon = plans;
      console.log("this is plans:>", plans);
    },
    onSearching(c) {
      this.startedTyping = false;
      this.plansAcceptedCoupon = null;
    },
    changePlanId(id) {
      console.log(id);
    },
    duration(dur) {
      switch (dur) {
        case "annaul":
          this.showOff = true;
          this.$refs["monthly"].classList.remove("active");
          this.$refs["annaul"].classList.add("active");
          break;
        case "monthly":
          this.showOff = false;
          this.$refs["annaul"].classList.remove("active");
          this.$refs["monthly"].classList.add("active");
          break;
      }
    },
    subscribe(planId, couponCode = null) {
      //here add planId to the url
      couponCode ? this.$emit("setCouponCode", couponCode) : "";
      this.$router.push(`/subscribe/creation/${planId}`);
      // window.href = `/subscribe/creation/${planId}`
      // this.$emit("getSelectedPlanId", planId);
      // this.servicePlanId = planId;
    },
    async getServiceFeatures(servicePlanId) {
      this.showAdvantageModal = true;
      this.isFeatureLoading = true;
      const servicePlan = await SubscriptionService.getServicePlanDetails(
        servicePlanId
      );
      this.features = servicePlan.PlanFeatures;
      this.isFeatureLoading = false;
    },
    openAdvantageModal(id) {
      this.getServiceFeatures(id);
    },
    getCurrency() {
      return getCurrency();
    },
  },
};
</script>
