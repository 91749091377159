<template>
  <div class="container flex-col text-center">
    <label for="url" class="c-primary mb-0 mt-3 fw-n fs-18"
      >تغيير رابط الاشتراك</label
    >
    <div class="form-group">
      <span class="domain">dafater.biz.</span>
      <input
        type="text"
        id="url"
        style="
          width: 30%;
          min-width: 20rem;
          margin: 3rem auto;
          padding-right: 12rem;
        "
        v-model="url"
      />
      <button class="btn btn-primary" type="button" @click="handleSearch">
        ابحث
      </button>
    </div>
    <div class="flex-center flex-col my-2">
      <div class="loader my-3" v-if="loading"></div>
      <div v-if="!loading && exist" class="c-danger my-3">
        عفواً هذا الاسم مستخدم!
      </div>
      <div v-if="!loading && searched && !exist" class="c-success my-3">
        هذا الاسم متاح!
      </div>
      <button
        class="btn btn-info"
        :disabled="loading || !searched || exist"
        @click="showWarningModal = true"
      >
        تفعيل الرابط
      </button>
      <!-- to be changed searched to valid url -->
    </div>
  </div>
  <m-modal
    class="modal-warning"
    v-show="showWarningModal"
    @close="showWarningModal = false"
    hasFooter
  >
    <!-- Modal header -->
    <template #header> تأكيد تفعيل الرابط </template>

    <!-- Modal body -->
    <template #body>
      قد تنقطع الخدمة أثناء هذا التغيير ونوصي بالقيام بذلك بعد ساعات العمل.
    </template>

    <template #footer>
      <div class="modal__action">
        <div
          class="loader"
          style="top: 1rem; left: 4rem"
          v-if="loadingModal"
        ></div>
        <a class="btn btn-primary" v-else @click="applyUrl"> موافق </a>
        <a
          class="btn btn-dark"
          :style="loadingModal ? 'filter: opacity(0.5)' : ''"
          @click="closeModal"
        >
          إلغاء الطلب
        </a>
      </div>
    </template>
  </m-modal>
</template>

<script>
import SubscriptionService from "@/services/SubscriptionService";
import MModal from "@/components/common/MModal.vue";
export default {
  components: { MModal },
  data() {
    return {
      url: null,
      exist: false,
      searched: false,
      loading: false,
      showWarningModal: false,
      loadingModal: false,
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      if (this.loadingModal) return;
      this.showWarningModal = false;
    },
    async handleSearch() {
      if (this.loading || !this.url) return;
      this.loading = true;
      this.searched = true;
      try {
        const response = await SubscriptionService.searchForUrl(this.url);
        if (response.data) {
          this.loading = false;
          this.exist = false;
        } else {
          console.log("from else");
          this.exist = true;
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    async applyUrl() {
      if (((this.loading || !this.searched) && this.exist) || !this.url) return;
      this.loading = true;
      this.loadingModal = true;
      this.searched = false;
      try {
        const response = await SubscriptionService.changeSubscriptionUrl(
          this.$route.params.id,
          this.url
        );
        if (response && response.status == 200) {
          this.loadingModal = false;
          this.loading = false;
          this.$router.push(`/subscriptions/${this.$route.params.id}`);
        } else {
          this.loading = false;
          this.loadingModal = true;
          this.exist = false;
          this.showWarningModal = false;
          this.$toast.error("عفواً هناك خطأ ما!");
        }
      } catch (error) {
        this.loading = false;
        this.loadingModal = false;
        this.exist = true;
        this.$toast.error("عفواً هناك خطأ ما!");
        this.showWarningModal = false;
      }
    },
  },
};
</script>

<style>
.domain {
  padding: 1rem;
  background: #fff;
  margin-left: -12rem;
}
</style>