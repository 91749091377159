<template>
  <div class="profile">
    <div class="profile__colored-header"></div>
    <div class="profile__container">
      <div class="profile__card">
        <!-- <div class="profile__img" v-if="!editting">
          <img
            :src="require('@/assets/images/avatar.jpeg')"
            :alt="profile.Name"
          />
        </div>
        <div class="profile__img profile__img-input" v-if="editting">
          <input
            type="file"
            name="profile_img"
            :disabled="isSaving"
            @change="filesChange($event)"
            accept="image/*"
            class="input-file"
          />
          <img
            :src="require('@/assets/images/avatar.jpeg')"
            :alt="profile.Name"
          />
          <i class="fas fa-camera"></i>
        </div> -->
        <template v-if="!editting">
          <h2
            class="profile__name"
            v-if="Object.keys(profile).length === 0 || loading"
          >
            <skeleton-text effect="wave">Profile name</skeleton-text>
          </h2>
          <p
            class="profile__email"
            v-if="Object.keys(profile).length === 0 || loading"
          >
            <skeleton-text effect="wave">ProfileEmail@email.com</skeleton-text>
          </p>
          <h2 class="profile__name">{{ profile.Name }}</h2>
          <p class="profile__email">{{ profile.Email }}</p>
          <p class="profile__role">
            <!-- دور المستخدم:<span>{{ profile.Roles[0].Name }}</span> -->
          </p>
        </template>
        <div class="profile__action">
          <a @click="editProfile" class="btn btn-info" v-if="!editting"
            >تعديل</a
          >
          <template v-if="editting">
            <a @click="updateAccount" class="btn btn-info">حفظ</a>
            <a @click="editProfile" class="btn btn-dark">إلغاء</a>
          </template>
        </div>
      </div>
      <form class="profile__details">
        <section class="profile__details-section">
          <template v-if="editting">
            <h3 class="details-section__heading">معلومات الحساب</h3>
            <div class="details-section__body">
              <div class="form-group">
                <label for="name">الاسم</label>
                <skeleton-block
                  v-if="Object.keys(profile).length === 0 || loading"
                  width="35rem"
                  height="5rem"
                  effect="wave"
                />
                <input
                  v-else
                  type="text"
                  v-model="profile.Name"
                  @change="changeIn('profile')"
                  :disabled="!editting"
                  :class="editting ? 'active-editting' : ''"
                />
              </div>
              <div class="form-group">
                <label for="name">البريد الالكتروني</label>
                <skeleton-block
                  v-if="Object.keys(profile).length === 0 || loading"
                  width="35rem"
                  height="5rem"
                  effect="wave"
                />
                <input
                  v-else
                  type="text"
                  v-model="profile.Email"
                  @change="changeIn('profile')"
                  :disabled="!editting"
                  :class="editting ? 'active-editting' : ''"
                />
              </div>

              <div class="form-group">
                <label for="name">دور المستخدم</label>
                <skeleton-block
                  v-if="Object.keys(profile).length === 0 || loading"
                  width="35rem"
                  height="5rem"
                  effect="wave"
                />
                <!-- <input
                  v-else
                  type="text"
                  v-model="profile.Roles[0].Name"
                  @change="changeIn('profile')"
                  :disabled="!editting"
                  :class="editting ? 'active-editting' : ''"
                /> -->
                <select
                  v-else
                  v-model="profile.Roles[0].Name"
                  :disabled="!editting"
                  @change="changeIn('profile')"
                  :class="editting ? 'active-editting' : ''"
                >
                  <!-- <option value="" selected disabled hidden>اختر المدينة</option> -->
                  <option v-for="(role, i) in roles" :key="i" :value="i">
                    {{ role.ar }}
                  </option>
                </select>
              </div>
            </div>
            <div class="hr"></div>
          </template>
        </section>
        <section class="profile__details-section">
          <h3 class="details-section__heading">معلومات الشركة</h3>
          <div class="details-section__body">
            <!-- form item -->
            <div class="form-group">
              <label for="name">إسم الشركة</label>
              <skeleton-block
                v-if="Object.keys(Tenant).length === 0 || loading"
                width="35rem"
                height="5rem"
                effect="wave"
              />
              <input
                v-else
                type="text"
                v-model="Tenant.Name"
                @change="changeIn('Tenant')"
                :disabled="!editting"
                :class="editting ? 'active-editting' : ''"
              />
            </div>
            <!-- //form item -->
            <!-- form item -->
            <div class="form-group">
              <label for="name">حجم الشركة</label>
              <skeleton-block
                v-if="Object.keys(Tenant).length === 0 || loading"
                width="35rem"
                height="5rem"
                effect="wave"
              />
              <select
                v-else
                v-model="Tenant.CompanySize"
                @change="changeIn('Tenant')"
                :disabled="!editting"
                :class="editting ? 'active-editting' : ''"
              >
                <option v-for="size in sizes" :key="size" :value="size">
                  {{ size }}
                </option>
              </select>
            </div>
            <!-- //form item -->
            <!-- form item -->
            <div class="form-group">
              <label for="name">مجال العمل</label>

              <skeleton-block
                v-if="Object.keys(Tenant).length === 0 || loading"
                width="35rem"
                height="5rem"
                effect="wave"
              />
              <select
                v-else
                v-model="Tenant.BusinessDomain"
                @change="changeIn('Tenant')"
                :disabled="!editting"
                :class="editting ? 'active-editting' : ''"
              >
                <option
                  v-for="domain in domains"
                  :key="domain"
                  :value="domain.en"
                >
                  {{ domain.ar }}
                </option>
              </select>
            </div>
            <!-- //form item -->
            <!-- form item -->
            <div class="form-group">
              <label for="name">رقم المعرف الضريبي</label>
              <skeleton-block
                v-if="Object.keys(Tenant).length === 0 || loading"
                width="35rem"
                height="5rem"
                effect="wave"
              />
              <input
                v-else
                type="text"
                v-model="Tenant.TINumber"
                @change="changeIn('Tenant')"
                :disabled="!editting"
                :class="editting ? 'active-editting' : ''"
              />
            </div>
            <!-- //form item -->
            <!-- form item -->
            <div class="form-group">
              <label for="name">رقم السجل التجاري</label>
              <skeleton-block
                v-if="Object.keys(Tenant).length === 0 || loading"
                width="35rem"
                height="5rem"
                effect="wave"
              />
              <!-- <input
                v-else
                type="text"
                v-model="Tenant.CrNumber"
                @change="changeIn('Tenant')"
                :disabled="!editting"
                :class="editting ? 'active-editting' : ''"
                pattern="^\d{10}$"
              /> -->
              <FormKit
                v-else
                ref="crNumber"
                type="text"
                :outer-class="`cr-number ${editting ? 'active-editting' : ''}`"
                v-model="Tenant.CrNumber"
                @change="changeIn('Tenant')"
                :disabled="!editting"
                :class="editting ? 'active-editting' : ''"
                :validation="[['matches', /^\d{10}$/]]"
                validation-visibility="live"
                :validation-messages="{
                  matches: 'رقم السجل التجاري غير صحيح',
                }"
              />
              <!-- <span class="error" v-if="!Tenant.CrNumber.match(/^\d{10}$/)">
                من فضلك ادخل رقم سجل تجاري صحيح
              </span> -->
            </div>
            <!-- //form item -->
          </div>
          <div class="hr"></div>
        </section>
        <section class="profile__details-section">
          <h3 class="details-section__heading">
            عنوان الفوترة ومعلومات الاتصال
          </h3>
          <div class="details-section__body">
            <!-- form item -->
            <div class="form-group">
              <label for="name">الدولة</label>
              <skeleton-block
                v-if="Object.keys(TenantAddress).length === 0 || loading"
                width="35rem"
                height="5rem"
                effect="wave"
              />

              <select
                v-else
                v-model="TenantAddress.Country"
                @change="changeCities"
                :disabled="!editting"
                :class="editting ? 'active-editting' : ''"
              >
                <!-- <option selected disabled hidden>اختر البلد</option> -->
                <option
                  v-for="country in countries"
                  :key="country.CountryCode"
                  :value="country.CountryCode"
                  :selected="TenantAddress.Country == country.CountryCode"
                >
                  {{ country.Name.ArabicValue }}
                </option>
              </select>
            </div>
            <!-- //form item -->
            <!-- form item -->
            <div class="form-group">
              <label for="city">المدينة</label>
              <skeleton-block
                v-if="Object.keys(TenantAddress).length === 0 || loading"
                width="35rem"
                height="5rem"
                effect="wave"
              />
              <select
                v-else
                id="city"
                v-model="TenantAddress.City"
                :disabled="!editting"
                @change="changeIn('TenantAddress')"
                :class="editting ? 'active-editting' : ''"
              >
                <option
                  v-for="city in cities"
                  :key="city.Id"
                  :value="city.Id"
                  :selected="TenantAddress.City == city.Id"
                >
                  {{ city.Name.ArabicValue }}
                </option>
              </select>
            </div>
            <!-- //form item -->
            <!-- form item -->
            <div class="form-group">
              <label for="name">العنوان</label>
              <skeleton-block
                v-if="Object.keys(TenantAddress).length === 0 || loading"
                width="35rem"
                height="5rem"
                effect="wave"
              />
              <input
                v-else
                type="text"
                v-model="TenantAddress.Street"
                @change="changeIn('TenantAddress')"
                :disabled="!editting"
                :class="editting ? 'active-editting' : ''"
              />
            </div>
            <!-- //form item -->
            <!-- form item -->
            <div class="form-group">
              <label for="name">الجوال</label>
              <skeleton-block
                v-if="Object.keys(TenantAddress).length === 0 || loading"
                width="35rem"
                height="5rem"
                effect="wave"
              />
              <input
                v-else
                type="tel"
                v-model="TenantAddress.Telephone"
                @change="changeIn('TenantAddress')"
                :disabled="!editting"
                :class="editting ? 'active-editting' : ''"
              />
            </div>
            <!-- //form item -->
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import { useMsal } from "@/composition-api/useMsal";
import AccountService from "@/services/AccountService";
import { SkeletonBlock, SkeletonText } from "skeleton-elements/vue";
export default {
  components: {
    SkeletonBlock,
    SkeletonText,
  },
  setup() {
    const { instance, inProgress } = useMsal();
    return {
      instance,
      inProgress,
    };
  },
  data() {
    return {
      profile: {},
      Tenant: {},
      TenantAddress: {},
      domains: [
        { en: "Retail", ar: "التجزئة" },
        { en: "Manufacturing", ar: "التصنيع" },
        { en: "Non Profit", ar: "غير ربحي" },
        { en: "Governmental", ar: "حكومي" },
        { en: "Commercial", ar: "تجاري" },
        { en: "Other", ar: "أخرى" },
      ],
      sizes: ["11 - 30", "31 - 50", "51 - 200", "200+"],
      roles: [
        { en: "Manager", ar: "مدير" },
        { en: "Accountant", ar: "محاسب" },
        { en: "Marketer", ar: "مسوق" },
        { en: "Employee", ar: "موظف" },
        { en: "Administrator", ar: "مدير النظام" },
        { en: "Affiliate", ar: "مسوق بالعمولة" },
      ],
      CrNumberError: null,
      countries: null,
      cities: null,
      editting: false,
      isSaving: false,
      profileUpdated: false,
      tenantUpdated: false,
      addressUpdated: false,
      loading: false,
    };
  },
  watch: {},
  mounted() {
    // this.getGraphData();// to be called to fill profile fields
    this.getProfile();
  },
  methods: {
    changeIn(field) {
      if (field == "profile") this.profileUpdated = true;
      if (field == "Tenant") this.tenantUpdated = true;
      if (field == "TenantAddress") this.addressUpdated = true;
    },
    editProfile() {
      this.editting = !this.editting;
    },
    filesChange(e) {
      const fd = new FormData();
      fd.append("pic", e.target.files[0]);
    },
    async getProfile() {
      this.loading = true;
      const profile = await AccountService.getProfile();
      this.countries = await AccountService.getCountries();
      this.profile = profile;
      this.Tenant = profile.Tenant;
      this.TenantAddress = profile.Tenant.Address;
      if (typeof this.TenantAddress.Country === "object") {
        this.TenantAddress.Country = this.TenantAddress.Country.CountryCode;
        this.TenantAddress.City = this.TenantAddress.City?.Id;
      }
      this.cities = this.countries.find(
        (c) => this.TenantAddress.Country == c.CountryCode
      ).ChildRegions;
      this.loading = false;
    },
    async changeCities(e) {
      const CountryCode = e.target.value;
      this.changeIn("TenantAddress");
      // const countryId = e.target.value;
      // const countryCode = this.countries.find(
      //   (c) => c.Id == countryId
      // ).CountryCode;
      const cities = await AccountService.getCountryCities(CountryCode);
      this.cities = cities;
      this.TenantAddress.City = this.cities[0].Id;
    },
    updateAccount() {
      if (!this.$refs["crNumber"].node.context.state.valid) return;
      this.loading = true;

      const update = new Promise(async (resolve, reject) => {
        const status = {};
        if (this.profileUpdated) {
          status["updateProfile"] = await this.updateProfile();
        }
        if (this.tenantUpdated) {
          // if(this.Tenant.CrNumber)
          if (this.$refs["crNumber"].node.context.state.valid) {
            status["updateTenant"] = await this.updateTenant();
          }
        }
        if (this.addressUpdated) {
          status["updateAddress"] = await this.updateAddress();
        }
        resolve(status);
      });
      update.then((r) => {
        this.loading = false;
        this.profile = {};
        this.Tenant = {};
        this.TenantAddress = {};
        this.getProfile();
      });
    },
    async updateProfile() {
      const Profile = {
        Id: this.profile.Id,
        NameIdentifier: this.profile.NameIdentifier,
        Name: this.profile.Name,
        Email: this.profile.Email,
        MobileNumber: this.profile.MobileNumber,
        Status: this.profile.Status,
      };
      return await AccountService.updateProfile(Profile);
    },
    async updateTenant() {
      const Tenant = {
        Id: this.Tenant.Id,
        Name: this.Tenant.Name,
        CrNumber: this.Tenant.CrNumber,
        BusinessDomain: this.Tenant.BusinessDomain,
        CompanySize: this.Tenant.CompanySize,
        TiNumber: this.Tenant.TINumber,
      };
      return await AccountService.updateTenant(Tenant);
    },
    async updateAddress() {
      const TenantAddress = {
        street: this.TenantAddress.Street,
        district: this.TenantAddress.District,
        telephone: this.TenantAddress.Telephone,
        country: this.TenantAddress.Country,
        city: this.TenantAddress.City,
      };
      return await AccountService.updateTenantAddress(TenantAddress);
    },
    // async getGraphData() {
    //   console.log(this.instance);
    //   const response = await this.instance
    //     .acquireTokenSilent({
    //       ...loginRequest,
    //     })
    //     .catch(async (e) => {
    //       console.log("this is e", e);
    //       if (e instanceof InteractionRequiredAuthError) {
    //         await this.instance.acquireTokenRedirect(loginRequest);
    //       }
    //       throw e;
    //     });
    //   if (inProgress.value === InteractionStatus.None) {
    //     console.log("this.is the response", response);
    //     // const graphData = await callMsGraph(response.accessToken);
    //     // state.data = graphData;
    //     // state.resolved = true;
    //     // stopWatcher();
    //   }
    // },
  },
};
</script>

<style lang="scss">
@import "../assets/styles/abstracts/mixins";
.cr-number {
  width: 75%;
  @include responsive(tab-port) {
    width: 100%;
  }
  input {
    width: 100% !important;
  }
  &.active-editting input {
    background-color: transparent !important;
    border: 1px solid rgba(#acacac, 0.35) !important;
  }
}
</style>