<template>
  <div class="addon-details container">
    <div class="addon-details__back-btn">
      <router-link to="/addons">
        <i class="fas fa-chevron-right"></i>
        الرجوع للصفحة السابقة
      </router-link>
    </div>
    <div class="addon-details__summary grid grid3-1">
      <!-- flex-between -->
      <div class="flex">
        <div class="addon-details__img">
          <img
            v-if="addon.addon_image"
            :src="require(`@/assets/images/addons/${this.addon.addon_image}`)"
            :alt="addon.name"
          />
        </div>
        <div class="addon-details__info flex-col">
          <div class="addon-details__rate">
            <i class="fas fa-star c-secondary"></i>
            <i class="fas fa-star c-secondary"></i>
            <i class="fas fa-star c-secondary"></i>
            <i class="fas fa-star c-grey"></i>
            <i class="fas fa-star c-grey"></i>
          </div>
          <h2 class="addon-details__name c-primary">
            {{ addon.name }}
          </h2>
          <div class="addon-details__gen flex">
            <div class="addon-details__cat">{{ addon.category }}</div>
            <div class="addon-details__users mx-3">
              <i class="fas fa-user-friends"></i>
              <span class="mx-1"> عدد المستخدمين</span>
              <template v-if="addon.users">{{ addon.users.length }}</template>
            </div>
          </div>
        </div>
      </div>
      <div class="addon-details__action flex-col">
        <div class="addon-details__price">
          {{ addon.price }} {{ getCurrency() }}
        </div>
        <button @click="addAddon(addon)" class="btn btn-info btn-icon">
          إضافة الى اشتراك
          <i class="far fa-chevron-down"></i>
        </button>
      </div>
    </div>
    <!-- section -->
    <section
      class="addon-details__screens addon-details__row addon-details__section"
    >
      <h3 class="section__heading">صور من التطبيق</h3>
      <addon-screens-carousel :images="addon.images" />
    </section>
    <!-- section // section -->
    <section class="addon-details__exc addon-details__section">
      <p>{{ addon.excerpt }}</p>
    </section>
    <section class="addon-details__tabs addon-details__section">
      <addon-details-tab-section />
    </section>
    <section class="addon-details__start addon-details__section">
      <p>
        يمكن القيام بذلك عن طريق إنشاء موارد في دافاتير ثم ربطها بـ فودكس
        دافاتير في صفحة إعدادات الدمج للحصول على إرشادات مفصلة ، راجع دليل
        التكامل الكامل على
        <a href="https://help.foodics.com/" class="link"
          >https://help.foodics.com/</a
        >
      </p>
    </section>
  </div>
  <MModal
    class="modal-add-addon"
    v-show="isModalVisible"
    @close="isModalVisible = false"
    hasFooter
  >
    <!-- Modal header -->
    <template #header> إضافة {{ wantAdd.name }} </template>

    <!-- Modal body -->
    <template #body> هل انت متأكد من إضافة {{ wantAdd.name }} اليك؟ </template>

    <template #footer>
      <div class="modal__action">
        <a class="btn btn-primary" @click="confirmAdd(wantAdd)"> موافق </a>
        <a class="btn btn-dark" @click="isModalVisible = !isModalVisible">
          إلغاء الطلب
        </a>
      </div>
    </template>
  </MModal>
</template>

<script>
import Services from "@/services/AddonService";
import AddonScreensCarousel from "@/components/addons/AddonScreensCarousel.vue";
import AddonDetailsTabSection from "@/components/addons/AddonDetailsTabSection.vue";
import MModal from "@/components/common/MModal.vue";
import { getCurrency } from "@/util/utils";
export default {
  components: { AddonScreensCarousel, AddonDetailsTabSection, MModal },
  computed: {},
  data() {
    return {
      addon: {},
      isModalVisible: false,
      wantAdd: {},
    };
  },
  mounted() {
    this.addon = Services.getAddon(this.$route.params.id);
    this.addon.category = Services.getAddonCat(this.addon.cat_id).name;
  },
  methods: {
    addAddon(addon) {
      this.isModalVisible = true;
      this.wantAdd = addon;
    },
    confirmAdd() {
      alert("thank u for adding this addon");
      this.$router.back();
    },
    getCurrency() {
      return getCurrency();
    },
  },
};
</script>

<style>
</style>