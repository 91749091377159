<template>
  <div class="manage-api container">
    <div class="section bread-crumbs pA-2 pr-4">
      <a
        @click="$router.push(`/subscriptions/${$route.params.id}`)"
        class="pointer"
        >تفاصيل الإشتراك</a
      >
      >>
      <span>تهيئة نقاط البيع و اداره API</span>
    </div>
    <section class="manage-api__section section pb-4 pt-6 my-2 text-center">
      <div class="manage-api__section-header">
        <img src="@/assets/images/api.svg" alt="" />
        <p>
          يمكنك تجربة دمج دفاتر مع شركتك وان تحظى أيضا بتجربة مجانية للتأكد من
          ذلك
        </p>
        <h4>إضغط على الزر API للإطلاع على دفاتر</h4>

        <a
          href="https://api.dafater.biz/swagger/ui/index.html"
          target="_blank"
          class="btn btn-primary mt-2"
          style="display: inline-block"
          >API Documentation</a
        >
      </div>
      <div class="mt-4 flex-col flex-center">
        <h4 class="mb-1">يمكنك التجربة هنا</h4>
        <a
          href="https://api.dafater.biz/swagger/ui/index.html#operations"
          target="_blank"
          class="btn btn-primary mt-2"
          style="display: inline-block"
          >موقع التجربة</a
        >
      </div>
      <div class="manage-api__action-widgets">
        <div class="">
          <!-- <div class="manage-api__action-widget">
            <p v-if="!hasFreeTrail">لطلب باقة تجريبية قم بإراسال طلبك من هنا</p>
            <subscription-credentials
              v-if="hasFreeTrail"
              :data="freeTrailSubscriptionData"
              @cancel="hasFreeTrail = false"
              @share="shareCredModal = true"
            />
            <button
              v-if="!hasFreeTrail"
              @click="getFreeTrailSubscription"
              class="btn btn-info mt-2"
            >
              طلب باقة مجانية
            </button>
          </div> -->
          <div class="manage-api__action-widget">
            <subscription-credentials :data="subscription" />
          </div>
        </div>
        <!-- <div
          class="
            manage-api__action-widget
            support
            mt-3
            flex-center-y flex-between
          "
        >
          <p class="mx-2 text-right">يمكنك التجربة على باقة</p>
          <button @click="showSendReqModal = true" class="btn btn-primary">
            أرسل طلب
          </button>
        </div> -->
      </div>
    </section>
  </div>
  <m-modal
    v-show="shareCredModal"
    class="share-cred-modal"
    @close="shareCredModal = false"
  >
    <template #header> مشاركة بيانات الباقة </template>

    <template #body>
      <FormKit
        type="form"
        ref="subscription"
        :actions="false"
        @submit="submitHandler"
      >
        <label> أرسل إلى </label>
        <div class="" v-for="(email, i) in emails" :key="i">
          <FormKit
            type="email"
            :name="email"
            validation="required|email"
            :validation-messages="{
              required: 'من فضلك ادخل بريد إلكتروني اعلاه',
              email: 'من فضلك ادخل بريد إلكتروني صحيح',
            }"
            placeholder="البريد الإلكتروني"
          />
          <!-- <a class="link" @click="removeEmailField(email)" v-if="i > 0">
                <i class="fas fa-times"></i>
              </a> -->
        </div>
        <div class="modal-copy__action">
          <a class="link c-primary" @click="addEmailField">
            <i class="fas fa-plus-circle"></i>
            إضافة بريد إلكتروني آخر
          </a>
          <a class="btn btn-info" @click="submitForm">إرسال</a>
        </div>
      </FormKit>
    </template>
  </m-modal>
  <m-modal
    class="modal-warning"
    v-show="showWarningModal"
    @close="showWarningModal = false"
    hasFooter
  >
    <!-- Modal header -->
    <template #header> التجربة على الباقة الحالية </template>

    <!-- Modal body -->
    <template #body>
      للتجربة على باقتك الحالية قد يكون به خطورة على بياناتك لتأكيد الطلب إضغط
      موافق
    </template>

    <template #footer>
      <div class="modal__action">
        <a class="btn btn-primary" @click="getCurrentSubscriptionCred">
          موافق
        </a>
        <a class="btn btn-dark" @click="showWarningModal = false">
          إلغاء الطلب
        </a>
      </div>
    </template>
  </m-modal>
  <m-modal
    class="modal-send-req"
    v-show="showSendReqModal"
    @close="showSendReqModal = false"
  >
    <!-- Modal header -->
    <template #header> الدعم الفني </template>

    <!-- Modal body -->
    <template #body>
      <FormKit
        type="form"
        submit-label="إرسال"
        :submit-attrs="{
          inputClass: 'btn btn-info',
        }"
        @submit="sendRequest"
      >
        <FormKit
          type="tel"
          label="رقم الهاتف"
          placeholder="+25585418512615"
          :validation="[['required']]"
          :validation-messages="{
            matches: 'من فضلك ادخل رقم هاتف صحيح',
          }"
          validation-visibility="dirty"
        />
        <!-- <FormKit
          type="select"
          name="reason"
          label="سبب الطلب"
          placeholder="اختر من القائمة"
          :options="['option1', 'option2', 'option2', 'option2']"
          validation="required"
          :validation-messages="{
            is: ({ node: { value } }) =>
              `Sorry, we don’t service ${value} anymore.`,
          }"
        /> -->
        <FormKit type="textarea" label="الوصف" rows="8" help="" />
      </FormKit>
    </template>
  </m-modal>
</template>

<script>
import SubscriptionCredentials from "@/components/ManageApi/SubscriptionCredentials.vue";
import MModal from "@/components/common/MModal.vue";
import SubscriptionService from "@/services/SubscriptionService";
// import CopyUrl from "@/components/common/CopyUrl.vue";
export default {
  components: { SubscriptionCredentials, MModal },
  data() {
    return {
      shareCredModal: false,
      showWarningModal: false,
      showSendReqModal: false,
      hasFreeTrail: false,
      hasSubscriptionCred: false,
      currentSubscriptionCred: {},
      freeTrailSubscriptionData: {},
      emails: ["email"],
      subscription: null,
    };
  },
  async mounted() {
    // call api to get if has free trail or not
    // and update hasFreeTrail variable
    this.hasFreeTrail = false;
    // this.subscription = await SubscriptionService.getSubscription(
    //   this.$route.params.id
    // );
    const sessionSubscriptions = JSON.parse(
      sessionStorage.getItem("subscriptions")
    );
    const currentSubscription = sessionSubscriptions.find(
      (s) => s.Id == this.$route.params.id
    );
    console.log("this is susbscriptions", sessionSubscriptions);
    this.subscription = currentSubscription
      ? currentSubscription
      : SubscriptionService.getSubscription(this.$route.params.id);
    this.currentSubscriptionCred.packageUrl = this.subscription.Url;
  },
  methods: {
    getFreeTrailSubscription() {
      // call api to get free trail subscription data
      // and return it
      this.hasFreeTrail = true;
      (this.hasSubscriptionCred = false),
        (this.freeTrailSubscriptionData = {
          expire: 14,
          packageUrl: "https://engineering-duaa.dafater.biz/app...",
          clientID: "mybusiness-1245",
          clientSecret: "et367jeed",
        });
    },
    async getCurrentSubscriptionCred() {
      this.showWarningModal = false;
      this.hasSubscriptionCred = true;
      this.hasFreeTrail = false;
      const response = await SubscriptionService.getApiCredentials(
        this.subscription.SubscriberIdentifier,
        this.subscription.ServiceAdminCredentials.Password
      );
      console.log(response);
      this.currentSubscriptionCred = {
        packageUrl: "https://engineering-duaa.dafater.biz/app...",
        clientID: "mybusiness-1245",
        clientSecret: "et367jeed",
      };
    },
    sendRequest() {
      this.showSendReqModal = false;
      alert("تم استلام طلبكم بنجاح");
    },
    addEmailField() {
      this.emails.push("email-" + this.emails.length);
    },
    submitForm() {
      this.$refs.subscription.node.submit();
    },
    submitHandler(data) {
      // send data to back end
    },
  },
};
</script>