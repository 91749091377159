import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

const AD_URI = process.env.VUE_APP_AD_APP_URI;

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_SignUpIn",
    forgotPassword: "B2C_1_PasswordReset",
    editProfile: "B2C_1_ProfileEdit",
  },
  authorities: {
    signUpSignIn: {
      authority: `${AD_URI}/B2C_1_SignUpIn`,
    },
    forgotPassword: {
      authority: `${AD_URI}/B2C_1_PasswordReset`,
    },
    editProfile: {
      authority: `${AD_URI}/B2C_1_ProfileEdit`,
    },
  },
  authorityDomain: process.env.VUE_APP_AD_APP_DOMAIN,
};

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AD_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Use a sign-up/sign-in user-flow as a default authority
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: process.env.VUE_APP_ROOT_URI, // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    // redirectUri: "https://engineering-frontend.dafater.biz/pay/",
    // redirectUri: "https://saasmoneyv2storagedev.z6.web.core.windows.net/",
    // postLogoutRedirectUri: "/pay/", // Indicates the page to navigate after logout. //change this when deploying to /dist/
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout. //change this when deploying to /dist/
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // switch (level) { // let's comment this for deployment
        //   case LogLevel.Error:
        //     console.error(message);
        //     return;
        //   case LogLevel.Info:
        //     console.info(message);
        //     return;
        //   case LogLevel.Verbose:
        //     console.debug(message);
        //     return;
        //   case LogLevel.Warning:
        //     console.warn(message);
        //     return;
        // }
      },
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["openid", "profile"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
