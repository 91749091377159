import http from "@/services/httpService";

import { useMsal } from "@/composition-api/useMsal";

async function getClientIp() {
  const { data } = await http.get("https://checkip.amazonaws.com/");
  let ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/;
  let ip = data.match(ipRegex)[0];
  console.log(ip);
  return ip;
}

function getCurrency() {
  switch (getCountry()) {
    case "Egypt":
      return "جنيه";
    case "KSA":
      return "ر.س";
    default:
      return "ر.س";
  }
}
function getCurrencyEnum() {
  switch (getCountry()) {
    case "Egypt":
      return 1;
    case "KSA":
      return 0;
    default:
      return 0;
  }
}
function getCountry() {
  const { accounts } = useMsal();
  return accounts.value[0]?.idTokenClaims.country;
}

const Currencies = [
  { en: "SAR", ar: "ر.س" },
  { en: "USD", ar: "دولار" },
  { en: "EGP", ar: "جنيه" },
];

export { getClientIp, getCurrency, getCurrencyEnum, getCountry, Currencies };
