<template>
  <div class="invoices">
    <div class="invoices__heading">
      <h2>تفاصيل الفواتير</h2>
      <p>يمكن مراجعة فواتيرك القديمة وفرزها بالتواريخ</p>
    </div>

    <!-- filter  -->
    <div class="invoices__filter">
      <div class="filter__search">
        <a class="filter__search">
          <i class="fal fa-search"></i>
        </a>
        <input
          type="search"
          v-model="searchTerm"
          placeholder="ابحث عن الفاتورة"
        />
      </div>
      <div class="filter__sort">
        <select name="order">
          <option value="recent">من الاحدث</option>
          <option value="ancient">من الاقدم</option>
        </select>
        <select name="order">
          <option value="subscription">الاشتراكات</option>
        </select>
      </div>
    </div>
    <!-- //filter  -->
    <invoices-table :data="filtered" :currency="currency" />
  </div>
</template>

<script>
import InvoicesTable from "@/components/invoices/InvoicesTable.vue";
import Services from "@/services/InvoicesService";
import { formatDate } from "@/util/date";
import { getCurrency } from "@/util/utils";
export default {
  components: {
    InvoicesTable,
  },
  data() {
    return {
      invoices: [],
      filtered: [],
      searchTerm: "",
      currency: null,
    };
  },
  mounted() {
    this.getInvoices();
    this.getCurrency();
  },
  watch: {
    searchTerm(to) {
      this.search(to);
    },
  },
  methods: {
    async getInvoices() {
      const invoices = await Services.getAllInvoices();
      console.log("this is invoices:>", invoices);
      this.invoices = invoices
        .map((i) => {
          i.Date = formatDate(i.Date);
          i.DateTo = formatDate(i.DateTo);
          i.Description = i.Description.ArabicValue;
          return i;
        })
        .filter((i) => i.Type !== 2);
      this.filtered = this.invoices;
    },
    search(term) {
      if (term == "") {
        this.filtered = this.invoices;
      } else {
        this.filtered = this.invoices.filter(
          (invoice) =>
            invoice.Id.toString().toLowerCase().includes(term.toLowerCase()) ||
            invoice.InvoiceId.toString()
              .toLowerCase()
              .includes(term.toLowerCase()) ||
            invoice.SubscriptionId.toString()
              .toLowerCase()
              .includes(term.toLowerCase()) ||
            invoice.Description.toLowerCase().includes(term.toLowerCase()) ||
            invoice.Amount.toString().toLowerCase().includes(term.toLowerCase())
        );
      }
    },
    getCurrency() {
      this.currency = getCurrency();
    },
  },
};
</script>