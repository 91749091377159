import http from "./httpService";

const signUp = async (user) => {
  // const userObj = {} test with this object
  const { data: profile } = await http.post("/account?ServiceId=1", user);
  return profile;
};
const getProfile = async () => {
  const { data: profile } = await http.get("/account/user?ServiceId=1");
  return profile;
};
const getCountries = async (includeCities = true) => {
  const { data: countries } = await http.get(
    `/account/countries?includeChildRegions=${includeCities}`
  );
  return countries;
  // return includeCities ? countriesIncludeCities : countries;
};
const getCountryCities = async (countryCode) => {
  if (!countryCode) return;
  const { data: cities } = await http.get(
    `account/cities?countryCode=${countryCode}`
  );
  return cities;
  // return citiesOfCountryCode;
};
const updateProfile = async (updatedProfile) => {
  const { status } = await http.put("/account", updatedProfile);
  return status;
};
const updateTenant = async (updatedTenant) => {
  const { data: Tenant } = await http.put("/account/tenant", updatedTenant);
  return Tenant;
};
const updateTenantAddress = async (updatedTenantAddress) => {
  const { data: tenantAddress } = await http.put(
    "/account/tenant/address",
    updatedTenantAddress
  );
  return tenantAddress;
};

export default {
  signUp,
  getProfile,
  getCountries,
  getCountryCities,
  updateProfile,
  updateTenant,
  updateTenantAddress,
};

/**
 * TOMOWROW'S TASKS
 * 
 * 
 1- get account details and fill all fields    []
 2- set preferd payment method                 []
 3- get service details                        []
 4- get all paymentmethodtypes in subscribe page   []
 5-    []
 6-    []
 7-    []
 8-    []
 9-    []
 */

// const countries = {
//   SA,
//   AE,
//   BH,
//   KW,
//   OM,
//   QA,
//   JO,
//   DZ,
//   SD,
//   SO,
//   IQ,
//   MA,
//   YE,
//   TN,
//   KM,
//   DJ,
//   SY,
//   PS,
//   LB,
//   LY,
//   EG,
//   MR,
//   AD,
//   AF,
//   AG,
//   AI,
//   AL,
//   AM,
//   AO,
//   AQ,
//   AR,
//   AS,
//   AT,
//   AU,
//   AW,
//   AX,
//   AZ,
//   BA,
//   BB,
//   BD,
//   BE,
//   BF,
//   BG,
//   BI,
//   BJ,
//   BL,
//   BM,
//   BN,
//   BO,
//   BQ,
//   BR,
//   BS,
//   BT,
//   BV,
//   BW,
//   BY,
//   BZ,
//   CA,
//   CC,
//   CD,
//   CF,
//   CG,
//   CH,
//   CI,
//   CK,
//   CL,
//   CM,
//   CN,
//   CO,
//   CR,
//   CU,
//   CV,
//   CW,
//   CX,
//   CY,
//   CZ,
//   DE,
//   DK,
//   DM,
//   DO,
//   EC,
//   EE,
//   EH,
//   ER,
//   ES,
//   ET,
//   FI,
//   FJ,
//   FK,
//   FM,
//   FO,
//   FR,
//   GA,
//   GB,
//   GD,
//   GE,
//   GF,
//   GG,
//   GH,
//   GI,
//   GL,
//   GM,
//   GN,
//   GP,
//   GQ,
//   GR,
//   GS,
//   GT,
//   GU,
//   GW,
//   GY,
//   HK,
//   HM,
//   HN,
//   HR,
//   HT,
//   HU,
//   ID,
//   IE,
//   IL,
//   IM,
//   IN,
//   IO,
//   IR,
//   IS,
//   IT,
//   JE,
//   JM,
//   JP,
//   KE,
//   KG,
//   KH,
//   KI,
//   KN,
//   KP,
//   KR,
//   KY,
//   KZ,
//   LA,
//   LC,
//   LI,
//   LK,
//   LR,
//   LS,
//   LT,
//   LU,
//   LV,
//   MC,
//   MD,
//   ME,
//   MF,
//   MG,
//   MH,
//   MK,
//   ML,
//   MM,
//   MN,
//   MO,
//   MP,
//   MQ,
//   MS,
//   MT,
//   MU,
//   MV,
//   MW,
//   MX,
//   MY,
//   MZ,
//   NA,
//   NC,
//   NE,
//   NF,
//   NG,
//   NI,
//   NL,
//   NO,
//   NP,
//   NR,
//   NU,
//   NZ,
//   PA,
//   PE,
//   PF,
//   PG,
//   PH,
//   PK,
//   PL,
//   PM,
//   PN,
//   PR,
//   PT,
//   PW,
//   PY,
//   RE,
//   RO,
//   RS,
//   RU,
//   RW,
//   SB,
//   SC,
//   SE,
//   SG,
//   SH,
//   SI,
//   SJ,
//   SK,
//   SL,
//   SM,
//   SN,
//   SR,
//   SS,
//   ST,
//   SV,
//   SX,
//   SZ,
//   TC,
//   TD,
//   TF,
//   TG,
//   TH,
//   TJ,
//   TK,
//   TL,
//   TM,
//   TO,
//   TR,
//   TT,
//   TV,
//   TW,
//   TZ,
//   UA,
//   UG,
//   UM,
//   US,
//   UY,
//   UZ,
//   VA,
//   VC,
//   VE,
//   VG,
//   VI,
//   VN,
//   VU,
//   WF,
//   WS,
//   YT,
//   ZA,
//   ZM,
//   ZW,
// };
const countries = [
  {
    Id: 1,
    Name: {
      ArabicValue: "المملكه االعربية لسعودية",
      EnglishValue: "Saudi Arabia",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 0,
    CountryCode: 0,
    Type: 1,
  },
  {
    Id: 2,
    Name: {
      ArabicValue: "الإمارات العربية المتحدة",
      EnglishValue: "United Arab Emirates",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 1,
    CountryCode: 1,
    Type: 1,
  },
  {
    Id: 3,
    Name: {
      ArabicValue: "مملكة البحرين ",
      EnglishValue: "Bahrain",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 2,
    CountryCode: 2,
    Type: 1,
  },
  {
    Id: 4,
    Name: {
      ArabicValue: "الكويت",
      EnglishValue: "Kuwait ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 3,
    CountryCode: 3,
    Type: 1,
  },
  {
    Id: 5,
    Name: {
      ArabicValue: "سلطنة عمان ",
      EnglishValue: "Oman ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 4,
    CountryCode: 4,
    Type: 1,
  },
  {
    Id: 6,
    Name: {
      ArabicValue: "قطر",
      EnglishValue: "Qatar",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 5,
    CountryCode: 5,
    Type: 1,
  },
  {
    Id: 7,
    Name: {
      ArabicValue: "الأردن",
      EnglishValue: "Jordan ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 6,
    CountryCode: 6,
    Type: 1,
  },
  {
    Id: 8,
    Name: {
      ArabicValue: "الجزائر",
      EnglishValue: "Algeria",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 7,
    CountryCode: 7,
    Type: 1,
  },
  {
    Id: 9,
    Name: {
      ArabicValue: "السودان",
      EnglishValue: "Sudan",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 8,
    CountryCode: 8,
    Type: 1,
  },
  {
    Id: 10,
    Name: {
      ArabicValue: "الصومال",
      EnglishValue: "Somalia",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 9,
    CountryCode: 9,
    Type: 1,
  },
  {
    Id: 11,
    Name: {
      ArabicValue: "العراق",
      EnglishValue: "Iraq ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 10,
    CountryCode: 10,
    Type: 1,
  },
  {
    Id: 12,
    Name: {
      ArabicValue: "المغرب",
      EnglishValue: "Morocco",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 11,
    CountryCode: 11,
    Type: 1,
  },
  {
    Id: 13,
    Name: {
      ArabicValue: "اليمن ",
      EnglishValue: "Yemen",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 12,
    CountryCode: 12,
    Type: 1,
  },
  {
    Id: 14,
    Name: {
      ArabicValue: "تونس ",
      EnglishValue: "Tunisia",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 13,
    CountryCode: 13,
    Type: 1,
  },
  {
    Id: 15,
    Name: {
      ArabicValue: "جزر القمر ",
      EnglishValue: "Comoros",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 14,
    CountryCode: 14,
    Type: 1,
  },
  {
    Id: 16,
    Name: {
      ArabicValue: "جيبوتي",
      EnglishValue: "Djibouti",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 15,
    CountryCode: 15,
    Type: 1,
  },
  {
    Id: 17,
    Name: {
      ArabicValue: "سوريا ",
      EnglishValue: "Syria",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 16,
    CountryCode: 16,
    Type: 1,
  },
  {
    Id: 18,
    Name: {
      ArabicValue: "فلسطين",
      EnglishValue: "Palestine",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 17,
    CountryCode: 17,
    Type: 1,
  },
  {
    Id: 19,
    Name: {
      ArabicValue: "لبنان ",
      EnglishValue: "Lebanon",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 18,
    CountryCode: 18,
    Type: 1,
  },
  {
    Id: 20,
    Name: {
      ArabicValue: "ليبيا",
      EnglishValue: "Libya",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 19,
    CountryCode: 19,
    Type: 1,
  },
  {
    Id: 21,
    Name: {
      ArabicValue: "مصر",
      EnglishValue: "Egypt",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 20,
    CountryCode: 20,
    Type: 1,
  },
  {
    Id: 22,
    Name: {
      ArabicValue: "موريتانيا",
      EnglishValue: "Mauritania",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 21,
    CountryCode: 21,
    Type: 1,
  },
  {
    Id: 23,
    Name: {
      ArabicValue: "أخرى",
      EnglishValue: "OTHER",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 22,
    CountryCode: 249,
    Type: 1,
  },
];
const countriesIncludeCities = [
  {
    Id: 1,
    Name: {
      ArabicValue: "المملكه االعربية لسعودية",
      EnglishValue: "Saudi Arabia",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 24,
        Name: {
          ArabicValue: "الطائف",
          EnglishValue: "Taif ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 25,
        Name: {
          ArabicValue: "ابها",
          EnglishValue: "Abha ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 1,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 26,
        Name: {
          ArabicValue: "الخرج ",
          EnglishValue: "Al Kharj ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 2,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 27,
        Name: {
          ArabicValue: "القنفذة ",
          EnglishValue: "Al Qunfudhah",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 3,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 28,
        Name: {
          ArabicValue: "بريدة ",
          EnglishValue: "Buraidah ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 4,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 29,
        Name: {
          ArabicValue: "الدمام ",
          EnglishValue: "Dammam ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 5,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 30,
        Name: {
          ArabicValue: "الأحساء",
          EnglishValue: "Al Hassa ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 6,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 31,
        Name: {
          ArabicValue: "حائل ",
          EnglishValue: "Hail ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 7,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 32,
        Name: {
          ArabicValue: "حفر الباطن",
          EnglishValue: "Hafar Al-Batin",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 8,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 33,
        Name: {
          ArabicValue: "الجبيل ",
          EnglishValue: "Jubail ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 9,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 34,
        Name: {
          ArabicValue: "جدة",
          EnglishValue: "Jeddah ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 10,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 35,
        Name: {
          ArabicValue: "جازان ",
          EnglishValue: "Jizan",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 11,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 36,
        Name: {
          ArabicValue: "خميس مشيط ",
          EnglishValue: "Khamis Mushaait ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 12,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 37,
        Name: {
          ArabicValue: "مكة",
          EnglishValue: "Makkah ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 13,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 38,
        Name: {
          ArabicValue: "المدينة المنورة",
          EnglishValue: "Al Madinah Munawwara ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 14,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 39,
        Name: {
          ArabicValue: "نجران ",
          EnglishValue: "Najran ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 15,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 40,
        Name: {
          ArabicValue: "القطيف",
          EnglishValue: "Qatif",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 16,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 41,
        Name: {
          ArabicValue: "الرياض",
          EnglishValue: "Riyadh ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 17,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 42,
        Name: {
          ArabicValue: "تبوك ",
          EnglishValue: "Tabuk",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 18,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 43,
        Name: {
          ArabicValue: "الطائف",
          EnglishValue: "Taif ",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 19,
        CountryCode: null,
        Type: 2,
      },
      {
        Id: 44,
        Name: {
          ArabicValue: "أبها",
          EnglishValue: "Yanbu",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 20,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 0,
    CountryCode: 0,
    Type: 1,
  },
  {
    Id: 2,
    Name: {
      ArabicValue: "الإمارات العربية المتحدة",
      EnglishValue: "United Arab Emirates",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 45,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 1,
    CountryCode: 1,
    Type: 1,
  },
  {
    Id: 3,
    Name: {
      ArabicValue: "مملكة البحرين ",
      EnglishValue: "Bahrain",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 46,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 2,
    CountryCode: 2,
    Type: 1,
  },
  {
    Id: 4,
    Name: {
      ArabicValue: "الكويت",
      EnglishValue: "Kuwait ",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 47,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 3,
    CountryCode: 3,
    Type: 1,
  },
  {
    Id: 5,
    Name: {
      ArabicValue: "سلطنة عمان ",
      EnglishValue: "Oman ",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 48,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 4,
    CountryCode: 4,
    Type: 1,
  },
  {
    Id: 6,
    Name: {
      ArabicValue: "قطر",
      EnglishValue: "Qatar",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 49,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 5,
    CountryCode: 5,
    Type: 1,
  },
  {
    Id: 7,
    Name: {
      ArabicValue: "الأردن",
      EnglishValue: "Jordan ",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 50,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 6,
    CountryCode: 6,
    Type: 1,
  },
  {
    Id: 8,
    Name: {
      ArabicValue: "الجزائر",
      EnglishValue: "Algeria",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 51,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 7,
    CountryCode: 7,
    Type: 1,
  },
  {
    Id: 9,
    Name: {
      ArabicValue: "السودان",
      EnglishValue: "Sudan",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 52,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 8,
    CountryCode: 8,
    Type: 1,
  },
  {
    Id: 10,
    Name: {
      ArabicValue: "الصومال",
      EnglishValue: "Somalia",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 53,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 9,
    CountryCode: 9,
    Type: 1,
  },
  {
    Id: 11,
    Name: {
      ArabicValue: "العراق",
      EnglishValue: "Iraq ",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 54,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 10,
    CountryCode: 10,
    Type: 1,
  },
  {
    Id: 12,
    Name: {
      ArabicValue: "المغرب",
      EnglishValue: "Morocco",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 55,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 11,
    CountryCode: 11,
    Type: 1,
  },
  {
    Id: 13,
    Name: {
      ArabicValue: "اليمن ",
      EnglishValue: "Yemen",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 56,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 12,
    CountryCode: 12,
    Type: 1,
  },
  {
    Id: 14,
    Name: {
      ArabicValue: "تونس ",
      EnglishValue: "Tunisia",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 57,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 13,
    CountryCode: 13,
    Type: 1,
  },
  {
    Id: 15,
    Name: {
      ArabicValue: "جزر القمر ",
      EnglishValue: "Comoros",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 58,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 14,
    CountryCode: 14,
    Type: 1,
  },
  {
    Id: 16,
    Name: {
      ArabicValue: "جيبوتي",
      EnglishValue: "Djibouti",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 59,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 15,
    CountryCode: 15,
    Type: 1,
  },
  {
    Id: 17,
    Name: {
      ArabicValue: "سوريا ",
      EnglishValue: "Syria",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 60,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 16,
    CountryCode: 16,
    Type: 1,
  },
  {
    Id: 18,
    Name: {
      ArabicValue: "فلسطين",
      EnglishValue: "Palestine",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 61,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 17,
    CountryCode: 17,
    Type: 1,
  },
  {
    Id: 19,
    Name: {
      ArabicValue: "لبنان ",
      EnglishValue: "Lebanon",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 62,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 18,
    CountryCode: 18,
    Type: 1,
  },
  {
    Id: 20,
    Name: {
      ArabicValue: "ليبيا",
      EnglishValue: "Libya",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 63,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 19,
    CountryCode: 19,
    Type: 1,
  },
  {
    Id: 21,
    Name: {
      ArabicValue: "مصر",
      EnglishValue: "Egypt",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 64,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 20,
    CountryCode: 20,
    Type: 1,
  },
  {
    Id: 22,
    Name: {
      ArabicValue: "موريتانيا",
      EnglishValue: "Mauritania",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 65,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 21,
    CountryCode: 21,
    Type: 1,
  },
  {
    Id: 23,
    Name: {
      ArabicValue: "أخرى",
      EnglishValue: "OTHER",
    },
    Currency: 0,
    ChildRegions: [
      {
        Id: 66,
        Name: {
          ArabicValue: "أخرى",
          EnglishValue: "Other",
        },
        Currency: 0,
        ChildRegions: [],
        Order: 0,
        CountryCode: null,
        Type: 2,
      },
    ],
    Order: 22,
    CountryCode: 249,
    Type: 1,
  },
];

const citiesOfCountryCode = [
  {
    Id: 24,
    Name: {
      ArabicValue: "الطائف",
      EnglishValue: "Taif ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 0,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 25,
    Name: {
      ArabicValue: "ابها",
      EnglishValue: "Abha ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 1,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 26,
    Name: {
      ArabicValue: "الخرج ",
      EnglishValue: "Al Kharj ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 2,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 27,
    Name: {
      ArabicValue: "القنفذة ",
      EnglishValue: "Al Qunfudhah",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 3,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 28,
    Name: {
      ArabicValue: "بريدة ",
      EnglishValue: "Buraidah ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 4,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 29,
    Name: {
      ArabicValue: "الدمام ",
      EnglishValue: "Dammam ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 5,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 30,
    Name: {
      ArabicValue: "الأحساء",
      EnglishValue: "Al Hassa ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 6,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 31,
    Name: {
      ArabicValue: "حائل ",
      EnglishValue: "Hail ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 7,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 32,
    Name: {
      ArabicValue: "حفر الباطن",
      EnglishValue: "Hafar Al-Batin",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 8,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 33,
    Name: {
      ArabicValue: "الجبيل ",
      EnglishValue: "Jubail ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 9,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 34,
    Name: {
      ArabicValue: "جدة",
      EnglishValue: "Jeddah ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 10,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 35,
    Name: {
      ArabicValue: "جازان ",
      EnglishValue: "Jizan",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 11,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 36,
    Name: {
      ArabicValue: "خميس مشيط ",
      EnglishValue: "Khamis Mushaait ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 12,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 37,
    Name: {
      ArabicValue: "مكة",
      EnglishValue: "Makkah ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 13,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 38,
    Name: {
      ArabicValue: "المدينة المنورة",
      EnglishValue: "Al Madinah Munawwara ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 14,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 39,
    Name: {
      ArabicValue: "نجران ",
      EnglishValue: "Najran ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 15,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 40,
    Name: {
      ArabicValue: "القطيف",
      EnglishValue: "Qatif",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 16,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 41,
    Name: {
      ArabicValue: "الرياض",
      EnglishValue: "Riyadh ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 17,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 42,
    Name: {
      ArabicValue: "تبوك ",
      EnglishValue: "Tabuk",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 18,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 43,
    Name: {
      ArabicValue: "الطائف",
      EnglishValue: "Taif ",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 19,
    CountryCode: null,
    Type: 2,
  },
  {
    Id: 44,
    Name: {
      ArabicValue: "أبها",
      EnglishValue: "Yanbu",
    },
    Currency: 0,
    ChildRegions: [],
    Order: 20,
    CountryCode: null,
    Type: 2,
  },
];
