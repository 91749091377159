<template>
  <div class="dashboard">
    <SideBar @side-toggle="toggleSideBar()" :collapsed="collapsed" />
    <div class="not-sidebar">
      <NavBar @side-toggle="toggleSideBar()" />
      <div class="dashboard-content__container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar.vue";
import NavBar from "./NavBar.vue";
export default {
  components: { NavBar, SideBar },
  data() {
    return {
      collapsed: true,
    };
  },
  watch: {
    $route(to) {
      this.collapsed = true;
    }
  },
  methods: {
    toggleSideBar() {
      this.collapsed = !this.collapsed;
    },
    
  },
};
</script>


<style>
</style>