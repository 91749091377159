const addons = [
  {
    id: "1",
    name: "فودكس",
    price: 100,
    addon_image: "fodics.svg",
    excerpt:
      "زد هي شركة متخصصة في تمكين التجارة الإلكترونية لتجار التجزئة من البدء أو حتى بالتوسع في تجارتهم، و يخدم زد قطاعات التجزئة المختلفة ومن مختلف الأحجام، خدمات زد المختلفة تشتمل على المتجر الإلكتروني ومنظومة زد وتمكين زد ومجتمع للتجار المعتمدين على زد. زد شريكك في تجارتك الإلكترونية.",
    discription:
      "تقدم فودكس نظام إدارة المطاعم -كل في واحد- والذي يستند إلى الخدمة السحابية على أجهزة الآيباد باللغتين العربية والإنجليزية. بتسخير العديد من إضافات تطبيقات نظام iOS سواءً المفعَّلة أو التي لا زالت تحت التطوير؛ تعمل الآلاف من المطاعم وعربات الطعام والمقاهي وسلاسل الوجبات السريعة في مُختلف أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات وثيقة مع مرتاديها.",
    images: [
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
    ],
    users: ["12", "32", "14", "24"],
    new: false,
    cat_id: "1234",
  },
  {
    id: "2",
    name: "تطبيق زد",
    price: 100,
    addon_image: "zid.png",
    excerpt:
      "زد هي شركة متخصصة في تمكين التجارة الإلكترونية لتجار التجزئة من البدء أو حتى بالتوسع في تجارتهم، و يخدم زد قطاعات التجزئة المختلفة ومن مختلف الأحجام، خدمات زد المختلفة تشتمل على المتجر الإلكتروني ومنظومة زد وتمكين زد ومجتمع للتجار المعتمدين على زد. زد شريكك في تجارتك الإلكترونية.",
    discription:
      "تقدم فودكس نظام إدارة المطاعم -كل في واحد- والذي يستند إلى الخدمة السحابية على أجهزة الآيباد باللغتين العربية والإنجليزية. بتسخير العديد من إضافات تطبيقات نظام iOS سواءً المفعَّلة أو التي لا زالت تحت التطوير؛ تعمل الآلاف من المطاعم وعربات الطعام والمقاهي وسلاسل الوجبات السريعة في مُختلف أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات وثيقة مع مرتاديها.",
    images: [
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
    ],
    users: ["12", "32", "14", "24"],
    new: false,
    cat_id: "1234",
  },
  {
    id: "3",
    name: "تطبيق زيبير",
    price: 100,
    addon_image: "zapier.png",
    excerpt:
      "زد هي شركة متخصصة في تمكين التجارة الإلكترونية لتجار التجزئة من البدء أو حتى بالتوسع في تجارتهم، و يخدم زد قطاعات التجزئة المختلفة ومن مختلف الأحجام، خدمات زد المختلفة تشتمل على المتجر الإلكتروني ومنظومة زد وتمكين زد ومجتمع للتجار المعتمدين على زد. زد شريكك في تجارتك الإلكترونية.",
    discription:
      "تقدم فودكس نظام إدارة المطاعم -كل في واحد- والذي يستند إلى الخدمة السحابية على أجهزة الآيباد باللغتين العربية والإنجليزية. بتسخير العديد من إضافات تطبيقات نظام iOS سواءً المفعَّلة أو التي لا زالت تحت التطوير؛ تعمل الآلاف من المطاعم وعربات الطعام والمقاهي وسلاسل الوجبات السريعة في مُختلف أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات وثيقة مع مرتاديها.",
    images: [
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
    ],
    users: ["12", "32", "14", "24"],
    new: false,
    cat_id: "1234",
  },
  {
    id: "4",
    name: "السلة",
    price: 100,
    addon_image: "salla.png",
    excerpt:
      "زد هي شركة متخصصة في تمكين التجارة الإلكترونية لتجار التجزئة من البدء أو حتى بالتوسع في تجارتهم، و يخدم زد قطاعات التجزئة المختلفة ومن مختلف الأحجام، خدمات زد المختلفة تشتمل على المتجر الإلكتروني ومنظومة زد وتمكين زد ومجتمع للتجار المعتمدين على زد. زد شريكك في تجارتك الإلكترونية.",
    discription:
      "تقدم فودكس نظام إدارة المطاعم -كل في واحد- والذي يستند إلى الخدمة السحابية على أجهزة الآيباد باللغتين العربية والإنجليزية. بتسخير العديد من إضافات تطبيقات نظام iOS سواءً المفعَّلة أو التي لا زالت تحت التطوير؛ تعمل الآلاف من المطاعم وعربات الطعام والمقاهي وسلاسل الوجبات السريعة في مُختلف أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات وثيقة مع مرتاديها.",
    images: [
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
    ],
    users: ["12", "32", "14", "24"],
    new: true,
    cat_id: "1234",
  },
  {
    id: "1",
    name: "فودكس",
    price: 100,
    addon_image: "fodics.svg",
    excerpt:
      "زد هي شركة متخصصة في تمكين التجارة الإلكترونية لتجار التجزئة من البدء أو حتى بالتوسع في تجارتهم، و يخدم زد قطاعات التجزئة المختلفة ومن مختلف الأحجام، خدمات زد المختلفة تشتمل على المتجر الإلكتروني ومنظومة زد وتمكين زد ومجتمع للتجار المعتمدين على زد. زد شريكك في تجارتك الإلكترونية.",
    discription:
      "تقدم فودكس نظام إدارة المطاعم -كل في واحد- والذي يستند إلى الخدمة السحابية على أجهزة الآيباد باللغتين العربية والإنجليزية. بتسخير العديد من إضافات تطبيقات نظام iOS سواءً المفعَّلة أو التي لا زالت تحت التطوير؛ تعمل الآلاف من المطاعم وعربات الطعام والمقاهي وسلاسل الوجبات السريعة في مُختلف أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات وثيقة مع مرتاديها.",
    images: [
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
    ],
    users: ["12", "32", "14", "24"],
    new: false,
    cat_id: "14",
  },
  {
    id: "2",
    name: "تطبيق زد",
    price: 100,
    addon_image: "zid.png",
    excerpt:
      "زد هي شركة متخصصة في تمكين التجارة الإلكترونية لتجار التجزئة من البدء أو حتى بالتوسع في تجارتهم، و يخدم زد قطاعات التجزئة المختلفة ومن مختلف الأحجام، خدمات زد المختلفة تشتمل على المتجر الإلكتروني ومنظومة زد وتمكين زد ومجتمع للتجار المعتمدين على زد. زد شريكك في تجارتك الإلكترونية.",
    discription:
      "تقدم فودكس نظام إدارة المطاعم -كل في واحد- والذي يستند إلى الخدمة السحابية على أجهزة الآيباد باللغتين العربية والإنجليزية. بتسخير العديد من إضافات تطبيقات نظام iOS سواءً المفعَّلة أو التي لا زالت تحت التطوير؛ تعمل الآلاف من المطاعم وعربات الطعام والمقاهي وسلاسل الوجبات السريعة في مُختلف أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات وثيقة مع مرتاديها.",
    images: [
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
    ],
    users: ["12", "32", "14", "24"],
    new: false,
    cat_id: "14",
  },
  {
    id: "3",
    name: "تطبيق زيبير",
    price: 100,
    addon_image: "zapier.png",
    excerpt:
      "زد هي شركة متخصصة في تمكين التجارة الإلكترونية لتجار التجزئة من البدء أو حتى بالتوسع في تجارتهم، و يخدم زد قطاعات التجزئة المختلفة ومن مختلف الأحجام، خدمات زد المختلفة تشتمل على المتجر الإلكتروني ومنظومة زد وتمكين زد ومجتمع للتجار المعتمدين على زد. زد شريكك في تجارتك الإلكترونية.",
    discription:
      "تقدم فودكس نظام إدارة المطاعم -كل في واحد- والذي يستند إلى الخدمة السحابية على أجهزة الآيباد باللغتين العربية والإنجليزية. بتسخير العديد من إضافات تطبيقات نظام iOS سواءً المفعَّلة أو التي لا زالت تحت التطوير؛ تعمل الآلاف من المطاعم وعربات الطعام والمقاهي وسلاسل الوجبات السريعة في مُختلف أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات وثيقة مع مرتاديها.",
    images: [
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
    ],
    users: ["12", "32", "14", "24"],
    new: false,
    cat_id: "14",
  },
  {
    id: "4",
    name: "السلة",
    price: 100,
    addon_image: "salla.png",
    excerpt:
      "زد هي شركة متخصصة في تمكين التجارة الإلكترونية لتجار التجزئة من البدء أو حتى بالتوسع في تجارتهم، و يخدم زد قطاعات التجزئة المختلفة ومن مختلف الأحجام، خدمات زد المختلفة تشتمل على المتجر الإلكتروني ومنظومة زد وتمكين زد ومجتمع للتجار المعتمدين على زد. زد شريكك في تجارتك الإلكترونية.",
    discription:
      "تقدم فودكس نظام إدارة المطاعم -كل في واحد- والذي يستند إلى الخدمة السحابية على أجهزة الآيباد باللغتين العربية والإنجليزية. بتسخير العديد من إضافات تطبيقات نظام iOS سواءً المفعَّلة أو التي لا زالت تحت التطوير؛ تعمل الآلاف من المطاعم وعربات الطعام والمقاهي وسلاسل الوجبات السريعة في مُختلف أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات وثيقة مع مرتاديها.",
    images: [
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
    ],
    users: ["12", "32", "14", "24"],
    new: true,
    cat_id: "14",
  },
  {
    id: "1",
    name: "فودكس",
    price: 100,
    addon_image: "fodics.svg",
    excerpt:
      "زد هي شركة متخصصة في تمكين التجارة الإلكترونية لتجار التجزئة من البدء أو حتى بالتوسع في تجارتهم، و يخدم زد قطاعات التجزئة المختلفة ومن مختلف الأحجام، خدمات زد المختلفة تشتمل على المتجر الإلكتروني ومنظومة زد وتمكين زد ومجتمع للتجار المعتمدين على زد. زد شريكك في تجارتك الإلكترونية.",
    discription:
      "تقدم فودكس نظام إدارة المطاعم -كل في واحد- والذي يستند إلى الخدمة السحابية على أجهزة الآيباد باللغتين العربية والإنجليزية. بتسخير العديد من إضافات تطبيقات نظام iOS سواءً المفعَّلة أو التي لا زالت تحت التطوير؛ تعمل الآلاف من المطاعم وعربات الطعام والمقاهي وسلاسل الوجبات السريعة في مُختلف أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات وثيقة مع مرتاديها.",
    images: [
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
    ],
    users: ["12", "32", "14", "24"],
    new: false,
    cat_id: "14",
  },
  {
    id: "2",
    name: "تطبيق زد",
    price: 100,
    addon_image: "zid.png",
    excerpt:
      "زد هي شركة متخصصة في تمكين التجارة الإلكترونية لتجار التجزئة من البدء أو حتى بالتوسع في تجارتهم، و يخدم زد قطاعات التجزئة المختلفة ومن مختلف الأحجام، خدمات زد المختلفة تشتمل على المتجر الإلكتروني ومنظومة زد وتمكين زد ومجتمع للتجار المعتمدين على زد. زد شريكك في تجارتك الإلكترونية.",
    discription:
      "تقدم فودكس نظام إدارة المطاعم -كل في واحد- والذي يستند إلى الخدمة السحابية على أجهزة الآيباد باللغتين العربية والإنجليزية. بتسخير العديد من إضافات تطبيقات نظام iOS سواءً المفعَّلة أو التي لا زالت تحت التطوير؛ تعمل الآلاف من المطاعم وعربات الطعام والمقاهي وسلاسل الوجبات السريعة في مُختلف أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات وثيقة مع مرتاديها.",
    images: [
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
    ],
    users: ["12", "32", "14", "24"],
    new: false,
    cat_id: "14",
  },
  {
    id: "3",
    name: "تطبيق زيبير",
    price: 100,
    addon_image: "zapier.png",
    excerpt:
      "زد هي شركة متخصصة في تمكين التجارة الإلكترونية لتجار التجزئة من البدء أو حتى بالتوسع في تجارتهم، و يخدم زد قطاعات التجزئة المختلفة ومن مختلف الأحجام، خدمات زد المختلفة تشتمل على المتجر الإلكتروني ومنظومة زد وتمكين زد ومجتمع للتجار المعتمدين على زد. زد شريكك في تجارتك الإلكترونية.",
    discription:
      "تقدم فودكس نظام إدارة المطاعم -كل في واحد- والذي يستند إلى الخدمة السحابية على أجهزة الآيباد باللغتين العربية والإنجليزية. بتسخير العديد من إضافات تطبيقات نظام iOS سواءً المفعَّلة أو التي لا زالت تحت التطوير؛ تعمل الآلاف من المطاعم وعربات الطعام والمقاهي وسلاسل الوجبات السريعة في مُختلف أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات وثيقة مع مرتاديها.",
    images: [
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
    ],
    users: ["12", "32", "14", "24"],
    new: false,
    cat_id: "14",
  },
  {
    id: "4",
    name: "السلة",
    price: 100,
    addon_image: "salla.png",
    excerpt:
      "زد هي شركة متخصصة في تمكين التجارة الإلكترونية لتجار التجزئة من البدء أو حتى بالتوسع في تجارتهم، و يخدم زد قطاعات التجزئة المختلفة ومن مختلف الأحجام، خدمات زد المختلفة تشتمل على المتجر الإلكتروني ومنظومة زد وتمكين زد ومجتمع للتجار المعتمدين على زد. زد شريكك في تجارتك الإلكترونية.",
    discription:
      "تقدم فودكس نظام إدارة المطاعم -كل في واحد- والذي يستند إلى الخدمة السحابية على أجهزة الآيباد باللغتين العربية والإنجليزية. بتسخير العديد من إضافات تطبيقات نظام iOS سواءً المفعَّلة أو التي لا زالت تحت التطوير؛ تعمل الآلاف من المطاعم وعربات الطعام والمقاهي وسلاسل الوجبات السريعة في مُختلف أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات وثيقة مع مرتاديها.",
    images: [
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
      "addon-screenshot.png",
    ],
    users: ["12", "32", "14", "24"],
    new: true,
    cat_id: "14",
  },
];

const addonsCats = [
  {
    id: "34",
    name: "إضافات مجانية",
  },
  // {
  //   id: "1234",
  //   name: "التسويق الالكتروني",
  // },
  // {
  //   id: "14",
  //   name: "الموارد البشرية",
  // },
  // {
  //   id: "134",
  //   name: "إضافات غالية",
  // },
  // {
  //   id: "123",
  //   name: "إضافات مجانية تاني",
  // },
];

const getAllAddons = () => {
  // calling api
  return addons;
};

const getAddon = (id) => {
  return addons.find((addon) => addon.id == id);
};

const getAllAddonsCat = () => {
  return addonsCats;
};

const getAddonCat = (id) => {
  return addonsCats.find((cat) => cat.id == id);
};

const applyAddon = ({ name }) => {
  alert(name, "is added successfully");
};

const search = (term, cat_id) => {
  return addons
    .filter((a) => a.cat_id == cat_id)
    .filter((a) => a.name.includes(term));
};

export default {
  getAllAddons,
  getAddon,
  getAllAddonsCat,
  getAddonCat,
  applyAddon,
  search,
};
