<template>
  <div class="subscription__card sub-card">
    <div class="sub-card__header">
      <div class="flex-center-y">
        <skeleton-text effect="wave" tag="h4">
          may be three words
        </skeleton-text>

        <div class="sub-card__status mx-2">
          <skeleton-block
            effect="wave"
            width="90px"
            height="25px"
            borderRadius="20px"
          />
        </div>
      </div>
      <span class="fs-18 fw-b">
        <span class="mx-1">
          <skeleton-text effect="wave"> aword </skeleton-text>
        </span>
        <skeleton-text effect="wave"> two words </skeleton-text>
      </span>
    </div>
    <div class="sub-card__body grid grid3-1">
      <div class="sub-card__info">
        <skeleton-text effect="wave" tag="h3">
          three words or more
        </skeleton-text>

        <div class="sub-card__link">
          <skeleton-text effect="wave" tag="p"> two words </skeleton-text>
          <skeleton-block width="450px" height="45px" effect="wave" />
        </div>
        <div class="sub-card__footer-info">
          <div class="sub-card__kval">
            <div class="sub-card__key">
              <skeleton-text effect="wave"> two words </skeleton-text>
            </div>
            <div class="sub-card__val">
              <skeleton-text effect="wave"> abigsingleword </skeleton-text>
            </div>
          </div>
          <div class="sub-card__kval">
            <div class="sub-card__key">
              <skeleton-text effect="wave"> two words </skeleton-text>
            </div>
            <div class="sub-card__val">
              <skeleton-text effect="wave"> abigsingleword </skeleton-text>
            </div>
          </div>
          <div class="sub-card__kval">
            <div class="sub-card__key">
              <skeleton-text effect="wave"> two words </skeleton-text>
            </div>
            <div class="sub-card__val">
              <skeleton-text effect="wave"> abigsingleword </skeleton-text>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-card__action">
        <div class="sub-card__expiration">
          <skeleton-block
            tag="div"
            width="150px"
            height="150px"
            borderRadius="50%"
            effect="wave"
          />
        </div>
        <skeleton-block width="150px" height="40px" effect="wave" />
      </div>
    </div>
  </div>
</template>

<script>
import { SkeletonBlock } from "skeleton-elements/vue";
import { SkeletonText } from "skeleton-elements/vue";
export default {
  components: {
    SkeletonBlock,
    SkeletonText,
  },
};
</script>

<style lang="scss" scoped>
</style>