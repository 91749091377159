<template>
  <loading-screen v-if="showLoadingScreen" :loadingItems="loadingItems" />
  <div class="form-step form-step2">
    <!-- aside invoice card  -->
    <aside>
      <invoice-card
        :transaction="transaction"
        :features="features"
        :loadingUpdate="loadingUpdate"
        :subscriptionType="subscriptionType"
        @applyCoupon="applyDiscountCoupon"
        :enableDicountInput="false"
        @pay="payClicked()"
      />
    </aside>
    <!-- ////aside invoice card  -->

    <!-- package specs  -->
    <main class="package-specs">
      <header
        class="package-specs__header"
        v-if="subscriptionType == 'creation'"
      >
        <div v-if="Object.keys(transaction).length > 0">
          <i class="far fa-check c-primary"></i>
          <h4>تم إختيار {{ transaction.ServicePlan.Name.ArabicValue }}</h4>
          <!-- <span class="c-secondary mx-2 fw-b">
            <span class="price">{{ transaction.ServicePlanTotalPrice }}</span>
            {{getCurrency()}} / سنوياً
          </span> -->
        </div>
        <skeleton-text v-else effect="wave">
          <h4 style="margin: 0">name and price of package</h4>
        </skeleton-text>

        <a @click="$emit('backStep')" class="btn btn-info">تغيير الباقة</a>
      </header>
      <template v-if="!features">
        <feature-card-group-loading />
        <section class="vcp--expanded">
          <header class="vcp__header">
            <div class="vcp__header-title">مزايا إضافية</div>
          </header>
          <div class="vcp__body">
            <div class="vcp__body-content">
              <section class="package-specs__section">
                <package-spec-card-loading />
                <package-spec-card-loading />
                <package-spec-card-loading />
                <package-spec-card-loading />
              </section>
            </div>
          </div>
        </section>
        <section class="vcp--expanded">
          <header class="vcp__header">
            <div class="vcp__header-title">الخدمات الإضافية</div>
          </header>
          <div class="vcp__body">
            <div class="vcp__body-content">
              <feature-card-group-loading />
            </div>
          </div>
        </section>
        <section class="vcp--expanded">
          <header class="vcp__header">
            <div class="vcp__header-title">خدمات آخرى</div>
          </header>
          <div class="vcp__body">
            <div class="vcp__body-content">
              <feature-card-group-loading />
            </div>
          </div>
        </section>
      </template>

      <feature-card-group
        :features="features"
        :loadingUpdate="loadingUpdate"
        @update="updateFeature"
        :FeaturesGroup="FeaturesGroup1"
        :currency="currency"
        :cycleType="transaction.SelectedBillingCycleType"
      />

      <!-- section one -->
      <section
        class="vcp--expanded"
        v-if="FeaturesGroup2 && FeaturesGroup2.length > 0"
      >
        <header class="vcp__header">
          <div class="vcp__header-title">مزايا إضافية</div>
        </header>
        <div class="vcp__body">
          <div class="vcp__body-content">
            <feature-card-group
              :features="features"
              :loadingUpdate="loadingUpdate"
              @update="updateFeature"
              :FeaturesGroup="FeaturesGroup2"
              :currency="currency"
              :cycleType="transaction.SelectedBillingCycleType"
            />
          </div>
        </div>
      </section>
      <!-- section two -->
      <section
        class="vcp--expanded"
        v-if="FeaturesGroup3 && FeaturesGroup3.length > 0"
      >
        <header class="vcp__header">
          <div class="vcp__header-title">الخدمات الإضافية</div>
        </header>
        <div class="vcp__body">
          <div class="vcp__body-content">
            <feature-card-group
              :features="features"
              :loadingUpdate="loadingUpdate"
              @update="updateFeature"
              :FeaturesGroup="FeaturesGroup3"
              :currency="currency"
              :cycleType="transaction.SelectedBillingCycleType"
            />
          </div>
        </div>
      </section>
      <!-- section three  -->
      <section
        class="vcp--expanded"
        v-if="FeaturesGroup4 && FeaturesGroup4.length > 0"
      >
        <header class="vcp__header">
          <div class="vcp__header-title">خدمات آخرى</div>
        </header>
        <div class="vcp__body">
          <div class="vcp__body-content">
            <feature-card-group
              :features="features"
              :loadingUpdate="loadingUpdate"
              @update="updateFeature"
              :FeaturesGroup="FeaturesGroup4"
              :currency="currency"
              :cycleType="transaction.SelectedBillingCycleType"
            />
          </div>
        </div>
      </section>
    </main>
    <!-- ////package specs  -->
  </div>
</template>

<script>
import SubscriptionService from "@/services/SubscriptionService";
import FeatureCardGroup from "./FeatureCardGroup.vue";
import FeatureCardGroupLoading from "./FeatureCardGroupLoading.vue";
import InvoiceCard from "./InvoiceCard.vue";
import { SkeletonText } from "skeleton-elements/vue";
import LoadingScreen from "@/components/common/LoadingScreen.vue";

import { Currencies } from "@/util/utils";
import PackageSpecCardLoading from "./PackageSpecCardLoading.vue";
export default {
  components: {
    FeatureCardGroup,
    FeatureCardGroupLoading,
    PackageSpecCardLoading,
    InvoiceCard,
    SkeletonText,
    LoadingScreen,
  },
  props: {
    subPackage: Object,
    servicePlan: Number,
    transactionId: Number,
    subscriptionType: String,
    couponCode: String,
  },
  emits: ["backStep", "forwardStep", "setTransactionId", "showThanksModal"],
  data() {
    return {
      transaction: {},
      features: null,
      quotaApplicationFeatures: null,
      nonApplicationFeatures: null,
      QuotaNonAppFeatures: null,
      moduleFeatures: null,
      // AddionalQuotaPackTotalPrice: [],
      FeaturesGroup1: null,
      FeaturesGroup2: null,
      FeaturesGroup3: null,
      FeaturesGroup4: null,
      loadingUpdate: true,
      showLoadingScreen: false,
      loadingItems: [],
      currency: null,
    };
  },
  watch: {
    quotaApplicationFeatures(newval) {
      console.log("this is quotaApplicationFeatures:>", newval);
    },
    nonApplicationFeatures(newval) {
      console.log("this is nonApplicationFeatures:>", newval);
    },
    transaction(newVal) {
      if (!this.transaction.ServicePlan) {
        this.transaction.ServicePlan =
          this.transaction.Subscription.ServicePlan;
      }
    },
  },
  mounted() {
    // check if session storage transaction.Id == this.$route.transactionId
    // don't do any thing
    // if !this.$route.transactionId || transaction.Id != this.$route.transactionId
    const sessionTransaction = JSON.parse(
      sessionStorage.getItem("transaction")
    );
    if (
      sessionTransaction &&
      sessionTransaction.Id == this.$route.params.transactionId
    ) {
      this.setTransactionConfigs(sessionTransaction);
    } else {
      this.subscriptionTransactions();
    }
  },
  methods: {
    async subscriptionTransactions() {
      const httpMethod = this.transactionId
        ? SubscriptionService.getSubscriptionTransactions
        : SubscriptionService.postSubscriptionTransactions;
      const id = this.transactionId
        ? this.transactionId
        : this.servicePlan
        ? this.servicePlan
        : this.$route.params.id;
      const transaction = await httpMethod(
        id,
        this.subscriptionType,
        this.couponCode
      );
      sessionStorage.setItem("transaction", JSON.stringify(transaction));
      this.setTransactionConfigs(transaction);
    },
    setTransactionConfigs(transaction) {
      this.transaction = transaction;
      const { ServicePlan } = this.transaction;
      console.log(" this is the transaction:>", this.transaction);

      if (!ServicePlan) {
        this.transaction.ServicePlan =
          this.transaction.Subscription.ServicePlan;
      }
      this.currency = Currencies[this.transaction.ServicePlan.Currency].ar;
      this.$emit("setTransactionId", this.transaction.Id);
      this.setFeatures(this.transaction.SubscriptionSteps);
    },
    setFeatures(SubscriptionSteps) {
      this.moduleFeatures = this.getAppFeatureType(SubscriptionSteps, 0).filter(
        (feature) => feature.InitialQuotaPack == 0
      );
      console.log(this.moduleFeatures);
      this.nonApplicationFeatures = this.getAppFeatureType(
        SubscriptionSteps,
        2
      ).filter((feature) => feature.InitialQuotaPack == 0);
      this.quotaApplicationFeatures = this.getAppFeatureType(
        SubscriptionSteps,
        3
      );
      this.QuotaNonAppFeatures = this.getAppFeatureType(SubscriptionSteps, 4);
      this.features = [
        ...this.QuotaNonAppFeatures, // 4
        ...this.quotaApplicationFeatures, //3
        ...this.nonApplicationFeatures, //2
        ...this.moduleFeatures, //0
      ];
      this.FeaturesGroup1 = this.getFeatureGroup(0, 1000);
      this.FeaturesGroup2 = this.getFeatureGroup(1001, 2000);
      this.FeaturesGroup3 = this.getFeatureGroup(2001, 3000);
      this.FeaturesGroup4 = this.getFeatureGroup(3001, 4000);
      this.loadingUpdate = false;
    },
    updateFeature({ type, Id, value }) {
      console.log("type", type);
      if (type == 0) {
        this.updateModuleFeature(Id, value, this.subscriptionType);
      } else if (type == 2) {
        this.updateNonAppFeature(Id, value, this.subscriptionType);
      } else {
        this.updateQuotaAppFeature(Id, value, this.subscriptionType);
      }
    },
    async updateNonAppFeature(Id, isAdded, subscriptionType) {
      this.loadingUpdate = true;
      const transactionId = this.transaction.Id;
      const update = isAdded
        ? SubscriptionService.addNonAppFeature
        : SubscriptionService.removeNonAppFeature;
      sessionStorage.removeItem("transaction");
      this.transaction = await update(transactionId, Id, subscriptionType);
      this.setTransactionInStorage(this.transaction);
      this.loadingUpdate = false;
      console.log(this.transaction);
      this.setFeatures(this.transaction.SubscriptionSteps);
    },
    async updateQuotaAppFeature(Id, additionalCount, subscriptionType) {
      this.loadingUpdate = true;
      const transactionId = this.transaction.Id;
      sessionStorage.removeItem("transaction");
      this.transaction = await SubscriptionService.updateQuotaAppFeature(
        transactionId,
        Id,
        additionalCount,
        subscriptionType
      );
      this.setTransactionInStorage(this.transaction);
      this.loadingUpdate = false;
      this.setFeatures(this.transaction.SubscriptionSteps);
    },

    async updateModuleFeature(Id, isAdded, subscriptionType) {
      this.loadingUpdate = true;
      const transactionId = this.transaction.Id;
      const updateFeature = isAdded
        ? SubscriptionService.addModuleFeature
        : SubscriptionService.removeModuleFeature;
      sessionStorage.removeItem("transaction");
      this.transaction = await updateFeature(
        transactionId,
        Id,
        subscriptionType
      );
      this.setTransactionInStorage(this.transaction);
      this.loadingUpdate = false;
      this.setFeatures(this.transaction.SubscriptionSteps);
    },

    getAppFeatureType(arr, type) {
      return arr
        .filter((item) => item.AppFeatureType == type)
        .sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    },
    getFeatureGroup(from, to) {
      return this.features
        .filter((item) => item.DisplayOrder >= from && item.DisplayOrder <= to)
        .sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    },
    async applyDiscountCoupon(discountCoupon) {
      this.loadingUpdate = true;
      try {
        const { transaction } = await SubscriptionService.applyDiscountCoupon(
          this.transaction.Id,
          discountCoupon
        );
        this.transaction = transaction;
        // sessionStorage.removeItem("transaction");
        this.setTransactionInStorage(this.transaction);
      } catch (error) {
        this.$toast.error("نأسف هناك خطأ ما!");
      }
      this.loadingUpdate = false;
    },
    payClicked() {
      if (
        this.transaction.ServicePlan.HasPromotionCode &&
        this.transaction.ServicePlan.TrialPlan
      ) {
        // is trial plan
        return this.postSubscribe(this.transaction.Id);
      }
      if (
        this.transaction.GrandTotal -
          this.transaction.TransactionPaymentAmount ==
          0 &&
        this.transaction.TransactionPaymentAmount == 0
      )
        return;
      if (this.transaction.TransactionPaymentAmount == 0) {
        // if has money in wallet
        return this.postSubscribe(this.transaction.Id);
      }
      if (!this.loadingUpdate) this.$emit("forwardStep");
    },
    async postSubscribe(Id) {
      this.loadingItems.push({ status: "doing", text: "تهيئة الأشتراك" });
      this.showLoadingScreen = true;
      const httpMethod =
        this.subscriptionType == "creation"
          ? SubscriptionService.postSubscription
          : SubscriptionService.updateSubscription;
      const response = await httpMethod(Id);
      if (response.status == 200) {
        // remove subscriptions from session
        sessionStorage.removeItem("subscriptions");
        sessionStorage.removeItem("invoices");
        this.loadingItems.map((item) => {
          if (item.text.includes("تهيئة الأشتراك")) {
            item.status = "done";
          }
        });
        setTimeout(() => {
          this.showLoadingScreen = false;
          this.$emit("showThanksModal", {
            show: true,
            subscription: response.data,
          });
        }, 1500);
      } else {
        this.showLoadingScreen = false;
        this.$toast.error(response.message);
      }
    },
    setTransactionInStorage(transaction) {
      sessionStorage.setItem("transaction", JSON.stringify(transaction));
    },
  },
};
</script>
