<template>
  <div class="invoice-table">
    <InvoiceTableLoading v-show="loadingData" />
    <table-lite
      :is-loading="isLoading"
      :columns="columns"
      :rows="data"
      :total="totalRecordCount"
      :sortable="sortable"
      @do-search="$emit('doSearch')"
      @is-finished="tableLoadingFinish"
      v-show="!loadingData"
    >
    </table-lite>
  </div>
  <invoice-details-modal
    :currency="currency"
    :dataId="dataId"
    @modalStatus="changeDetailsModalStatus"
  />
  <invoice-print-modal
    :currency="currency"
    :dataId="printId"
    @modalStatus="changePrintModalStatus"
  />
  <template>
    <vue3-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="فاتورة اشتراك"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <div v-if="downloadInvoice" class="print-modal__print" id="printMe">
          <!-- header -->
          <div class="print-modal__print-header">
            <div class="logo">
              <img src="@/assets/images/logo.png" alt="Dafater Sa" />
            </div>
            <div class="print-modal__print-title">
              <h1 class="heading">فاتورة اشتراك</h1>
              <h2 class="number">#{{ downloadInvoice.InvoiceId }}</h2>
            </div>
          </div>
          <!-- .end header  -->
          <!-- company info -->
          <div class="company-info">
            <h3>دفاتر لحلول الاعمال</h3>
            <p>
              السعودية , الرياض<br />
              بناية 12 الدور السادس<br />
              التقسيم الأول
            </p>
          </div>
          <!-- .end company info -->
          <div class="invoice-dates">
            <!-- date item -->
            <div class="date-item">
              <div class="invoice-dates__key">
                <i class="fas fa-calendar-alt"></i>
                تاريخ دورة الفاتورة
              </div>

              <div class="invoice-dates__date">
                <template v-if="downloadInvoice.Date">{{
                  downloadInvoice.Date
                }}</template>
                <template v-else> -- </template>
              </div>
            </div>
            <!-- .end date item -->
            <!-- date item -->
            <div class="date-item">
              <div class="invoice-dates__key">
                <i class="fas fa-calendar-alt"></i>
                تاريخ التسديد
              </div>

              <div class="invoice-dates__date">
                <template v-if="downloadInvoice.DateTo">{{
                  downloadInvoice.DateTo
                }}</template>
                <template v-else> -- </template>
              </div>
            </div>
            <!-- .end date item -->
            <div class="break"></div>
            <!-- date item -->
            <div class="date-item">
              <div class="invoice-dates__key">
                <i class="fas fa-calendar-alt"></i>
                إلى
              </div>

              <div class="invoice-dates__date">
                <template v-if="downloadInvoice.DateTo">{{
                  downloadInvoice.DateTo
                }}</template>
                <template v-else> -- </template>
              </div>
            </div>
            <!-- .end date item -->
          </div>
          <!-- /.end invoice dates -->
          <!-- invoices tables -->
          <table>
            <thead>
              <tr>
                <th>الرقم</th>
                <th>اسم الخدمه</th>
                <th>العدد</th>
                <th>سعر الوحدة</th>
                <th>الاجمالي</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(row, i) in downloadInvoice.BillingRecordItems"
                :key="row.Id"
              >
                <tr v-if="row.Amount">
                  <td>
                    {{ i + 1 }}
                  </td>
                  <td class="fw-bl text-right">
                    {{ row.Name.ArabicValue }}
                  </td>
                  <td>---</td>
                  <td>---</td>
                  <td>{{ row.Amount }} <br />{{ currency }}</td>
                </tr>
              </template>
              <tr class="bg-white" v-if="downloadInvoice.BillingRecordItems">
                <td></td>
                <td></td>
                <td colspan="3">
                  <table>
                    <tr>
                      <td>المجموع الصافي</td>
                      <td>
                        {{
                          getTotalAmountWithoutTax(
                            downloadInvoice.BillingRecordItems
                          )
                        }}
                        <br />{{ currency }}
                      </td>
                    </tr>
                    <!-- <tr>
                    <td>ضريبة القيمة المضافة</td>
                    <td>
                      {{ getTaxAmount(invoice.BillingRecordItems) }}
                    </td>
                  </tr> -->
                    <tr>
                      <td>المجموع الاجمالي المطلوب</td>
                      <td>{{ downloadInvoice.Amount }} <br />{{ currency }}</td>
                    </tr>
                    <tr>
                      <td>الرصيد المستحق</td>
                      <td>{{ downloadInvoice.Amount }} <br />{{ currency }}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th class="px-1 text-right">ملاحظات</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th class="px-1 text-right">الشروط والاحكام</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          <!-- ./end invoices tables -->
        </div>
      </template>
    </vue3-html2pdf>
  </template>
</template>

<script>
import TableLite from "vue3-table-lite";
import InvoiceTableLoading from "./InvoiceTableLoading";
import InvoiceDetailsModal from "@/components/invoices/InvoiceDetailsModal.vue";
import InvoicePrintModal from "@/components/invoices/InvoicePrintModal.vue";
import Vue3Html2pdf from "vue3-html2pdf";

export default {
  components: {
    TableLite,
    InvoiceTableLoading,
    InvoiceDetailsModal,
    InvoicePrintModal,
    Vue3Html2pdf,
  },
  props: {
    data: Array,
    detailsModalCB: Function,
    printModalCB: Function,
    currency: String,
  },
  watch: {
    // data(to) {
    //   this.isLoading = false;
    // },
  },
  data() {
    return {
      dataId: "",
      printId: "",
      loadingData: true,
      isLoading: false,
      columns: [
        {
          label: "#",
          field: "Id",
          width: "3%",
          sortable: true,
          isKey: true,
        },
        {
          label: "التاريخ",
          field: "Date",
          sortable: true,
        },
        {
          label: "رقم الفاتورة",
          field: "InvoiceId",
          sortable: true,
        },
        // {
        //   label: "رقم الاشتراك",
        //   field: "SubscriptionId",
        //   sortable: true,
        // },
        {
          label: "الوصف",
          field: "Description",
          sortable: true,
          width: "25%",
        },
        {
          label: "المبلغ",
          field: "Amount",
          sortable: true,
        },
        {
          label: "",
          field: "show",
          display: function (row) {
            return (
              '<button data-id="' +
              row.Id +
              '" class="is-rows-el show-btn btn btn-primary btn-icon"><i class="far fa-eye"></i>عرض</button>'
            );
          },
        },
        {
          label: "",
          field: "download",
          display: function (row) {
            return (
              '<button data-id="' +
              row.Id +
              '" class="is-rows-el download-btn icon-btn"><i class="far fa-download"></i></button>'
            );
          },
        },
        {
          label: "",
          field: "print",
          display: function (row) {
            return (
              '<button data-id="' +
              row.Id +
              '" class="is-rows-el print-btn icon-btn"><i class="fas fa-print"></i></button>'
            );
          },
        },
      ],
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "id",
        sort: "asc",
      },
      changeDetailsModalCB: null,
      changePrintModalCB: null,
      downloadInvoice: null,
    };
  },
  mounted() {
    this.getInvoices();
    // console.log(this.rows);
  },
  methods: {
    changeDetailsModalStatus(cb) {
      this.changeDetailsModalCB = cb();
    },
    changePrintModalStatus(cb) {
      this.changePrintModalCB = cb();
    },
    getInvoices() {
      this.isLoading = true;
      // const data = Service.getAllInvoices();
      this.rows = this.data;
      this.isLoading = false;
    },
    doSearch() {},
    // tableLoadingFinish() {},
    showModal(id, modalType = "details") {
      // console.log(id);
      // send this //BillingRecordItems to the modal to show it
      // getInvoiceDetails(d)
      const invoice = this.data.find((i) => i.Id == id);
      // console.log("modalType", modalType);
      if (this.detailsModalCB) {
        if (modalType == "details") this.detailsModalCB(invoice, true);
        else this.printModalCB(invoice, true);
      } else {
        if (modalType == "details") this.changeDetailsModalCB(invoice, true);
        else this.changePrintModalCB(invoice, true);
      }
    },
    checkRow(row) {
      console.log(row);
    },
    tableLoadingFinish(elements) {
      this.isLoading = false;
      this.loadingData = false;
      elements.forEach((element) => {
        if (element.classList.contains("show-btn")) {
          element.addEventListener("click", (e) => {
            this.dataId = element.dataset.id;
            this.showModal(this.dataId);
          });
        }
        if (element.classList.contains("print-btn")) {
          element.addEventListener("click", (e) => {
            this.printId = element.dataset.id;
            this.showModal(this.printId, "print");
          });
        }
        if (element.classList.contains("download-btn")) {
          element.addEventListener("click", (e) => {
            this.downloadInvoice = this.data.find(
              (i) => i.Id == element.dataset.id
            );
            // console.log("this is download invoice:>", this.downloadInvoice);
            setTimeout(() => {
              this.$refs.html2Pdf.generatePdf();
            }, 1000);
          });
        }
      });
    },
    onProgress(e) {},
    hasGenerated(e) {},
    getTotalAmountWithoutTax(arr) {
      return arr.reduce(
        (ac, { Amount, Id }) => (Id !== 21450 ? ac + Amount : ac),
        0
      );
    },
  },
};
</script>

<style lang="scss">
.invoice-table {
  table {
    position: relative;
    z-index: 2;
    font-size: 1.8rem;
  }
  thead {
    position: relative;
    z-index: 3;
    color: #525c6b;
    font-weight: bold;
    box-shadow: 0px 3px 6px #00000029;
    tr {
      height: 7rem;
      color: #525c6a;
      font-size: 1.8rem;
      font-weight: bold;
      background-color: #fff;
      border: 0;
    }
  }
  .vtl-table th {
    color: #525c6a;
  }
  .vtl-table td {
    color: #96a4af;
  }
}
// ::v-deep(.vtl-paging-info) {
//   color: rgb(172, 0, 0);
// }
// ::v-deep(.vtl-paging-count-label),
// ::v-deep(.vtl-paging-page-label) {
//   color: rgb(172, 0, 0);
// }
// ::v-deep(.vtl-paging-pagination-page-link) {
//   border: none;
// }

.vtl-paging.vtl-row {
  display: none;
}
</style>