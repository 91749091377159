<template>
  <form ref="cardForm" :action="formActionUrl" method="post">
    <span style="color: red">{{ $route.query.response_message }}</span>
    <div class="form-row grid grid2-1">
      <div class="form-group">
        <label>الاسم على البطاقة</label>
        <input
          :class="'form-control ' + (cardErrors.cardHolder ? 'error' : '')"
          type="text"
          :data-error="cardErrors.cardHolder ? true : false"
          placeholder="enter card holder name"
          v-model="cardHolder"
          name="card_holder_name"
        />
        <!-- validation error -->
        <div v-if="cardErrors.cardHolder" class="error">
          <small>{{ cardErrors.cardHolder }}</small>
        </div>
      </div>
      <div class="form-group">
        <label>تاريخ الصلاحية</label>
        <input
          :class="'form-control ' + (cardErrors.cardExpiry ? 'error' : '')"
          name="expiry_date"
          ref="cardExpiry"
          :data-error="cardErrors.cardExpiry ? true : false"
          v-model="cardExpiry"
          v-cardformat:formatCardExpiry
          placeholder="MM/YY"
        />
        <!-- validation error -->
        <div v-if="cardErrors.cardExpiry" class="error">
          <small>{{ cardErrors.cardExpiry }}</small>
        </div>
      </div>
      <div class="form-group">
        <label>رقم البطاقة</label>
        <input
          :class="'form-control ' + (cardErrors.cardNumber ? 'error' : '')"
          name="card_number"
          ref="cardNumber"
          :data-error="cardErrors.cardNumber ? true : false"
          v-model="cardNumber"
          v-cardformat:formatCardNumber
          placeholder="1234 1234 1234 1234"
        />
        <!-- validation error -->
        <div v-if="cardErrors.cardNumber" class="error">
          <small>{{ cardErrors.cardNumber }}</small>
        </div>
      </div>

      <div class="form-group">
        <label>رمز الحماية(cvv)</label>
        <input
          :class="'form-control ' + (cardErrors.cardCvc ? 'error' : '')"
          name="card_security_code"
          ref="cardCvc"
          :data-error="cardErrors.cardCvc ? true : false"
          v-model="cardCvc"
          v-cardformat:formatCardCvc
          placeholder="CVV"
        />
        <!-- validation error -->
        <div v-if="cardErrors.cardCvc" class="error">
          <small>{{ cardErrors.cardCvc }}</small>
        </div>
      </div>
      <div class="form-data">
        <input
          type="hidden"
          name="access_code"
          id="access_code"
          v-model="access_code"
        />
        <input type="hidden" name="language" id="language" v-model="language" />
        <input type="hidden" name="remember_me" id="remember_me" value="YES" />
        <input
          type="hidden"
          name="service_command"
          id="service_command"
          v-model="service_command"
        />
        <input
          type="hidden"
          name="merchant_identifier"
          id="merchant_identifier"
          v-model="merchant_identifier"
        />
        <input
          type="hidden"
          name="return_url"
          id="return_url"
          v-model="return_url"
        />
        <input
          type="hidden"
          name="merchant_reference"
          id="merchant_reference"
          v-model="merchant_reference"
        />
        <input
          type="hidden"
          name="signature"
          id="signature"
          v-model="signature"
        />
        <!-- <input type="hidden" v-for="item in hiddenData" :key="item" :name="item" :v-model="item"> //for advanced I'll implement this-->
        <!-- value="0722aeb52ca796b20325f1da75443256ad598b3b012c91dff70e21437929e2f6" -->
      </div>
    </div>
    <div class="submit" style="text-align: center; margin-top: 2rem">
      <button type="button" class="btn btn-info btn-wide" @click="submitform()">
        حفظ
      </button>
    </div>
  </form>
</template>

<script>
import PaymentUtils from "./PaymentUtils";
export default {
  data() {
    return {
      cardHolder: null,
      cardCvc: null,
      cardNumber: null,
      cardExpiry: null,
      cardErrors: {},
      formData: {},
      formActionUrl: process.env.VUE_APP_PAYMENT_CARD_ACTION,
      return_url: "",
      access_code: process.env.VUE_APP_ACCESS_CODE,
      merchant_identifier: process.env.VUE_APP_MER_ID,
      pass: process.env.VUE_APP_SECRET_PASS,
      service_command: "TOKENIZATION",
      language: "ar",
      merchant_reference: PaymentUtils.generateId(), // this will be order id, or uuid id
      signature: "",
      errorMessage: false,
      creditCardType: {
        0: "Visa",
        1: "MasterCard",
        2: "AmericanExpress",
        3: "Mada",
      },
    };
  },
  watch: {
    cardHolder(value) {
      this.cardErrors.cardHolder = null;
      if (value.match(/[\u0600-\u06FF]/g) != null) {
        this.cardErrors.cardHolder =
          "card holder name cannot contain arabic charactars";
      } else if (value.match(/\d+/g) != null) {
        this.cardErrors.cardHolder = "card holder name cannot contain numbers";
      }
    },
    cardCvc() {
      this.cardErrors.cardCvc = null;
    },
    cardNumber() {
      this.cardErrors.cardNumber = null;
    },
    cardExpiry() {
      this.cardErrors.cardExpiry = null;
    },
  },
  mounted() {
    console.log("this is route:>", this.$route.path.replace("/", ""));
  },
  methods: {
    submitform() {
      if (this.validateForm()) {
        this.return_url = `${
          process.env.VUE_APP_API_BASE_URL
        }PaymentPayfort/RedirectTokenization?portal=GoDafaterPortal&path=${this.$route.path.replace(
          "/",
          ""
        )}`; //window.location.href; // add /dist/ or /pay/ when deploy
        const dataObj = {
          access_code: this.access_code,
          language: this.language,
          merchant_identifier: this.merchant_identifier,
          merchant_reference: this.merchant_reference,
          return_url: this.return_url,
          service_command: this.service_command,
        };
        PaymentUtils.generateSignature(dataObj, this.pass)
          .then((d) => {
            this.signature = d;
            this.cardExpiry = this.cardExpiry
              .replaceAll(" ", "")
              .split("/")
              .reverse()
              .join("");
            this.cardNumber = this.cardNumber.replaceAll(" ", "");
            return new Promise((resolve) => {
              resolve(this.signature);
            });
          })
          .then((c) => {
            // console.log("now we are going to submit..");
            this.$refs.cardForm.submit();
          });
      }
    },
    validateForm() {
      this.cardErrors = {};
      if (
        !this.cardHolder ||
        this.cardHolder.match(/\d+/g) != null ||
        this.cardHolder.match(/[\u0600-\u06FF]/g) != null
      ) {
        this.cardErrors.cardHolder = "Invalid Credit Card Holder Name.";
      }
      // validate card number
      if (!this.$cardFormat.validateCardNumber(this.cardNumber)) {
        // this.$cardFormat.validateCardCVC(this.card);
        this.cardErrors.cardNumber = "Invalid Credit Card Number.";
      }

      // validate card expiry
      if (!this.$cardFormat.validateCardExpiry(this.cardExpiry)) {
        this.cardErrors.cardExpiry = "Invalid Expiration Date.";
      }

      // validate card CVC
      if (!this.$cardFormat.validateCardCVC(this.cardCvc)) {
        this.cardErrors.cardCvc = "Invalid CVC.";
      }
      const isValid = Object.keys(this.cardErrors).length === 0;
      return isValid;
    },

    // creditCardType(code) {
    //   const types =
    //   return types[code];
    // },
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: red;
}
input.error {
  border-color: red;
}
</style>