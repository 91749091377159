<template>
  <div class="trail-subscription">
    <!-- <radial-progress
      v-if="data.expire"
      :progress="65"
      :angle="-90"
      color="#21B2C8"
      emptyColor="#916AAB"
      :size="120"
      :thickness="15"
      emptyThickness="15"
      animation="reverse 700 400"
      :gap="0"
      dot="15 #21B2C8"
    >
      <template #legend-caption>
        <div class="progress__left">متبقي</div>
        <div class="progress__days-left">
          <span class="days-left__no">
            {{ data.expire }}
          </span>
          <div class="days-left__d">يوم</div>
        </div>
      </template>
    </radial-progress> -->
    <!-- <copy-url
      v-if="data"
      label="رابط الباقة"
      :url="data.Url"
      withoutBtn
      class="sub-link-field"
    /> -->
    <h4 v-if="!showCredInfo">احصل على معلومات الربط بباقتك</h4>
    <div
      style="display: flex; justify-content: center; height: 100%"
      v-if="loading"
    >
      <div class="loader"></div>
    </div>
    <template v-if="hasGeneratedBefore">
      <div class="grid grid-1x2 mt-4">
        <copy-url label="Client ID" :url="credentials.clientID" />
        <copy-url label="Client Secret" withoutBtn :url="'*******'" />
      </div>
      <button @click="() => getCred('UPDATE', false)" class="btn btn-info mt-2">
        الحصول على كلمة المرور
      </button>
    </template>
    <template v-else>
      <button
        @click="() => getCred()"
        class="btn btn-info mt-2"
        v-if="!showCredInfo"
      >
        الربط بالـAPI
      </button>
      <div class="grid grid-1x2" v-if="showCredInfo && credentials">
        <copy-url label="Client ID" :url="credentials.clientID" />
        <copy-url label="Client Secret" :url="credentials.clientCredential" />
      </div>
    </template>
    <!-- <div class="grid grid-1x2">
      <button @click="$emit('cancel')" class="btn btn-dark">
        إلغاء الباقة
      </button>
      <button @click="$emit('share')" class="btn btn-info btn-icon">
        <i class="fas fa-share-square"></i>
        مشاركة بيانات الباقة
      </button>
    </div> -->
    <br />
    <br />
    <h4>يمكنك استخدام نفس المعلومات لتهيئة نقاط البيع</h4>
  </div>

  <m-modal
    class="modal-warning"
    v-show="showWarningModal"
    @close="closeWarningModal"
    hasFooter
  >
    <!-- Modal header -->
    <template #header> تأكيد الحصول على المعلومات </template>

    <!-- Modal body -->
    <template #body>
      <div>
        الحصول على معلومات ربط جديدة ستجعل من الانظمة المرتبطة بالمعلومات
        القديمة لا تعمل.
        <h4 class="fs-20 mt-3">هل انت موافق؟</h4>
      </div>
    </template>

    <template #footer>
      <div class="modal__action">
        <a class="btn btn-primary" @click="() => getCred('UPDATE', true)">
          موافق
        </a>
        <a class="btn btn-dark" @click="closeWarningModal"> إلغاء الطلب </a>
      </div>
    </template>
  </m-modal>
</template>

<script>
import CopyUrl from "@/components/common/CopyUrl.vue";
import SubscriptionService from "@/services/SubscriptionService";
import MModal from "@/components/common/MModal.vue";
export default {
  components: { CopyUrl, MModal },

  props: {
    data: Object,
  },
  mounted() {},
  data() {
    return {
      showWarningModal: false,
      showCredInfo: false,
      credentials: null,
      loading: true,
      hasGeneratedBefore: false,
    };
  },
  watch: {
    data(to) {
      this.getCredStatus();
    },
  },
  methods: {
    async getCredStatus() {
      this.loading = true;
      const response = await SubscriptionService.getApiCredentials(
        this.data.SubscriberIdentifier,
        "GET"
      );
      if (response && response.data.statusCode == 201) {
        this.hasGeneratedBefore = true;
        this.credentials = response.data.result.data;
      }
      this.loading = false;
    },
    async getCred(method = "POST", confirm = false) {
      this.loading = true;
      this.showWarningModal = false;
      if (method == "UPDATE" && !confirm) this.showWarningModal = true;
      else if ((method == "UPDATE" && confirm) || method == "POST") {
        this.hasGeneratedBefore = false;
        const response = await SubscriptionService.getApiCredentials(
          this.data.SubscriberIdentifier,
          method
        );
        if (response && response.data.statusCode == 201) {
          this.credentials = response.data.result.data;
          this.showCredInfo = true;
          this.loading = false;
        } else {
          this.$toast.warning("عفواً هناك مشكلة ما!");
          this.loading = false;
        }
      }
    },
    closeWarningModal() {
      this.showWarningModal = false;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.trail-subscription {
  .ep-container {
    margin-top: -20rem;
  }
  button.btn {
    font-size: 1.6rem;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    i {
      margin-right: 0;
    }
  }

  .copy-url-comp {
    font-size: 1.4rem;
    text-align: right;
    .btn {
      font-size: 1.4rem;
    }
  }
}

.progress__left {
  font-size: 1.6rem;
  font-weight: bold;
  color: #3b3939;
}
.progress__days-left {
  display: flex;
  align-items: center;
}
.days-left__no {
  font-size: 3.2rem;
  font-weight: bold;
  color: #1fb1c6;
}
.days-left__d {
  font-size: 1.3rem;
  color: #3b3939;
}
.ep-legend--value {
  display: none;
}

.sub-link-field .link-copy__input {
  max-width: 100%;
}
</style>