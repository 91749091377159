<template>
  <div class="loading-screen">
    <h2 class="loading-screen__quote">
      <VueWriter :eraseSpeed="50" :typeSpeed="100" :array="replace" />
    </h2>
    <div class="progress-container">
      <template v-for="({ status, text }, i) in loadingItems" :key="i">
        <div v-if="status == 'done'" class="loading-item success">
          <i class="fas fa-check-circle"></i>
          <span>تم {{ text }}</span>
        </div>
        <div v-else-if="status == 'doing'" class="loading-item process">
          <img src="@/assets/images/inprogress.svg" alt="" />
          <span>جاري {{ text }}</span>
        </div>
        <div v-else class="loading-item hold">
          <i class="fas fa-circle"></i>
          <span>{{ text }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    loadingItems: Array,
  },
  mounted() {
    // console.log("this is loadingItems:.>", this.loadingItems);
    // this.replace = [
    //   ...this.quots.map((quot, i) => ({
    //     from: this.quots[i - 1].ar,
    //     to: quot.ar,
    //   })),
    // ];
  },
  data() {
    return {
      quots: [
        {
          ar: "الهدف النهائي للحياة هو الفعل و ليس العلم ، فالعلم بلا عمل لا يساوي شيئاً .نحن نتعلم لكي نعمل.",
          en: "Opportunities don't happen. You create them.",
          auth: { ar: "توماس هكسلي", en: "Chris Grosser" },
        },
        {
          ar: "الغاية غير المخطط لها مجرد حلم.",
          en: "There are two types of people who will tell you that you cannot make a difference in this world: those who are afraid to try and those who are afraid you will succeed.",
          auth: { ar: "دوجلاس ماكجريجور", en: "Ray Goforth" },
        },
        {
          ar: "هناك ثلاثة أساسيات عظيمة لتحقيق أى شىء ذو قيمة وهم: العمل الجاد والتمسك به والسلوك الحسن.",
          en: "All progress takes place outside the comfort zone.",
          auth: { ar: "توماس أديسون", en: "Michael John Bobak" },
        },
        {
          ar: "عجلة الحظ لا يدفعها إلا العمل.",
          en: "The only limit to our realization of tomorrow will be our doubts of today.",
          auth: { ar: "فرانسيس بيكون", en: "Franklin D. Roosevelt" },
        },
        {
          ar: "الرجل القوي يعمل والضعيف يتمنى.",
          en: "If you really want to do something, you'll find a way. If you don't, you'll find an excuse.",
          auth: { ar: "برناردشو", en: "Jim Rohn" },
        },
      ],
      replace: [
        '"عجلة الحظ لا يدفعها إلا العمل."',
        '"الغاية غير المخطط لها مجرد حلم."',
        '"الهدف النهائي للحياة هو الفعل و ليس العلم ، فالعلم بلا عمل لا يساوي شيئاً .نحن نتعلم لكي نعمل."',
        '"الرجل القوي يعمل والضعيف يتمنى."',
        '"هناك ثلاثة أساسيات عظيمة لتحقيق أى شىء ذو قيمة وهم: العمل الجاد والتمسك به والسلوك الحسن."',
      ],
    };
  },
  methods: {
    renderQuotes(index = this.quots.length - 1) {
      setTimeout(() => {
        index == 0
          ? this.renderQuotes(this.quots.length - 2)
          : this.renderQuotes((index -= 1));
      }, 3000);
      console.log("this is index:>", index);
      return this.quots[index].ar;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/abstracts/mixins";
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  color: #000;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  &__quote {
    color: #7d62a7;
    font-weight: bold;
    font-size: 4.5rem;
    width: 90rem;
    text-align: center;
    line-height: 7.5rem;
    @include responsive(tab-port) {
      width: 50rem;
    }
  }
}
.loading-item {
  display: flex;
  align-items: center;
  margin: 2rem 0;
  font-size: 1.5rem;
  i,
  img {
    margin: 0 1rem;
  }
  &.success {
    color: #5d5d5e;
    i {
      color: #37c140;
    }
  }
  &.process {
    color: #5d5d5e;
    font-weight: bold;
    img {
      width: 1.7rem;
    }
  }
  &.hold {
    color: #5d5d5e;
    &,
    & i {
      opacity: 0.5;
    }
  }
}
.is-typed {
  // font-family: "Monaco";
}

.is-typed span.typed {
  // color: black;
}

.is-typed span.cursor {
  display: inline-block;
  width: 3px;
  background-color: #7d62a7;
  animation: blink 1s infinite;
}

.is-typed span.underscore {
  display: inline-flex;
  width: 10px;
  height: 1px;
  align-items: flex-end;
  background-color: #7d62a7;
  animation: blink 1s infinite;
}

.is-typed span.cursor.typing {
  animation: none;
}

@keyframes blink {
  49% {
    background-color: #7d62a7;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
}
</style>