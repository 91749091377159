<template>
  <!-- <button @click="isModalVisible = true" class="btn btn-primary btn-icon">
    <i class="far fa-eye"></i>
    عرض
  </button> -->
  <m-modal v-show="isModalVisible" @close="isModalVisible = false">
    <template #header> {{invoice.InvoiceId}} تفاصيل الفاتورة </template>
    <template #body>
      <div class="package__details">
        <div class="details__prop">
          <span class="key">الباقة</span>
          <span class="value" v-if="invoice.BillingRecordItems">{{
            invoice.BillingRecordItems[0].Name.ArabicValue
          }}</span>
        </div>
        <!-- <div class="details__prop">
          <span class="key">المدة</span>
          <span class="value" v-if="pack.name">{{ invoice.duration }}</span>
        </div> -->
        <div class="details__prop">
          <span class="key">المبلغ</span>
          <span class="value">{{ invoice.Amount }}{{ currency }}</span>
        </div>
      </div>
      <table class="package__adv-table">
        <thead>
          <tr>
            <th>الميزة</th>
            <!-- <th>العدد</th> -->
            <th>سعر الوحدة</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="invoice.BillingRecordItems">
            <template
              v-for="({ Amount, Name }, i) in invoice.BillingRecordItems"
              :key="i"
            >
              <tr v-if="Amount">
                <td>{{ Name.ArabicValue }}</td>
                <!-- <td></td> -->
                <td>{{ Amount }} {{ currency }}</td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
      <!-- <p class="tax" v-if="pack.name">ضريبة القيمة المضافة %{{ pack.tax }}</p> -->
    </template>
  </m-modal>
</template>

<script>
import Service from "@/services/InvoicesService";
import MModal from "@/components/common/MModal.vue";
import { getCurrency } from "@/util/utils";
export default {
  components: { MModal },
  props: {
    dataId: String,
    currency: String,
  },
  mounted() {
    this.$emit("modalStatus", this.controlModalStatus);
  },
  data() {
    return {
      invoice: {},
      pack: {},
      adv: {},
      isModalVisible: false,
    };
  },
  watch: {
    dataId() {
      // this.invoice = Service.getInvoice(this.dataId);
      // this.pack = Service.getPackage(this.dataId);
      // this.adv = this.pack.advantiges;
      // this.isModalVisible = true;
    },
  },
  methods: {
    controlModalStatus() {
      return (invoice, isShow) => {
        this.invoice = invoice;
        console.log("this is  invoice:>", this.invoice);
        this.isModalVisible = isShow;
      };
    },
    getCurrency() {
      return getCurrency();
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 5px;
  padding: 1.5rem 2.5rem;
  font-size: 1.8rem;
  i {
    margin-right: 0;
  }
}
.package {
  &__details {
    margin-bottom: 2rem;
    text-align: right;
  }
  &__adv-table {
  }
}
.details__prop {
  margin-bottom: 2rem;
  color: #3b3939;
  &:not(:first-child) {
    display: inline-block;
    margin-left: 5rem;
  }
  span:first-child {
    margin-left: 2rem;
    font-weight: bold;
  }
}
.tax {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 2rem 0 0 0;
  color: #3b3939;
  text-align: right;
}
</style>