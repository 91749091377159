<template>
  <table>
    <thead>
      <tr>
        <th>#</th>
        <th>رقم الاشتراك</th>
        <th>نوع الباقة/الاشتراك</th>
        <th>تاريخ التجديد</th>
        <!-- <th>تجديد تلقائي</th> -->
        <th>رابط الاشتراك</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text> /
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
      </tr>
      <tr>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text> /
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>

        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
      </tr>
      <tr>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text> /
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
      </tr>
      <tr>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text> /
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
        <td>
          <skeleton-text effect="wave">abigwordhere</skeleton-text>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { SkeletonText } from "skeleton-elements/vue";
export default {
  components: { SkeletonText },
  data() {
    return {
      data: [],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>