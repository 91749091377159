<template>
  <div class="subscription-details">
    <!-- /subscription details section/ -->
    <div class="subscription-details__section-title mt-1">
      <skeleton-text tag="h3" class="mb-0" effect="wave"
        >one word</skeleton-text
      >

      <span class="fs-18 fw-b">
        <skeleton-text effect="wave">one word</skeleton-text>
        <skeleton-text class="mx-2" effect="wave">abigword</skeleton-text>
      </span>
    </div>
    <section class="subscription-details__section mb-2">
      <header class="section__header pA-2">
        <span class="fs-18">
          <skeleton-text effect="wave">one word</skeleton-text>
        </span>
        <skeleton-block
          effect="wave"
          width="90px"
          height="25px"
          borderRadius="20px"
          class="mx-2"
        />
      </header>
      <div class="section__body grid grid3-1">
        <!-- here is the section body -->
        <div class="subscription__info py-2">
          <ul class="list list-ver">
            <li>
              <div class="key">
                <skeleton-text effect="wave">one word</skeleton-text>
              </div>
              <div class="val">
                <skeleton-text effect="wave">three or more word</skeleton-text>
              </div>
            </li>
            <li>
              <div class="key">
                <skeleton-text effect="wave">three words here</skeleton-text>
              </div>
              <div class="val">
                <skeleton-text tag="h4" effect="wave">one word</skeleton-text>
              </div>
            </li>
            <li>
              <div class="key">
                <skeleton-text effect="wave">one word</skeleton-text>
              </div>
              <div class="val">
                <skeleton-text effect="wave">may be two words</skeleton-text>
              </div>
            </li>
            <li>
              <div class="key">
                <skeleton-text effect="wave">two or word</skeleton-text>
              </div>
              <div class="val">
                <skeleton-text effect="wave">one word</skeleton-text>
              </div>
            </li>
            <li>
              <div class="key">
                <skeleton-text effect="wave">one word</skeleton-text>
              </div>
              <div class="val">
                <skeleton-text effect="wave">three words or one</skeleton-text>
              </div>
            </li>
            <li>
              <div class="key">
                <skeleton-text effect="wave">one word</skeleton-text>
              </div>
              <div class="val">
                <skeleton-text effect="wave">one word</skeleton-text>
              </div>
            </li>
          </ul>
        </div>
        <div class="subscription__expire flex-center">
          <skeleton-block
            tag="div"
            width="170px"
            height="170px"
            borderRadius="50%"
            effect="wave"
          />
        </div>
      </div>
      <div class="section__footer grid grid3-1 pA-2">
        <div class="subscription__url flex-center-y">
          <label class="mx-2 nowrap fs-16">
            <skeleton-text tag="h4" effect="wave">one word</skeleton-text>
          </label>
          <div class="modal-copy__input">
            <skeleton-block height="40px" width="100%" effect="wave" />
          </div>
        </div>
        <div></div>
      </div>
    </section>
    <!-- ../subscription details section/ -->
    <div class="subscription-details__section-title">
      <skeleton-text tag="h3" class="mb-0" effect="wave"
        >one word</skeleton-text
      >
    </div>
    <section class="subscription-details__section">
      <table class="subscription-details__table">
        <tr>
          <td>
            <skeleton-text effect="wave">one word</skeleton-text>
          </td>
          <td><skeleton-text effect="wave">onewordhere</skeleton-text></td>
          <td>
            <span class="mx-1"
              ><skeleton-text effect="wave">word</skeleton-text></span
            >
            <skeleton-text effect="wave">onewordhere</skeleton-text>
          </td>
        </tr>
        <tr>
          <td>
            <skeleton-text effect="wave">three words here</skeleton-text>
          </td>
          <td>
            <table>
              <tr>
                <td>
                  <skeleton-text effect="wave">two words</skeleton-text>
                </td>
              </tr>
              <tr>
                <td>
                  <skeleton-text effect="wave">three words here</skeleton-text>
                </td>
              </tr>
              <tr>
                <td>
                  <skeleton-text effect="wave">two words</skeleton-text>
                </td>
              </tr>
            </table>
          </td>
          <td>
            <table>
              <tr>
                <td>
                  <span class="mx-1"
                    ><skeleton-text effect="wave">word</skeleton-text></span
                  >
                  <skeleton-text effect="wave">onewordhere</skeleton-text>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="mx-1"
                    ><skeleton-text effect="wave">wo</skeleton-text></span
                  >
                  <skeleton-text effect="wave">onewordhere</skeleton-text>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="mx-1"
                    ><skeleton-text effect="wave">word</skeleton-text></span
                  >
                  <skeleton-text effect="wave">onewordhere</skeleton-text>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">three words here</skeleton-text></td>
          <td>
            <table>
              <tr>
                <td>
                  <skeleton-text effect="wave">onewordhere</skeleton-text>
                </td>
              </tr>
              <tr>
                <td>
                  <skeleton-text effect="wave">onewor/dhere</skeleton-text>
                </td>
              </tr>
              <tr>
                <td>
                  <skeleton-text effect="wave">two words</skeleton-text>
                </td>
              </tr>
            </table>
          </td>
          <td>
            <table>
              <tr>
                <td>
                  <span class="mx-1"
                    ><skeleton-text effect="wave">word</skeleton-text></span
                  >
                  <skeleton-text effect="wave">onewordhere</skeleton-text>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="mx-1"
                    ><skeleton-text effect="wave">word</skeleton-text></span
                  >
                  <skeleton-text effect="wave">onewordhere</skeleton-text>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="mx-1"
                    ><skeleton-text effect="wave">word</skeleton-text></span
                  >
                  <skeleton-text effect="wave">onewordhere</skeleton-text>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
import { SkeletonBlock } from "skeleton-elements/vue";
import { SkeletonText } from "skeleton-elements/vue";
export default {
  components: {
    SkeletonBlock,
    SkeletonText,
  },
};
</script>

<style>
</style>