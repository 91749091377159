const generateId = () => {
  const placeholder = [1e7] + -1e3 + -4e3 + -8e3 + -1e11;
  const uid = () => {
    const id = crypto.getRandomValues(new Uint8Array(1));
    return (uid ^ (id[0] & (15 >> (uid / 4)))).toString(16);
  };
  return placeholder.replace(/[018]/g, uid);
  // return "orderId-123456"
};

const prepareSignatureData = (dataObj, pass) => {
  return (
    Object.keys(dataObj)
      .sort()
      .reduce((prev, currentKey) => {
        return prev + `${currentKey}=${dataObj[currentKey]}`;
      }, pass) + pass
  );
};

const generateSignature = (objData, pass) => {
  const preparedSignatureData = prepareSignatureData(objData, pass);
  const utf8 = new TextEncoder().encode(preparedSignatureData);
  return crypto.subtle
    .digest("SHA-256", utf8)
    .then((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, "0"))
        .join("");
      return hashHex;
    })
    .then((s) => s);
};

const PaymentMethodTypeEnum = [
  "CreditCard",
  "CreditCardAlias",
  "Wiretransfer",
  "WiretransferToVirtualAccount",
  "Cash",
  "Check",
  "Sadad",
  "Others",
  "DirectPayment",
];

const BusinessDomain = {
  Retail: "التجزئة",
  Manufacturing: "التصنيع",
  "Non Profit": "غير ربحي",
  Governmental: "حكومي",
  Commercial: "تجاري",
  Other: "أخرى",
};
export default {
  generateId,
  prepareSignatureData,
  generateSignature,
  PaymentMethodTypeEnum,
};
