import http from "./httpService";

const getAllInvoices = async (
  pageIndex = 0,
  pageCount = 50,
  orderBy = "Date",
  ascending = false
) => {
  // calling apis here
  const sessionInvoices = JSON.parse(sessionStorage.getItem('invoices'));
  if(sessionInvoices) return sessionInvoices
  const queryParams = `pageIndex=${pageIndex}&pageCount=${pageCount}&orderBy=${orderBy}&ascending=${ascending}`;
  const { data: invoices } = await http.get(`/billing-records?${queryParams}`);
  sessionStorage.setItem('invoices', JSON.stringify(invoices))
  return invoices;
};
const getAllPackages = () => {
  return packages;
};

const getInvoice = (id) => {
  return invoices.find((i) => i.id == id);
};
const getPackage = (id) => {
  return packages.find((p) => p.id == id);
};

const invoices = [
  {
    id: "9289",
    createdAt: Date.now(),
    invoice_id: "IN240004",
    subscription_id: "SN240004",
    disc: "اشتراك",
    duration: "يوم",
    price: 2000.0,
    package_id: "1",
  },
  {
    id: "9288",
    createdAt: Date.now(),
    invoice_id: "IN240004",
    subscription_id: "SN240004",
    disc: "اشتراك",
    duration: "شهر",
    price: 2000.0,
    package_id: "1",
  },
  {
    id: "9287",
    createdAt: Date.now(),
    invoice_id: "IN240004",
    subscription_id: "SN240004",
    disc: "اشتراك",
    duration: "سنة",
    price: 2000.0,
    package_id: "5",
  },
  {
    id: "9286",
    createdAt: Date.now(),
    invoice_id: "IN240004",
    subscription_id: "SN240004",
    disc: "اشتراك",
    duration: "سنة",
    price: 2000.0,
    package_id: "1",
  },
  {
    id: "9285",
    createdAt: Date.now(),
    invoice_id: "IN240004",
    subscription_id: "SN240004",
    disc: "اشتراك",
    duration: "سنة",
    price: 2000.0,
    package_id: "3",
  },
  {
    id: "9284",
    createdAt: Date.now(),
    invoice_id: "IN240004",
    subscription_id: "SN240004",
    disc: "اشتراك",
    duration: "سنة",
    price: 2000.0,
    package_id: "2",
  },
  {
    id: "9283",
    createdAt: Date.now(),
    invoice_id: "IN240004",
    subscription_id: "SN240004",
    disc: "اشتراك",
    duration: "سنة",
    price: 2000.0,
    package_id: "2",
  },
];

const packages = [
  {
    id: "9289",
    name: "ERP Advanced",
    advantiges: {
      emp_no: 3,
      price_for_emp: 50,
      standard_app_no: 3,
      price_for_app: 50,
      fngr_print_no: 3,
      price_for_fngr_print: 50,
    },
    tax: 15,
  },
  {
    id: "9288",
    name: "ERP Advanced",
    advantiges: {
      emp_no: 3,
      price_for_emp: 50,
      standard_app_no: 3,
      price_for_app: 50,
      fngr_print_no: 3,
      price_for_fngr_print: 50,
    },
    tax: 15,
  },
  {
    id: "9287",
    name: "ERP Advanced",
    advantiges: {
      emp_no: 3,
      price_for_emp: 50,
      standard_app_no: 3,
      price_for_app: 50,
      fngr_print_no: 3,
      price_for_fngr_print: 50,
    },
    tax: 15,
  },
  {
    id: "9286",
    name: "ERP Advanced",
    advantiges: {
      emp_no: 3,
      price_for_emp: 50,
      standard_app_no: 3,
      price_for_app: 50,
      fngr_print_no: 3,
      price_for_fngr_print: 50,
    },
    tax: 15,
  },
  {
    id: "9285",
    name: "ERP Advanced",
    advantiges: {
      emp_no: 3,
      price_for_emp: 50,
      standard_app_no: 3,
      price_for_app: 50,
      fngr_print_no: 3,
      price_for_fngr_print: 50,
    },
    tax: 15,
  },
  {
    id: "9284",
    name: "ERP Advanced",
    advantiges: {
      emp_no: 3,
      price_for_emp: 50,
      standard_app_no: 3,
      price_for_app: 50,
      fngr_print_no: 3,
      price_for_fngr_print: 50,
    },
    tax: 15,
  },
  {
    id: "9283",
    name: "ERP Advanced",
    advantiges: {
      emp_no: 3,
      price_for_emp: 50,
      standard_app_no: 3,
      price_for_app: 50,
      fngr_print_no: 3,
      price_for_fngr_print: 50,
    },
    tax: 15,
  },
];

export default {
  getAllInvoices,
  getAllPackages,
  getInvoice,
  getPackage,
};
