<template>
  <a
    @click="$emit('toggle')"
    :class="`switcher-toggler ${on ? 'toggle-on' : 'toggle-off'}`"
  >
    <i class="fas fa-toggle-on"></i>
  </a>
</template>

<script>
export default {
  name: "SwitchBtn",
  emits: ["toggle"],
  props: {
    on: Boolean,
  },
};
</script>

<style lang="sss" scoped>
</style>