<template>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>رقم الاشتراك</th>
          <th>نوع الباقة/الاشتراك</th>
          <th>تاريخ التجديد</th>
          <!-- <th>تجديد تلقائي</th> -->
          <th>رابط الاشتراك</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in data"
          :key="row.Id"
          class="pointer"
          @click="$router.push(`/subscriptions/${row.Id}`)"
        >
          <td>
            {{ row.Id }}
          </td>
          <td>
            {{ row.Number }}
          </td>
          <td>
            {{ row.ServicePlan.Name.ArabicValue }}
            <template v-if="row.ServicePlan.BillingCycleOptions.length">
              /
              {{
                durationFormat(
                  row.ServicePlan.BillingCycleOptions[0].BillingCycleType
                ).ar
              }}
            </template>
          </td>
          <td>
            {{ formatDate(row.NextBillingDate) }}
          </td>
          <!-- <td>
          <ToggleSwichBtn :value="value" @toggle="$emit('toggleValue', row)" />
        </td> -->
          <td>
            <!-- commented till we do it from serverside -->
            <p>
              <a
                class="share-btn"
                @click.stop="$emit('showShareLinkModal', row.Url)"
              >
                <!-- @click="copyUrl(value) -->
                <i class="fal fa-link"></i>
              </a>
              مشاركة الرابط
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// import ToggleSwichBtn from "./ToggleSwichBtn.vue";
import { formatDate } from "@/util/date";
export default {
  // components: { ToggleSwichBtn },
  props: {
    data: Array,
  },
  data() {
    return {};
  },
  mounted() {
    // console.log(this.data);
  },
  methods: {
    isUrl(txt) {
      let pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(txt);
    },
    durationFormat(duration) {
      const d = {
        1: { ar: "شهري", en: "" },
        6: { ar: "نصف سنوي", en: "" },
        12: { ar: "سنوي", en: "" },
      };
      return d[duration];
    },
    formatDate(date) {
      return formatDate(date);
    },
  },
};
</script>

<style lang="scss" scoped>
a.share-btn {
  padding: 0.5rem;
  margin-left: 0.5rem;
  color: #fff;
  background-color: #8e69a8;
  border-radius: 5px;
  cursor: pointer;
}
</style>