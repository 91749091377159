<template>
  <tabs-section :tabs="3">
    <template #tab0
      ><span class="hide-on-mob">الاشتراكات</span> الحالية ({{
        currentSubscriptions.length
      }})</template
    >
    <template #tab1
      ><span class="hide-on-mob">إشتراكات</span> معلقة ({{
        suspendedSubscriptions.length
      }})</template
    >
    <template #tab2
      ><span class="hide-on-mob">إشتراكات</span> ملغاه ({{
        canceledSubscriptions.length
      }})</template
    >
    <template #tab0-body>
      <template v-if="currentSubscriptions.length == 0 && allSubscriptions">
        <!-- <template v-else> -->
        <p class="text-center">لا يوجد أي اشتراكات حالية</p>
        <!-- </template> -->
      </template>
      <template v-else>
        <subscription-card-loading v-if="!allSubscriptions" />

        <subscription-card
          v-else
          v-for="subscription in currentSubscriptions"
          :key="subscription.id"
          :subscription="subscription"
          hasTimer
        />
      </template>
    </template>
    <template #tab1-body>
      <template v-if="subscriptions">
        <subscription-card
          v-for="subscription in suspendedSubscriptions"
          :key="subscription.id"
          :subscription="subscription"
        />
      </template>
      <subscription-card-loading v-else />
      <template v-if="suspendedSubscriptions.length == 0">
        <p class="text-center">لا يوجد أي اشتراكات معلّقة</p>
      </template>
    </template>
    <template #tab2-body>
      <template v-if="subscriptions">
        <subscription-card
          v-for="subscription in canceledSubscriptions"
          :key="subscription.id"
          :subscription="subscription"
        />
      </template>
      <subscription-card-loading v-else />
      <template v-if="canceledSubscriptions.length == 0">
        <p class="text-center">لا يوجد أي اشتراكات ملغاه</p>
      </template>
    </template>
  </tabs-section>
</template>

<script>
import TabsSection from "@/components/common/TabsSection.vue";
import SubscriptionCard from "./SubscriptionCard.vue";
import SubscriptionCardLoading from "./SubscriptionCardLoading.vue";
import Service from "@/services/SubscriptionService";
export default {
  components: { TabsSection, SubscriptionCard, SubscriptionCardLoading },
  props: {
    subscriptions: Array,
  },
  watch: {
    subscriptions(newval) {
      console.log(newval);
      this.filterSubscriptions(newval);
    },
  },
  mounted() {
    this.allSubscriptions = this.subscriptions;
    this.filterSubscriptions(this.subscriptions);
  },
  data() {
    return {
      currentSubscriptions: [],
      canceledSubscriptions: [],
      suspendedSubscriptions: [],
      allSubscriptions: null,
    };
  },
  methods: {
    getCurrentSubscriptions(all) {
      console.log("this is all:>", all);
      return all.filter(
        ({ Status }) => Status < 3 || (Status > 7 && Status < 13)
      );
    },
    getCanceledSubscriptions(all) {
      return all.filter(({ Status }) => {
        return Status == 6 || Status == 7 || Status == 13;
      });
    },
    getSuspendedSubscriptions(all) {
      return all.filter(
        ({ Status }) => Status == 3 || Status == 4 || Status == 5
      );
    },
    filterSubscriptions(newval) {
      this.currentSubscriptions = this.getCurrentSubscriptions(newval);
      this.canceledSubscriptions = this.getCanceledSubscriptions(newval);
      this.suspendedSubscriptions = this.getSuspendedSubscriptions(newval);
    },
  },
};
</script>