<script>
import { useMsal } from "@/composition-api/useMsal";
export default {
  setup() {
    const { instance, accounts, inProgress } = useMsal();
    return {
      instance,
      accounts,
      inProgress,
    };
  },
  mounted() {
    sessionStorage.clear();
    sessionStorage.removeItem("signed");
    this.instance.logoutRedirect();
  },
};
</script>

<style>
</style>