<template>
  <div class="package-spec__card">
    <!-- <div class="package-spec__select">
      <FormKit type="checkbox" :value="false" outer-class="$reset" />
    </div> -->
    <div class="w100">
      <div class="flex-between">
        <div class="package-spec__info">
          <h4 class="package-spec__name">{{ name }}</h4>
          <p>
            {{
              BillingFrequency == 0
                ? additionalPrice
                : additionalPrice * cycleType
            }}{{ currency }}
          </p>
        </div>
        <div class="package-spec__counter">
          <input
            type="checkbox"
            v-model="isAdded"
            :checked="isAdded"
            @change="checking"
            :disabled="disabled"
          />
        </div>
      </div>
      <!-- <p class="mt-2" v-if="moreInfo">{{ moreInfo }}</p> -->
    </div>
  </div>
</template>

<script>
import { getCurrency } from "@/util/utils";
export default {
  props: {
    AddionalQuotaPacks: Number,
    Id: Number,
    name: String,
    additionalPrice: Number,
    currency: String,
    cycleType: Number,
    BillingFrequency: Number,
    otherProps: Object,
    // moreInfo: String,
  },
  data() {
    return {
      isAdded: false,
      clicking: false,
      disabled: false,
    };
  },
  mounted() {
    if (
      this.otherProps.PreviousAddionalQuotaPacks &&
      !(this.otherProps.PreviousAddionalQuotaPacks - this.AddionalQuotaPacks)
    )
      this.disabled = true;
    this.counter = this.AddionalQuotaPacks;
    if (this.AddionalQuotaPacks == 1) {
      this.isAdded = true;
      this.disabled = true;
      this.$emit("toggle", true);
    }
  },
  methods: {
    getCurrency() {
      return getCurrency();
    },
    checking() {
      if (this.disabled) return;
      this.$emit("toggle", this.isAdded);
    },
  },
};
</script>

<style lang="scss" scoped>
.package-spec {
  &__card {
    border-radius: 10px;
    background-color: #f6f6f6;
    display: flex;
    justify-content: space-between;
    padding: 4rem 3rem;
  }
  &__counter {
    width: 2.5rem;
    input {
      height: 2.5rem;
      cursor: pointer;
    }
  }
}
</style>
