<template>
  <div class="tab-section__header">
    <ul class="tab-section__tabs list">
      <li v-for="(tab, i) in tabsArr" :key="i">
        <a
          :class="i == 0 ? 'tab-section__tab active' : 'tab-section__tab'"
          :ref="`tab${tab}`"
          @click="tabClick(`tab${tab}`, `tab${tab}-body`)"
        >
          <!-- add active if i is 0 -->

          <slot :name="`tab${tab}`"> </slot>
        </a>
      </li>
    </ul>
  </div>
  <div class="tab-section__body-container">
    <div
      v-for="(tab, i) in tabsArr"
      :key="i"
      :class="i == 0 ? 'tab-section__body active' : 'tab-section__body'"
      :ref="`tab${tab}-body`"
    >
      <slot :name="`tab${tab}-body`"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: { tabs: Number },
  data() {
    return {
      tabsArr: Array.from(Array(this.tabs).keys()),
    };
  },
  methods: {
    tabClick(tab, body) {
      Object.entries(this.$refs).map((r) => r[1][0].classList.remove("active"));
      this.$refs[tab][0].classList.add("active");
      this.$refs[body][0].classList.add("active");
    },
  },
};
</script>

<style>
</style>