<script>
export default {
  name: "MModal",
  props: {
    hasFooter: {
      type: Boolean,
      default: false,
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    //should put close in click out of it
    //by adding window listener
  },
  mounted() {
    document.addEventListener("keydown", (e) =>
      e.key == "Escape" ? this.close() : null
    );
  },
  unmounted() {
    document.removeEventListener("keydown", (e) =>
      e.key == "Escape" ? this.close() : null
    );
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal">
        <header class="modal__header" v-if="hasHeader">
          <slot name="header"> This is the default tile! </slot>
          <a class="modal__close-btn" @click="close">
            <i class="fas fa-times"></i>
          </a>
        </header>

        <section class="modal__body">
          <slot name="body"> This is the default body! </slot>
        </section>

        <footer class="modal__footer" v-if="hasFooter">
          <slot name="footer">
            <button @click="close" class="btn btn-info btn-wide">إغلاق</button>
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>


<style lang="scss" scoped>
@import '../../assets/styles/abstracts/mixins';
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: rgba(#525c6a, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  min-width: 80rem;
  border-radius: 10px 10px 0 0;
  transition: all 0.4s ease;
  @include responsive(tab-port) {
   min-width: 80vw;
   max-width: 95vw;
  }
  &__header {
    border-radius: 10px 10px 0 0;
    background-color: #f2f2f2;
    color: #525c6a;
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 3rem;
  }
  &__close-btn {
    color: rgba(#525c6a, 0.5);
    width: 4rem;
    height: 4rem;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: #ededed;
    }
  }
  &__body {
    padding: 4rem;
    max-height: 60vh;
    max-width: 95vw;
    overflow: auto;
  }
  &__footer {
    margin-bottom: 5rem;
    text-align: center;
  }
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
  .modal {
    // transform: scale(0.5);
  }
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all 0.2s ease;
}
</style>