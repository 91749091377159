<template>
  <m-modal class="modal-advan" v-show="showModal" @close="$emit('close')">
    <!-- here should put features -->
    <template #header> مزايا الاشتراك</template>
    <template #body>
      <table>
        <thead>
          <tr>
            <th>رابط الاشتراك</th>
            <th>الوصف</th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <tr>
            <td>
              <skeleton-text effect="wave">advantage key </skeleton-text>
            </td>
            <td>
              <skeleton-text effect="wave">value</skeleton-text>
            </td>
          </tr>
          <tr>
            <td>
              <skeleton-text effect="wave">big key </skeleton-text>
            </td>
            <td>
              <skeleton-text effect="wave">value</skeleton-text>
            </td>
          </tr>
          <tr>
            <td>
              <skeleton-text effect="wave">very key </skeleton-text>
            </td>
            <td>
              <skeleton-text effect="wave">small value</skeleton-text>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <template v-for="feature in quotasFeatures" :key="feature.id">
            <tr>
              <td>
                {{ feature.Name.ArabicValue }}
              </td>
              <td v-if="feature.InitialQuotaPack == 0">
                لا يوجد وحدات، يمكنك الإضافة
              </td>
              <td v-else>
                {{ feature.InitialQuotaPack }} وحدات متاحة - قابلة للزيادة
              </td>
            </tr>
          </template>
          <template v-for="feature in nonApplicationFeatures" :key="feature.id">
            <tr>
              <td>
                {{ feature.Name.ArabicValue }}
              </td>
              <td><i class="fas fa-check-circle c-success"></i>متوفرة</td>
            </tr>
          </template>
          <template v-for="feature in moduleFeatures" :key="feature.id">
            <tr v-if="feature.InitialQuotaPack == 0">
              <td>
                {{ feature.Name.ArabicValue }}
              </td>
              <td>قابلة للإضافة</td>
            </tr>
          </template>
        </tbody>
      </table>
    </template>
  </m-modal>
</template>

<script>
import MModal from "@/components/common/MModal.vue";
import { SkeletonText } from "skeleton-elements/vue";

export default {
  components: { MModal, SkeletonText },
  props: {
    showModal: Boolean,
    features: Array,
    loading: Boolean,
  },
  data() {
    return {
      nonApplicationFeatures: null,
      quotasFeatures: null,
      moduleFeatures: null,
    };
  },
  mounted() {},
  watch: {
    features() {
      this.setFeatures(this.features);
    },
  },
  methods: {
    setFeatures(features) {
      this.moduleFeatures = this.getAppFeatureType(features, 0);
      this.nonApplicationFeatures = this.getAppFeatureType(features, 2);
      this.quotasFeatures = [
        ...this.getAppFeatureType(features, 4),
        ...this.getAppFeatureType(features, 3),
      ].sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    },
    getAppFeatureType(arr, type) {
      return arr.filter((item) => item.AppFeatureType == type);
    },
  },
};
</script>

<style>
</style>