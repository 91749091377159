import http from "./httpService";
import AccountService from "./AccountService";
import axios from "axios";

import PaymentUtils from "@/components/payment/PaymentUtils";

const getWalletBalance = async () => {
  const { Tenant } = await AccountService.getProfile();
  return Tenant.Balance;
};
const postPaymentCard = async (card) => {
  const { status, data } = await http.post("/account/payment-methods", card);
  // setPreferredCard(data.Id);
  return status;
};
const getSavedCards = async () => {
  const { data: cards } = await http.get("/account/payment-methods");
  return cards;
};
const setPreferredCard = async (paymentMethodId) => {
  const { status } = await http.post(
    `/account/payment-methods/${paymentMethodId}/preferred`
  );
  return status;
};
const depositBalance = async (
  amount,
  transactionId,
  description,
  paymentMethodType = 1
) => {
  const postData = {
    AmountToDeposit: amount,
    PaymentMethodType: paymentMethodType, // will depend
    TransactionId: transactionId,
    Description: description,
  };
  const { status } = await http.put("/account/balance", postData);
  return status;
};
const payToGateway = async (cardData) => {
  // this for payment gateway
  const { data } = await http.post("/payment?command=5200", cardData);
  return data;
};
const authGateway = async (cardData) => {
  const { data } = await http.post("/payment?command=5300", cardData);
  return data;
};
const removePaymentCard = async (Id) => {
  const { status } = await http.delete(`/account/payment-methods/${Id}`);
  return status;
};

const paymentMethodTypesEnum = [
  "CreditCard",
  "CreditCardAlias",
  "Wiretransfer",
  "WiretransferToVirtualAccount",
  "Cash",
  "Check",
  "Sadad",
  "Others",
  "DirectPayment",
];

const CreditCardTypeEnum = ["VISA", "MASTERCARD", "AmericanExpress", "MADA"];

export default {
  getWalletBalance,
  postPaymentCard,
  getSavedCards,
  setPreferredCard,
  removePaymentCard,
  depositBalance,
  payToGateway,
  authGateway,
  paymentMethodTypesEnum,
  CreditCardTypeEnum,
};
