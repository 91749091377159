<template>
  <router-view />
</template>

<script>
export default {
  mounted() {
    console.log("this is route", this.$route);
    if (this.$route.name == "Subscribe") {
      this.$router.push({ path: "/subscribe/creation", replace: true });
    }
  },
};
</script>

<style>
</style>