// const mada = [
//   "468540",
//   "468541",
//   "468542",
//   "468543",
//   "417633",
//   "446393",
//   "636120",
//   "968201",
//   "410621",
//   "409201",
//   "403024",
//   "458456",
//   "462220",
//   "968205",
//   "455708",
//   "484783",
//   "588848",
//   "455036",
//   "968203",
//   "486094",
//   "486095",
//   "486096",
//   "504300",
//   "440533",
//   "489318",
//   "489319",
//   "445564",
//   "968211",
//   "410685",
//   "406996",
//   "432328",
//   "428671",
//   "428672",
//   "428673",
//   "968206",
//   "446672",
//   "446404",
//   "440795",
//   "440647",
//   "421141",
//   "474491",
//   "588845",
//   "968208",
//   "457997",
//   "457865",
//   "543357",
//   "434107",
//   "407197",
//   "407395",
//   "412565",
//   "431361",
//   "604906",
//   "521076",
//   "588850",
//   "968202",
//   "529415",
//   "535825",
//   "543085",
//   "524130",
//   "554180",
//   "549760",
//   "968209",
//   "524514",
//   "529741",
//   "537767",
//   "535989",
//   "536023",
//   "513213",
//   "520058",
//   "558563",
//   "585265",
//   "588983",
//   "588982",
//   "589005",
//   "508160",
//   "531095",
//   "530906",
//   "532013",
//   "605141",
//   "968204",
//   "422817",
//   "422818",
//   "422819",
//   "428331",
//   "483010",
//   "483011",
//   "483012",
//   "589206",
//   "968207",
//   "419593",
//   "439954",
//   "530060",
//   "531196",
//   "420132",
//   "506968",
//   "406136",
//   "42689700",
// ];
const master = ["5"];
const visa = ["4"];

const getCardType = (cardNumber) => {
  let digits = cardNumber.slice(0, 6);
  const mada = Array.isArray(
    cardNumber.match(
      /(4(0(0861|1757|7(197|395)|9201)|1(0685|7633|9593)|2(281(7|8|9)|8(331|67(1|2|3)))|3(1361|2328|4107|9954)|4(0(533|647|795)|5564|6(393|404|672))|5(5(036|708)|7865|8456)|6(2220|854(0|1|2|3))|8(301(0|1|2)|4783|609(4|5|6)|931(7|8|9))|93428)|5(0(4300|8160)|13213|2(1076|4(130|514)|9(415|741))|3(0906|1095|2013|5(825|989)|6023|7767|9931)|4(3(085|357)|9760)|5(4180|7606|8848)|8(5265|8(8(4(5|6|7|8|9)|5(0|1))|98(2|3))|9(005|206)))|6(0(4906|5141)|36120)|9682(0(1|2|3|4|5|6|7|8|9)|1(0|1)))/
    )
  );
  if (mada) {
    return 3;
  }
  if (master.includes(digits[0])) {
    return 1;
  }
  if (visa.includes(digits[0])) {
    return 0;
  }
  return -1;
};

const cardType = (cardNumber) => {
  const visa = Array.isArray(cardNumber.match(/^4[0-9]{6,}$/));
  const mastercard = Array.isArray(
    cardNumber.match(
      /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/
    )
  );
  const mada = Array.isArray(
    cardNumber.match(
      /(4(0(0861|1757|7(197|395)|9201)|1(0685|7633|9593)|2(281(7|8|9)|8(331|67(1|2|3)))|3(1361|2328|4107|9954)|4(0(533|647|795)|5564|6(393|404|672))|5(5(036|708)|7865|8456)|6(2220|854(0|1|2|3))|8(301(0|1|2)|4783|609(4|5|6)|931(7|8|9))|93428)|5(0(4300|8160)|13213|2(1076|4(130|514)|9(415|741))|3(0906|1095|2013|5(825|989)|6023|7767|9931)|4(3(085|357)|9760)|5(4180|7606|8848)|8(5265|8(8(4(5|6|7|8|9)|5(0|1))|98(2|3))|9(005|206)))|6(0(4906|5141)|36120)|9682(0(1|2|3|4|5|6|7|8|9)|1(0|1)))\d{10}$/
    )
  );
  console.log("this is visa:>", visa);
  console.log("this is mastercard:>", mastercard);
  console.log("this is mada:>", mada);
};

export default getCardType;
