<template>
  <div class="not-found">
    <img src="@/assets/images/logo.png" alt="Dafater" />
    <h2>404</h2>
    <p>نأسف هذا المحتوى غير موجود</p>
    <router-link to="/">
      العودة للوحة التحكم
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 20rem;
  }
  h2 {
    font-size: 10rem;
  }
}
</style>