<template>
  <div class="package-spec__card">
    <!-- <div class="package-spec__select">
      <FormKit type="checkbox" :value="false" outer-class="$reset" />
    </div> -->
    <div class="w100">
      <div class="flex-between">
        <div class="package-spec__info">
          <h4 class="package-spec__name">{{ name }}</h4>
          <p>
            {{ additionalName }}/
            {{
              BillingFrequency == 0
                ? additionalPrice
                : additionalPrice * cycleType
            }}
            {{ currency }}
          </p>
        </div>
        <div class="package-spec__counter">
          <a class="btn btn-primary" v-if="loading">
            <i class="fas fa-plus"></i>
          </a>
          <template v-else>
            <a @click="inc" @mouseleave="changeQty" class="btn btn-primary">
              <i class="fas fa-plus"></i>
            </a>
          </template>
          <span>
            {{ counter }}
          </span>
          <a class="btn btn-primary" v-if="loading">
            <i class="fas fa-minus"></i>
          </a>
          <template v-else>
            <a
              @click="dec"
              @mouseleave="changeQty"
              class="btn btn-primary"
              :disabled="initialQuota == counter"
            >
              <i class="fas fa-minus"></i>
            </a>
          </template>
        </div>
      </div>
      <!-- <p class="mt-2" v-if="moreInfo">{{ moreInfo }}</p> -->
    </div>
  </div>
</template>

<script>
import { getCurrency } from "@/util/utils";

export default {
  props: {
    countNum: Number,
    initialQuota: Number,
    name: String,
    Id: Number,
    additionalPrice: Number,
    additionalName: String,
    loading: Boolean,
    currency: String,
    AddionalQuotaPacks: Number,
    cycleType: Number,
    BillingFrequency: Number,
    otherProps: Object,
    // moreInfo: String,
  },
  data() {
    return {
      counter: 0,
      billingFreqency: null,
    };
  },
  mounted() {
    this.counter = this.countNum;
  },
  methods: {
    inc() {
      this.counter++;
    },
    dec() {
      if (this.initialQuota == this.counter) return;
      if (
        this.otherProps.PreviousAddionalQuotaPacks &&
        this.otherProps.PreviousAddionalQuotaPacks == this.AddionalQuotaPacks
      )
        return;
      this.counter--;
    },
    changeQty() {
      if (this.counter != this.AddionalQuotaPacks + this.initialQuota) {
        this.$emit("change", this.counter - this.initialQuota);
      }
    },
    getCurrency() {
      return getCurrency();
    },
  },
};
</script>

<style lang="scss" scoped>
.package-spec {
  &__card {
    border-radius: 10px;
    background-color: #f6f6f6;
    display: flex;
    justify-content: space-between;
    padding: 4rem 3rem;
  }
  &__counter {
    display: flex;
    span,
    .btn {
      width: 4rem;
      height: 4rem;
      display: grid;
      align-content: center;
      padding: 0;
      text-align: center;
    }
    span {
      background-color: #fff;
      box-shadow: 0px 3px 6px #00000029;
      font-size: 2rem;
      font-weight: bold;
    }
    .btn {
      border-radius: 2px;
      font-size: 1.5rem;
    }
  }
}
</style>
