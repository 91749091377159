<template>
  <div class="subscribe">
    <form class="subscribe-form container">
      <div class="form-step form-step2">
        <loading-screen v-if="showLoadingScreen" :loadingItems="loadingItems" />

        <aside>
          <div class="invoice-summary__card">
            <h3>الفاتورة</h3>
            <div class="invoice-card__details">
              <div class="invoice-card__row">
                <div class="invoice-card__row-key">مديوناتك</div>
                <div class="invoice-card__row-val">
                  <div class="loader" v-if="amount == null"></div>
                  <template v-else>
                    {{ Math.abs(amount) }}
                  </template>
                </div>
              </div>
            </div>
            <div class="invoice-card__row total-price">
              <div class="key">المطلوب دفعه</div>
              <div class="val">
                <div class="loader" v-if="amount == null"></div>
                <template v-else>
                  <span class="price">{{ Math.abs(amount) }}</span>
                  {{ getCurrency() }}
                </template>
              </div>
            </div>
            <a @click="purchase(Math.abs(amount))" class="btn btn-primary"
              >قم بالدفع</a
            >
          </div>
        </aside>
        <!-- ////aside invoice card  -->

        <!-- payment methods  -->
        <main class="payment-methods">
          <h4>اختر طريقة الدفع</h4>
          <div class="payment-methods__form">
            <label class="payment-method">
              <input
                type="radio"
                class="radio-check"
                name="payment-method"
                checked
              />
              <img src="@/assets/images/visa-mastercard-mada-logo.png" alt="" />
            </label>
          </div>
          <div
            v-if="loader"
            style="
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <div class="loader" style="font-size: 1.4rem"></div>
          </div>
          <p v-if="cards.length">
            فضلا اختر احد بطاقاتك الإئتمانية المستخدمة مسبقا, او قم بإدخال
            معلومات بطاقة جديدة
          </p>
          <div class="saved-payment-methods">
            <label
              v-for="card in cards"
              :key="card.Id"
              class="saved-payment-method"
            >
              <input
                type="radio"
                class="radio-check"
                :checked="card.IsPreferred"
                :value="card.Id"
                v-model="paymentCardId"
              />
              <img
                :src="
                  require(`@/assets/images/card-type-${card.CreditCardType}.png`)
                "
                alt=""
              />
              <p>{{ card.Number }}</p>
            </label>
          </div>
          <a v-if="!showForm" @click="showForm = true" class="link c-primary">
            <i class="fas fa-plus"></i>
            إضافة بطاقة جديدة
          </a>
          <a v-else @click="showForm = false" class="link c-primary">
            <i class="fas fa-minus"></i>
            إلغاء إضافة بطاقة جديدة
          </a>
          <div
            class="add-payment-method modal-add-payment-card"
            v-if="showForm"
          >
            <payment-card-form @updateFormData="updateCards" />
          </div>
        </main>
      </div>
    </form>
  </div>

  <!-- ////payment methods  -->
  <m-modal
    class="modal-thank-you"
    v-show="showThankuModal"
    @close="redirectHome"
  >
    <template #header>
      <span style="display: none"></span>
    </template>
    <template #body>
      <img src="@/assets/images/thank-you.svg" alt="thank you" />
      <h3>تم إتمام عملية الدفع بنجاح</h3>
      <router-link to="/" class="btn btn-primary btn-wide"
        >ذهاب للوحة التحكم</router-link
      >
    </template>
  </m-modal>
  <m-modal
    class="modal-cvc"
    v-show="showCVCModal"
    @close="closeCVCModal"
    style="z-index: 99999"
  >
    <template #header> الرقم السري </template>
    <template #body>
      <payment-card-cvc-form @getCVC="getCVC" />
    </template>
  </m-modal>
</template>

<script>
import PaymentCardForm from "@/components/payment/PaymentCardForm.vue";
import PaymentCardCvcForm from "@/components/payment/PaymentCardCvcForm.vue";
import PaymentService from "@/services/PaymentService";
import SubscriptionService from "@/services/SubscriptionService";

import MModal from "@/components/common/MModal.vue";
import LoadingScreen from "@/components/common/LoadingScreen.vue";

import getCardType from "@/util/cardType";
import PaymentUtils from "@/components/payment/PaymentUtils";
import { getCurrency, getCurrencyEnum } from "@/util/utils";
import { useMsal } from "@/composition-api/useMsal";
export default {
  components: { PaymentCardForm, MModal, LoadingScreen, PaymentCardCvcForm },
  setup() {
    const { accounts } = useMsal();
    return { accounts };
  },
  data() {
    return {
      showThankuModal: false,
      showForm: false,
      showCVCModal: false,
      debtAmount: -2500,
      paymentCards: [],
      cards: [],
      paymentCardId: null,
      amount: null,
      currency: "SAR",
      card_security_code: null,
      showLoadingScreen: false,
      loadingItems: [],
      loader: false,
    };
  },
  watch: {
    amount(newValue) {
      if (this.amount >= 0) this.$router.push({ name: "Dashboard" });
    },
  },
  async mounted() {
    const query = this.$route.query;
    if (Object.keys(query).length) {
      if (query.service_command == "TOKENIZATION") {
        this.handleTokenizationQuery(query);
      } else {
        this.handlePurchaseResponse(query);
      }
    }
    this.getPaymentCards();
    this.currency = getCurrencyEnum();
    this.amount = await PaymentService.getWalletBalance();
    if (this.amount >= 0) this.$router.push({ name: "Dashboard" });
  },
  methods: {
    getCards(card) {
      // based on type
      this.cards = this.paymentCards.filter((c) => c.type == card);
      if (!this.cards.length) {
        this.showForm = true;
      } else {
        this.showForm = false;
      }
    },
    updateCards({ name, number, type }) {
      // call add payment cards api
      // after getting response do below
      this.paymentCards.push({
        id: this.paymentCards.length,
        name,
        number,
        type,
      });
    },
    redirectHome() {
      this.$router.push({ name: "Dashboard" });
    },
    async getPaymentCards() {
      this.loader = true;
      const cards = await PaymentService.getSavedCards();
      if (cards) {
        this.cards = cards;
        this.loader = false;
      }
      this.paymentCardId = this.cards.find((card) => card.IsPreferred).Id;
    },
    async purchase(amount) {
      this.loadingItems = [
        { status: "doing", text: "تهيئة عملية الدفع من خلال البنك" },
        { status: "hold", text: "تأكيد عملية الدفع من خلال دفاتر" },
      ];
      this.showLoadingScreen = true;
      const card = this.cards.find(({ Id }) => Id == this.paymentCardId);
      const data = {
        Amount: amount,
        CustomerEmail: this.accounts[0].idTokenClaims.emails[0],
        CustomerName: this.accounts[0].name,
        ReturnURL: `${
          process.env.VUE_APP_API_BASE_URL
        }PaymentPayfort/RedirectPurchase?portal=GoDafaterPortal&path=${this.$route.path.replace(
          "/",
          ""
        )}`,
        MerchantExtra: "paydept",
        MerchantExtra1: this.accounts[0].localAccountId,
        MerchantExtra2: process.env.VUE_APP_SERVICE_ID,
        MerchantReference: PaymentUtils.generateId(),
        TokenName: card.Alias,
        Currency: this.currency,
      };
      if (this.card_security_code)
        data["CardSecurityCode"] = this.card_security_code;
      console.log("this is data that should send it in req:>", data);
      const paymentResponse = await PaymentService.payToGateway(data);
      const {
        status,
        response_code,
        response_message,
        merchant_reference,
        amount: PFRTamount,
      } = paymentResponse;
      console.log("this is payment response:>", paymentResponse);
      if (status == 20) {
        this.loadingItems = [
          { status: "doing", text: "تهيئة عملية الدفع من خلال البنك" },
          { status: "hold", text: "تأكيد عملية الدفع من خلال دفاتر" },
        ];
        setTimeout(() => {
          window.location.href = paymentResponse["3ds_url"];
        }, 1500);
      } else if (status == 14) {
        // this.handlePurchaseResponse(paymentResponse);
        this.depositIntoWallet(PFRTamount / 100, merchant_reference);
      } else {
        if (
          response_code == "00001" &&
          response_message.includes("card_security_code")
        ) {
          this.showCVCModal = true;
          this.cancelCVC = false;
          // listen on close
        } else {
          if (response_code == "00016") {
            this.$toast.error("المعلومات الخاصة بالبطاقة غير صحيحة");
          } else if (response_code == "00044") {
            this.$toast.error(
              "انتهت صلاحية استخدام البطاقة من فضلك اعد تسجيل البطاقة او استخدم بطاقة اخرى"
            );
            // remove card
            this.removeExpiredCard(card.Id);
          } else if (response_code == "00023") {
            this.$toast.error(response_message);
          }
          console.log("something went wrong:", response_message);
          this.$toast.error("نأسف لوجود خطأ ما!");
          this.showLoadingScreen = false;
        }
      }
    },
    async handlePurchaseResponse(purchaseResponse) {
      console.log(purchaseResponse);
      this.loadingItems = [
        { status: "doing", text: "تهيئة عملية الدفع من خلال البنك" },
        { status: "hold", text: "تأكيد عملية الدفع من خلال دفاتر" },
      ];
      this.amount = await PaymentService.getWalletBalance();
      if (Object.keys(purchaseResponse).length > 0) {
        this.showLoadingScreen = true;
        if (purchaseResponse.status == 14) {
          // complete process
          const { amount, merchant_reference } = purchaseResponse;
          this.loadingItems = [
            { status: "done", text: "تهيئة عملية الدفع من خلال البنك" },
            { status: "doing", text: "تأكيد عملية الدفع من خلال دفاتر" },
          ];
          // if success should do the next

          setTimeout(async () => {
            this.loadingItems = [
              { status: "done", text: "تهيئة عملية الدفع من خلال البنك" },
              { status: "done", text: "تأكيد عملية الدفع من خلال دفاتر" },
            ];
            this.showLoadingScreen = false;
          }, 1000);
        } else {
          console.log(purchaseResponse);
          this.$toast.error(purchaseResponse.response_message);
          this.showLoadingScreen = false;
        }
      }
    },
    async depositIntoWallet(amount, transactionId) {
      const deposit = await PaymentService.depositBalance(
        amount,
        transactionId
      );
      console.log(deposit);
      if (deposit == 200) {
        this.loadingItems = [
          { status: "done", text: "تهيئة عملية الدفع من خلال البنك" },
          { status: "done", text: "تأكيد عملية الدفع من خلال دفاتر" },
        ];
        this.showLoadingScreen = false;
        this.amount = await PaymentService.getWalletBalance();
      } else {
        this.$toast.error("there is an issue with deposit with dafater");
        this.showLoadingScreen = false;
      }
    },
    async handleTokenizationQuery(tokenizeResponse) {
      const { status, response_message } = tokenizeResponse;
      console.log(tokenizeResponse);
      this.$router.replace({ query: null });
      this.showLoadingScreen = true;
      this.loadingItems = [
        { status: "doing", text: "ارسال بيانات البطاقة إلى بوابة الدفع" },
        { status: "hold", text: "التأكد من صحة بيانات البطاقة" },
        { status: "hold", text: "تأكيد بيانات البطاقة مع دفاتر" },
      ];
      if (status == "18") {
        this.loadingItems = [
          { status: "done", text: "ارسال بيانات البطاقة إلى بوابة الدفع" },
          { status: "doing", text: "تأكيد بيانات البطاقة مع دفاتر" },
        ];
        let {
          token_name: alias,
          card_number,
          expiry_date: expiryDate,
          card_holder_name: holderName,
          card_bin,
        } = tokenizeResponse;
        let cardData = {
          alias,
          holderName: holderName,
          number: card_number.replace(card_bin, "******"),
          expiryDate,
          CreditCardType: getCardType(card_bin),
        };
        const response = await PaymentService.postPaymentCard(cardData);
        if (response == 200) {
          this.getPaymentCards();
          setTimeout(() => {
            this.$toast.success("تم إضافة البطاقة بنجاح!");
            this.loadingItems = [
              {
                status: "done",
                text: "ارسال بيانات البطاقة إلى بوابة الدفع",
              },
              { status: "done", text: "تأكيد بيانات البطاقة مع دفاتر" },
            ];
          }, 1000);

          setTimeout(() => {
            this.showLoadingScreen = false;
          }, 1500);
        } else {
          this.showLoadingScreen = false;
          this.$toast.error("نأسف لوجود مشكلة");
          console.log("setpaymentcard error:>", response);
          this.showForm = true;
        }
      } else if (status == "00") {
        this.showLoadingScreen = false;
        this.$toast.error("نأسف لوجود مشكلة");
        console.log("tokenization error:>", response_message);
        this.showForm = true;
      }
    },
    getCVC(cvc) {
      this.card_security_code = cvc;
      // this.$refs["payBtn"].click();
      this.purchase(Math.abs(this.amount));
      this.showCVCModal = false;
    },
    async removeExpiredCard(cardId) {
      const status = await PaymentService.removePaymentCard(cardId);
      if (status == 200) {
        this.getPaymentCards();
      }
    },
    closeCVCModal() {
      this.cancelCVC = true;
      this.showCVCModal = false;
      this.showLoadingScreen = false;
    },
    getCurrency() {
      return getCurrency();
    },
  },
};
</script>

<style>
</style>