<template>
  <div class="addons">
    <div class="addons__header">
      <h2 class="addons__heading">سوق الاضافات و التطبيقات</h2>
      <form class="addons__search-filter">
        <div class="search__cats">
          <select name="search_cat" id="search_cat">
            <option v-for="{ id, name } in cats" :key="id" value="id">
              {{ name }}
            </option>
          </select>
        </div>
        <div class="search__input">
          <input type="search" placeholder="ابحث عن" />
          <i class="fas fa-search"></i>
        </div>
        <button class="search__btn btn btn-info">ابحث</button>
      </form>
    </div>
    <div class="addons__body">
      <div class="addons__carousel">
        <carousel :items-to-show="1">
          <slide v-for="slide in 3" :key="slide">
            <img src="@/assets/images/addons-banner.png" alt="" />
          </slide>

          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
      </div>
      <div
        class="addons__cat-container"
        v-for="addonsCat in addonsCats"
        :key="addonsCat.id"
      >
        <h2 class="cat-container__heading">
          {{ cats.find((c) => c.id == addonsCat.id).name }}
        </h2>
        <div class="grid grid-1x4">
          <div
            class="addons__card"
            v-for="addon in addonsCat.addons"
            :key="addon.id"
          >
            <router-link
              :to="`/addons/${addon.id}`"
              class="addons__card-header"
            >
              <div class="addons__card-img">
                <img
                  :src="require('@/assets/images/addons/' + addon.addon_image)"
                  :alt="addon.name"
                />
              </div>
              <div class="addons__card-info">
                <h3 class="addons__card-title">{{ addon.name }}</h3>
                <div class="addons__card-cat">
                  {{ cats.filter((c) => c.id == addonsCat.id)[0].name }}
                </div>
              </div>
            </router-link>
            <p class="addons__card-excerpt">{{ addon.excerpt }}</p>
            <div class="addons__card-footer">
              <a
                class="addons__card-action btn btn-info"
                @click="addAddon(addon)"
              >
                إضافة
              </a>
              <div class="addons__card-price">
                {{ addon.price }}
                <span> ريال سعودي</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MModal
    class="modal-add-addon"
    v-show="isModalVisible"
    @close="isModalVisible = false"
    hasFooter
  >
    <!-- Modal header -->
    <template #header> إضافة {{ wantAdd.name }} </template>

    <!-- Modal body -->
    <template #body> هل انت متأكد من إضافة {{ wantAdd.name }} اليك؟ </template>

    <template #footer>
      <div class="modal__action">
        <a class="btn btn-primary" @click="confirmAdd(wantAdd)"> موافق </a>
        <a class="btn btn-dark" @click="isModalVisible = false">
          إلغاء الطلب
        </a>
      </div>
    </template>
  </MModal>
</template>

<script>
import MModal from "@/components/common/MModal.vue";
import Services from "@/services/AddonService";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  components: { MModal, Carousel, Slide, Pagination, Navigation },
  data() {
    return {
      cats: [],
      addonsCats: [],
      wantAdd: "",
      isModalVisible: false,
    };
  },
  watch: {},
  mounted() {
    this.loadData();
  },
  methods: {
    addAddon(addon) {
      this.isModalVisible = true;
      this.wantAdd = addon;
    },
    confirmAdd(addon) {
      Services.applyAddon(addon);
      this.isModalVisible = false;
    },
    loadData() {
      this.cats = Services.getAllAddonsCat();
      Services.getAllAddons()
        .map((addon) => {
          const cat = this.cats.find((c) => c.id == addon.cat_id);
          if (!this.addonsCats.some((c) => c.id == cat.id)) {
            this.addonsCats.push({ ...cat, addons: [] });
          }
          return addon;
        })
        .map((addon) => {
          this.addonsCats.map((adC) => {
            if (adC.id == addon.cat_id) {
              adC.addons.push(addon);
            }
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
