import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { plugin, defaultConfig } from "@formkit/vue";
import { ar } from "@formkit/i18n";
import VueCreditCardValidation from "vue-credit-card-validation";
import VueEllipseProgress from "vue-ellipse-progress";
import VueHtmlToPaper from "/src/util/printHtml";
import LottieAnimation from "lottie-web-vue";
import Toaster from "@meforma/vue-toaster";
import VueWriter from "vue-writer";

///msal browser///
import { EventType } from "@azure/msal-browser";
import { msalInstance, b2cPolicies, loginRequest } from "@/authConfig";
import { msalPlugin } from "@/plugins/msalPlugin";
////accordion////
import VueCollapsiblePanel from "@dafcoe/vue-collapsible-panel";

import DashboardLayout from "./components/dashboard/DashboardLayout.vue";

import "./assets/styles/main.scss";
import "./assets/fonts/all.min.css";
import "skeleton-elements/css";

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_FAILURE &&
    event.error.errorMessage.includes("forgotten")
  ) {
    msalInstance.loginRedirect({
      authority: b2cPolicies.authorities.forgotPassword.authority,
    });
  }
  if (
    event.eventType === EventType.LOGIN_FAILURE &&
    event.error.errorMessage.includes("cancelled")
  ) {
    msalInstance.loginRedirect(loginRequest);
  }
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const app = createApp(App);

app.use(router);
app.use(VueCreditCardValidation);
app.use(VueEllipseProgress, "radial-progress");
app.use(msalPlugin, msalInstance); ///msal browser///
app.use(VueCollapsiblePanel); ////accordion/////
app.use(Toaster);
app.use(LottieAnimation);
app.use(VueHtmlToPaper);
app.use(VueWriter);

app.use(
  plugin,
  defaultConfig({
    // Define additional locales
    locales: { ar },
    // Define the active locale
    locale: "ar",
  })
);
app.directive("hoist", (el) => {
  if (el.tagName === "TEMPLATE") {
    el.replaceWith(el.content);
  } else {
    el.replaceWith(...el.children);
  }
});
window.onunload = function () {
  sessionStorage.removeItem("subscriptions");
};
app.component("dashboard-layout", DashboardLayout);
app.mount("#app");
