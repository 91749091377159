import axios from "axios";
import { msalInstance } from "@/authConfig";
import Service from "@/services/AccountService";
import Api from "@meforma/vue-toaster/src/api";
const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    common: {
      "Access-Control-Allow-Origin": "*",
    },
  },
});

const onDownloadProgress = (progressEvent) => {
  // let percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total);
  // console.log("handling download progress event here:>", progressEvent);
  // console.log(progressEvent.currentTarget);
  // const total = parseFloat(
  //   progressEvent.currentTarget.getResponseHeader("Content-Length")
  // );
  // console.log(progressEvent.srcElement.getAllResponseHeaders());
  // const current = progressEvent.currentTarget.response.length;
  // let percentCompleted = Math.floor((current / total) * 100);
  // console.log("completed: ", percentCompleted);
};
const onUploadProgress = (progressEvent) => {
  // console.log("handling uplaod progress event here:>", progressEvent);
};

HTTP.interceptors.request.use(
  (config) => {
    config.headers["NameIdentifier"] =
      msalInstance.getAllAccounts()[0]?.localAccountId;
    config.headers["ServiceId"] = process.env.VUE_APP_SERVICE_ID;
    config.headers["Access-Control-Allow-Origin"] = "*";
    return {
      ...config,
      onUploadProgress: config.onUploadProgress || onUploadProgress,
      onDownloadProgress: config.onDownloadProgress || onDownloadProgress,
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);
HTTP.interceptors.response.use(null, async (err) => {
  const error = JSON.parse(JSON.stringify(err));
  console.log("this is errors:>", error.status);
  //handling error => log it or what ever
  if (
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 500
  ) {
    console.log("this is errors:>", error.response.message);
    Api().error(error.response.data.Message);
    if (error.status == 401) {
      console.log("from unauthed");
      // signup();
    }
    return Promise.reject(error);
  } else {
    console.log("Loggin the error", error);
    // alert("An unexpected error occurred");
  }
});

export default {
  get: HTTP.get,
  post: HTTP.post,
  put: HTTP.put,
  delete: HTTP.delete,
  HTTP,
};
const signup = async () => {
  const { idTokenClaims, name, tenantId, localAccountId } =
    msalInstance.getAllAccounts()[0];
  const user = {
    Email: idTokenClaims.emails[0],
    NameIdentifier: localAccountId,
    UserName: name,
    CompanyName: idTokenClaims.extension_CompanyName,
    Mobile: "0" + idTokenClaims.extension_PhoneNumber,
    CountryCode: idTokenClaims.country.slice(0, 2).toUpperCase(),
    Tinumber: tenantId,
  };
  const profile = await Service.signUp(user);
  if (profile) {
    window.location.reload();
  }
};
