<template>
  <div class="grid grid4-1 container mt-6">
    <section
      class="
        subscription-details__section subscription-details__section-aside
        mt-6
      "
      v-if="data.Status == 2 || data.Status == 8"
    >
      <header class="section__header pA-2">
        <i class="fas fa-tasks ml-1"></i>
        <span>ادارة اشتراكك</span>
      </header>
      <div class="section__body">
        <ul class="list list-ver fs-16 mb-4">
          <!-- <li>
            <router-link to="/subscriptions" class="flex-between my-1 w100">
              <span>تجديد الاشتراك</span>
              <i class="fas fa-chevron-left fs-14"></i>
            </router-link>
          </li>
          <li>
            <router-link to="/subscribe" class="flex-between my-1 w100">
              <span>تغيير الإشتراك</span>
              <i class="fas fa-chevron-left fs-14"></i>
            </router-link>
          </li> -->
          <li>
            <router-link
              :to="`/subscribe/configuration/${$route.params.id}`"
              class="flex-between my-1 w100"
            >
              <span>خدمات إضافية</span>
              <i class="fas fa-chevron-left fs-14"></i>
            </router-link>
          </li>
          <li>
            <router-link
              :to="`/subscriptions/change-url/${$route.params.id}`"
              class="flex-between my-1 w100"
            >
              <span>تغيير رابط الاشتراك</span>
              <i class="fas fa-chevron-left fs-14"></i>
            </router-link>
          </li>

          <li>
            <router-link
              :to="`/subscriptions/manage-api/${$route.params.id}`"
              class="flex-between my-1 w100"
            >
              <span> ادارة الAPI وتهيئة نقاط البيع</span>
              <i class="fas fa-chevron-left fs-14"></i>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <div class="subscription-details" v-if="data && !loading">
      <!-- /subscription details section/ -->
      <div class="subscription-details__section-title mt-1">
        <h3 class="mb-0">تفاصيل الاشتراك</h3>
        <!-- <span class="fs-18 fw-b">
          تجديد تلقائي
          <a @click="toggleRenew(data)">
            <!/-- don't forget this below to edit --/>
            <toggle-swich-btn :value="data.auto_renew" />
          </a>
        </span> -->
      </div>
      <section class="subscription-details__section mb-2">
        <header class="section__header pA-2">
          <span class="fs-18">حالة الاشتراك</span>
          <subscription-status :status="data.Status" />
        </header>
        <div class="section__body grid grid3-1">
          <!-- here is the section body -->
          <div class="subscription__info py-2">
            <ul class="list list-ver">
              <!-- <li>
                <div class="key">اسم الشركة</div>
                <div class="val">
                  <skeleton-text v-if="!data.Subscriber" effect="wave"
                    >subscriber name</skeleton-text
                  >
                  <template v-else>{{ data.Subscriber.Name }}</template>
                </div>
              </li> -->
              <li>
                <div class="key">اسم الباقة / نوعها</div>
                <div class="val" v-if="data.ServicePlan">
                  {{ data.ServicePlan.Name.ArabicValue }}
                </div>
              </li>
              <li>
                <div class="key">رقم الاشتراك</div>
                <div class="val">{{ data.Number }}</div>
              </li>
              <li>
                <div class="key">تاريخ بداية الاشتراك</div>
                <div class="val">{{ formatDate(data.StartDate) }}</div>
              </li>
              <li>
                <div class="key">تاريخ التجديد</div>
                <div class="val">{{ formatDate(data.NextBillingDate) }}</div>
              </li>
              <li>
                <div class="key">مزايا الاشتراك</div>
                <div class="val">
                  <a @click="showAdvanModal = true" class="link c-primary"
                    >عرض المزايا</a
                  >
                </div>
              </li>
            </ul>
          </div>
          <div class="subscription__expire flex-center">
            <radial-progress
              :progress="(data.RemainingDays / 360) * 100"
              :angle="-90"
              :legend="150"
              color="#35D280"
              :size="200"
              :thickness="25"
              emptyThickness="25"
              line="butt"
              animation="reverse 700 400"
              :gap="0"
              dot="0 #916AAB"
            >
              <template #legend-caption>
                <div class="progress__left">متبقي</div>
                <div class="progress__days-left">
                  <span class="days-left__no">
                    {{ data.RemainingDays }}
                  </span>
                  <div class="days-left__d">يوم</div>
                </div>
              </template>
            </radial-progress>
          </div>
        </div>
        <div class="section__footer grid grid3-1 pA-2" v-if="data.Url">
          <div class="subscription__url flex-center-y">
            <label class="mx-2 nowrap fs-16"> رابط الاشتراك </label>
            <div class="modal-copy__input">
              <i class="fas fa-link"></i>
              <input type="text" :value="data.Url" disabled class="fs-16" />
              <a
                :href="data.Url"
                target="_blank"
                class="btn btn-info fs-14 fw-b"
                >دخول لاشتراكي</a
              >
            </div>
            <a :href="data.Url" target="_blank" class="btn btn-info fs-14 fw-b"
              >دخول لاشتراكي</a
            >
          </div>
          <div></div>
        </div>
      </section>
      <!-- ../subscription details section/ -->

      <!-- /subscription addons section/ -->
      <div
        class="subscription-details__section-title"
        v-if="addons && addons.length > 0"
      >
        الاضافات
      </div>
      <section
        class="subscription-details__section"
        v-if="addons && addons.length > 0"
      >
        <table class="subscription-details__table">
          <tbody>
            <tr v-for="addon in addons" :key="addon.Id">
              <td>{{ addon.Name.ArabicValue }}</td>
              <td
                v-if="addon.AppFeatureType !== 0 && addon.AppFeatureType !== 2"
              >
                عدد الوحدات الاضافية
              </td>
              <td v-else></td>
              <td>
                <span
                  v-if="
                    addon.AppFeatureType !== 0 && addon.AppFeatureType !== 2
                  "
                  >{{ addon.AddionalQuotaPacks }}</span
                >
                <span v-else>
                  <i class="fas fa-check-circle c-success"></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <!-- ../subscription addons section/ -->

      <!-- /subscription addons section/ -->
      <div
        class="subscription-details__section-title"
        v-if="compFeatures && compFeatures.length"
      >
        المميزات المجانية
      </div>
      <section
        class="subscription-details__section"
        v-if="compFeatures && compFeatures.length"
      >
        <table class="subscription-details__table">
          <tbody>
            <tr v-for="{ Count, Feature } in compFeatures" :key="Feature.Id">
              <td>{{ Feature.Name.ArabicValue }}</td>
              <td>عدد الوحدات المضافة</td>
              <td>
                {{ Count }}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <!-- ../subscription addons section/ -->

      <!-- /subscription renew & billing section/ -->
      <div class="subscription-details__section-title">
        التجديد ومعلومات الدفع
      </div>
      <section class="subscription-details__section">
        <table class="subscription-details__table" v-if="data.ServicePlan">
          <tbody>
            <tr>
              <td>
                <span>سعر التجديد القادم</span>
              </td>
              <td>
                <table>
                  <tr>
                    <td>
                      إشتراك في باقة
                      {{ data.ServicePlan.Name.ArabicValue }}
                      <template
                        v-if="data.ServicePlan.BillingCycleOptions?.length"
                      >
                        لمدة
                        {{
                          data.ServicePlan.BillingCycleOptions[0]
                            .BillingCycleType
                        }}شهر
                      </template>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td>ضريبة قيمة مضافة {{ data.ServicePlan.Tax }} %</td>
                  </tr> -->
                </table>
              </td>
              <td>
                <table>
                  <tr>
                    <td>
                      <span v-if="!data.ServicePlan.TrialPlan">
                        <skeleton-text
                          v-if="!data.ExpectedNextBillNetTotal"
                          effect="wave"
                          >1524</skeleton-text
                        >
                        <template v-else>{{
                          data.ExpectedNextBillNetTotal
                        }}</template>
                        {{ getCurrency() }}</span
                      >
                    </td>
                  </tr>
                  <!-- <tr>
                    <td>
                      {{ tax }}
                    </td>
                  </tr> -->
                </table>
              </td>
            </tr>
            <tr v-if="!data.ServicePlan.TrialPlan">
              <td>طريقة الدفع</td>
              <td>
                <i class="fal fa-credit-card ml-1"></i>
                <span>******</span>
              </td>
              <td>
                <router-link to="/payments" class="link">
                  تعديل معلومات الدفع
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <!-- ../subscription renew & billing section/ -->

      <template v-if="!data.ServicePlan.TrialPlan">
        <!-- /subscription previous invoices section/ -->
        <div class="subscription-details__section-title">
          الفواتير السابقة الخاصة بالإشتراك
        </div>
        <section class="subscription-details__section">
          <invoices-table :data="invoices" :currency="currency" />
        </section>
      </template>
      <!-- ../subscription previous invoices section/ -->
    </div>
    <subscription-details-loading v-else />
  </div>

  <!-- features modal -->
  <m-modal
    class="modal-advan"
    v-show="showAdvanModal"
    @close="showAdvanModal = false"
  >
    <!-- here should put features -->
    <template #header> مزايا الاشتراك</template>
    <template #body>
      <table>
        <thead>
          <tr>
            <th>رابط الاشتراك</th>
            <th>الوصف</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="feature in data.EntitledFeatures">
            <tr
              v-if="feature.AppFeatureType !== 1 && feature.InitialQuotaPack"
              :key="feature.id"
            >
              <td>{{ feature.Name.ArabicValue }}</td>
              <td
                v-if="
                  feature.AppFeatureType == 0 || feature.AppFeatureType == 2
                "
              >
                <i class="fas fa-check-circle c-success"></i>
              </td>
              <td v-else>
                <!-- <i class="fal fa-repeat c-info"></i> -->
                {{ feature.InitialQuotaPack }}
                <template v-if="feature.InitialQuotaPack < 3">وحدة</template>
                <template v-else>وحدات</template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </template>
  </m-modal>
</template>

<script>
import MModal from "@/components/common/MModal.vue";
import InvoicesTable from "@/components/invoices/InvoicesTable.vue";
// import ToggleSwichBtn from "@/components/common/ToggleSwichBtn.vue";
import SubscriptionStatus from "@/components/subscriptions/SubscriptionStatus.vue";
import { formatDate, subtractDates, getNowDate } from "@/util/date";
import Services from "@/services/SubscriptionService";
import SubscriptionDetailsLoading from "@/components/subscriptions/SubscriptionDetailsLoading.vue";
import { getCurrency, Currencies } from "@/util/utils";
import { SkeletonText } from "skeleton-elements/vue";
export default {
  components: {
    InvoicesTable,
    SubscriptionStatus,
    MModal,
    SubscriptionDetailsLoading,
    SkeletonText,
  },
  data() {
    return {
      data: false,
      invoices: [],
      showAdvanModal: false,
      tax: 0,
      currency: null,
      addons: null,
      compFeatures: null,
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
    const sessionSubscriptions = JSON.parse(
      sessionStorage.getItem("subscriptions")
    );
    if (sessionSubscriptions) {
      this.data = sessionSubscriptions.filter(
        (s) => s.Id == this.$route.params.id
      )[0];
    }
    this.getCurrentCompFeatures();
  },
  methods: {
    async getCurrentCompFeatures() {
      this.loading = true;
      try {
        const response = await Services.getCurrentCompFeatures(
          this.$route.params.id
        );
        if (response && response.status == 200) {
          this.loading = false;
          this.compFeatures = response.data.sort(
            (a, b) => a.DisplayOrder - b.DisplayOrder
          );
        } else {
          this.loading = false;
          this.$toast.error("هناك خطأ ما!");
        }
      } catch (error) {
        this.loading = false;
        this.$toast.error("هناك خطأ ما!");
      }
    },
    async fetchData() {
      this.data = await Services.getSubscription(this.$route.params.id);
      this.currency = Currencies[this.data.Currency].ar;
      this.addons = this.data.EntitledFeatures.filter(
        (item) => item.AddionalQuotaPacks && item.AppFeatureType != 1
      );
      const invoices = this.data.BillingRecords;
      this.tax =
        ((this.data.ServicePlan.MonthlyPrice *
          this.data.ServicePlan.BillingCycleOptions[0].BillingCycleType) /
          100) *
        this.data.ServicePlan.Tax;
      this.invoices = invoices.map((i) => {
        i.Description = i.Description.ArabicValue;
        i.Date = formatDate(i.Date);
        i.DateTo = formatDate(i.DateTo);
        return i;
      });
    },
    toggleRenew(subscription) {
      this.data.auto_renew = !subscription.auto_renew;
      Services.mutateSubscription(this.data);
    },
    formatDate(d) {
      return formatDate(d);
    },
    getRemainingDays(endDate) {
      console.log("this is subtracts", endDate);
      return subtractDates(getNowDate(), endDate);
    },
    getCurrency() {
      return this.data.Currency
        ? Currencies[this.data.Currency].ar
        : Currencies[0].ar;
      // return getCurrency();
    },
  },
};
// {
//     id: string;
//     type: string;
//     company_name: string;
//     subscription_url: string;
//     active: boolean;
//     auto_renew: boolean;
//     status: string;
//     subscription_no: string;
//     start_date: number;
//     renew_date: number;
// }
</script>

<style lang="scss">
</style>