<template>
  <!-- <LoaderComp v-if="loading" /> -->
  <loading-screen v-if="showLoadingScreen" :loadingItems="loadingItems" />
  <div class="form-step form-step2">
    <!-- aside invoice card  -->
    <aside>
      <template v-if="directPayment">
        <invoice-card
          :transaction="transaction"
          :features="features"
          :subscriptionType="subscriptionType"
          :loadingUpdate="loadingUpdate"
          @applyCoupon="applyDiscountCoupon"
          :enableDicountInput="subscriptionType == 'creation'"
          @Pay="postSubscribe(transaction.Id)"
        />
      </template>
      <template v-else>
        <invoice-card
          :transaction="transaction"
          :features="features"
          :loadingUpdate="loadingUpdate"
          :subscriptionType="subscriptionType"
          :disabled="!directPayment && !paymentCardId"
          @applyCoupon="applyDiscountCoupon"
          :enableDicountInput="subscriptionType == 'creation'"
          @Pay="subscribe(transaction.TransactionPaymentAmount)"
        />
      </template>
    </aside>
    <!-- ////aside invoice card  -->

    <!-- payment methods  -->
    <main class="payment-methods">
      <h4>اختر طريقة الدفع</h4>
      <div
        v-if="!supportedPaymentMethods"
        style="
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        "
      >
        <div class="loader" style="font-size: 1.4rem"></div>
      </div>
      <div class="payment-methods__form">
        <label
          class="payment-method"
          v-for="(supportedPaymentMethod, i) in supportedPaymentMethods"
          :key="i"
        >
          <input
            type="radio"
            class="radio-check"
            name="payment-method"
            :value="supportedPaymentMethod"
            @change="changePaymentMethod"
            :checked="supportedPaymentMethod == 1"
          />
          <h4 style="margin: 0 2rem" v-if="supportedPaymentMethod == 8">
            دفع مباشر.
          </h4>
          <img
            v-else
            src="@/assets/images/visa-mastercard-mada-logo.png"
            alt=""
          />
        </label>
      </div>
      <template v-if="!directPayment && supportedPaymentMethods">
        <p v-if="cards.length">
          فضلا اختر احد بطاقاتك الإئتمانية المستخدمة مسبقا, او قم بإدخال معلومات
          بطاقة جديدة
        </p>
        <div class="saved-payment-methods">
          <label
            v-for="card in cards"
            :key="card.Id"
            class="saved-payment-method"
          >
            <input
              type="radio"
              class="radio-check"
              :checked="card.IsPreferred"
              :value="card.Id"
              v-model="paymentCardId"
            />
            <img
              :src="
                require(`@/assets/images/card-type-${card.CreditCardType}.png`)
              "
              alt=""
            />
            <p>{{ card.Number }}</p>
          </label>
        </div>
        <a v-if="!showForm" @click="showForm = true" class="link c-primary">
          <i class="fas fa-plus"></i>
          إضافة بطاقة جديدة
        </a>
        <a v-else @click="showForm = false" class="link c-primary">
          <i class="fas fa-minus"></i>
          إلغاء إضافة بطاقة جديدة
        </a>
        <div class="add-payment-method modal-add-payment-card" v-if="showForm">
          <payment-card-form />
          <!-- <a @click="submitNewMethod = true" class="btn btn-info submit"> حفظ </a> -->
        </div>
      </template>
    </main>
    <!-- ////payment methods  -->
  </div>
  <m-modal
    class="modal-cvc"
    v-show="showCVCModal"
    @close="closeCVCModal"
    style="z-index: 99999"
  >
    <template #header> الرقم السري </template>
    <template #body>
      <payment-card-cvc-form @getCVC="getCVC" />
    </template>
  </m-modal>
</template>

<script>
import PaymentCardForm from "@/components/payment/PaymentCardForm.vue";
import PaymentCardCvcForm from "@/components/payment/PaymentCardCvcForm.vue";
import MModal from "../common/MModal.vue";
import PaymentUtils from "../payment/PaymentUtils";
import PaymentService from "@/services/PaymentService";
import SubscriptionService from "@/services/SubscriptionService";
// import LoaderComp from "../common/LoaderComp.vue";
import { useMsal } from "@/composition-api/useMsal";
import getCardType from "@/util/cardType";
import InvoiceCard from "./InvoiceCard.vue";

import LoadingScreen from "@/components/common/LoadingScreen.vue";
import { getCurrencyEnum } from "@/util/utils";

export default {
  components: {
    PaymentCardForm,
    PaymentCardCvcForm,
    MModal,
    // LoaderComp,
    InvoiceCard,
    LoadingScreen,
  },
  setup() {
    const { accounts } = useMsal();
    return { accounts };
  },
  props: {
    transactionId: Number,
    payfortResponse: Object,
    subscriptionType: String,
  },
  emits: ["showThanksModal"],
  data() {
    return {
      transaction: {},
      features: [],
      loadingUpdate: false,
      showCVCModal: false,
      showForm: false,
      submitNewMethod: false,
      paymentCards: [],
      cards: [],
      paymentCardId: null,
      amount: null,
      currency: 0,
      card_security_code: null,
      supportedPaymentMethods: null,
      directPayment: false,
      showLoadingScreen: false,
      loadingItems: [],
      cancelCVC: false,
    };
  },
  watch: {
    cancelCVC(newVal) {
      if (newVal) this.showLoadingScreen = false;
    },
  },
  async mounted() {
    // await this.postSubscribe(this.transactionId); this for make subscription without paying
    this.currency = getCurrencyEnum();
    this.handleUrlQueriesResponse();
    this.getPaymentMethodTypes();
    this.getPaymentCards();
    if (this.transactionId) {
      this.subscriptionTransactions();
    }
  },
  methods: {
    handleUrlQueriesResponse() {
      const PFRTresponse = this.payfortResponse;
      if (Object.keys(PFRTresponse).length) {
        if (PFRTresponse.service_command == "TOKENIZATION") {
          this.handleTokenizationQuery(PFRTresponse);
        } else {
          this.handlePurchaseResponse(PFRTresponse);
        }
      }
      console.log("this is purchase:>", PFRTresponse);
    },
    async subscriptionTransactions() {
      const params = this.$route.params;
      const sessionTransaction = JSON.parse(
        sessionStorage.getItem("transaction")
      );
      if (
        this.subscriptionType == params.type &&
        params.transactionId == sessionTransaction.Id
      ) {
        this.transaction = sessionTransaction;
        this.setFeatures(this.transaction.SubscriptionSteps);
      } else {
        this.transaction =
          await SubscriptionService.getSubscriptionTransactions(
            this.transactionId,
            this.subscriptionType
          );
        this.setFeatures(this.transaction.SubscriptionSteps);
      }
    },
    setFeatures(featuresArray) {
      const nonApplicationFeatures = this.getAppFeatureType(featuresArray, 2);
      const quotaApplicationFeatures = this.getAppFeatureType(featuresArray, 3);
      const QuotaNonAppFeatures = this.getAppFeatureType(featuresArray, 4);
      this.features = [
        ...quotaApplicationFeatures,
        ...nonApplicationFeatures,
        ...QuotaNonAppFeatures,
      ];
    },
    getAppFeatureType(arr, type) {
      return arr.filter((item) => item.AppFeatureType == type);
    },
    getCardType(cardNumber) {
      return getCardType(cardNumber);
    },
    getCVC(cvc) {
      this.card_security_code = cvc;
      this.subscribe(this.transaction.TransactionPaymentAmount);
      this.showCVCModal = false;
    },
    changePaymentMethod(e) {
      if (e.target.value == 1) {
        this.directPayment = false;
      } else {
        this.directPayment = true;
      }
    },
    async getPaymentMethodTypes() {
      const servicePlane = await SubscriptionService.getServicePlan();
      this.supportedPaymentMethods = servicePlane.SupportedPaymentMethods;
    },
    async getPaymentCards() {
      this.cards = await PaymentService.getSavedCards();
      this.paymentCardId = this.cards.find((card) => card.IsPreferred)?.Id;
      if (!this.cards.length) {
        this.showForm = true;
      }
    },
    async applyDiscountCoupon(discountCoupon) {
      this.loadingUpdate = true;
      try {
        const { transaction } = await SubscriptionService.applyDiscountCoupon(
          this.transaction.Id,
          discountCoupon
        );
        this.transaction = transaction;
        // sessionStorage.removeItem("transaction");
        sessionStorage.setItem("transaction", JSON.stringify(this.transaction));
      } catch (error) {
        console.log(error);
        if (error.status == 404)
          this.$toast.error("نأسف هناك خطأ ما، الرمز الذي ادخلته غير موجود!");
        // else this.$toast.error(error);
      }
      this.loadingUpdate = false;
    },
    async postSubscribe(transactionId) {
      if (this.loadingItems.length > 0) {
        this.loadingItems.map((item) => {
          if (item.text.includes("تهيئة الأشتراك")) {
            item.status = "doing";
          }
        });
      } else {
        this.loadingItems.push({ status: "doing", text: "تهيئة الأشتراك" });
      }
      this.showLoadingScreen = true;
      const httpSubscriptionMethod =
        this.subscriptionType == "creation"
          ? SubscriptionService.postSubscription
          : SubscriptionService.updateSubscription;
      const response = await httpSubscriptionMethod(transactionId);
      console.log("this is response:>", response);
      // this.loading = false;
      if (response.status == 200) {
        this.loadingItems.map((item) => {
          if (item.text.includes("تهيئة الأشتراك")) {
            item.status = "done";
            // remove subscriptions from session
            sessionStorage.removeItem("subscriptions");
            sessionStorage.removeItem("invoices");
          }
        });
        setTimeout(() => {
          this.showLoadingScreen = false;
          this.$emit("showThanksModal", {
            show: true,
            subscription: response.data,
          });
        }, 1500);
      } else {
        this.showLoadingScreen = false;
        this.$toast.error(response.message);
      }
    },
    async subscribe() {
      this.loadingItems = [
        { status: "doing", text: "تهيئة عملية الدفع من خلال البنك" },
        { status: "hold", text: "تأكيد عملية الدفع من خلال دفاتر" },
        { status: "hold", text: "تهيئة الأشتراك" },
      ];
      this.showLoadingScreen = true;
      if (this.transaction.TransactionPaymentAmount == 0) {
        // if wallet has money to pay
        return this.postSubscribe(this.transaction.Id);
      }
      const card = this.cards.find(({ Id }) => Id == this.paymentCardId);
      const data = {
        Amount: this.transaction.TransactionPaymentAmount,
        CustomerEmail: this.accounts[0].idTokenClaims.emails[0],
        CustomerName: this.accounts[0].name,
        ReturnURL: `${
          process.env.VUE_APP_API_BASE_URL
        }PaymentPayfort/RedirectPurchase?portal=GoDafaterPortal&path=${this.$route.path.replace(
          "/",
          ""
        )}`,
        MerchantExtra: this.subscriptionType,
        MerchantExtra1: this.accounts[0].localAccountId,
        MerchantExtra2: process.env.VUE_APP_SERVICE_ID,
        MerchantReference: this.transaction.Id,
        TokenName: card.Alias,
        Currency: this.currency,
      };
      if (this.card_security_code)
        data["CardSecurityCode"] = this.card_security_code;
      const paymentResponse = await PaymentService.payToGateway(data);
      const { status, response_code, response_message } = paymentResponse;
      console.log("this is payment response:>", paymentResponse);
      if (status == 20) {
        this.loadingItems = [
          { status: "doing", text: "تهيئة عملية الدفع من خلال البنك" },
          { status: "hold", text: "تأكيد عملية الدفع من خلال دفاتر" },
          { status: "hold", text: "تهيئة الأشتراك" },
        ];
        setTimeout(() => {
          window.location.href = paymentResponse["3ds_url"];
        }, 1500);
      } else if (status == 14) {
        this.depositIntoWallet(
          paymentResponse.amount / 100,
          paymentResponse.merchant_reference
        );
      } else {
        if (
          response_code == "00001" &&
          response_message.includes("card_security_code")
        ) {
          this.showCVCModal = true;
          this.cancelCVC = false;
          // listen on close
        } else {
          if (response_code == "00016") {
            this.$toast.error("المعلومات الخاصة بالبطاقة غير صحيحة");
          } else if (response_code == "00044") {
            this.$toast.error(
              "انتهت صلاحية استخدام البطاقة من فضلك اعد تسجيل البطاقة او استخدم بطاقة اخرى"
            );
            // remove card
            this.removeExpiredCard(card.Id);
          } else if (response_code == "00023") {
            this.$toast.error(response_message);
          }
          this.$toast.error(response_message);
          console.log("something went wrong:", response_message);
          this.showLoadingScreen = false;
        }
      }
    },
    async removeExpiredCard(cardId) {
      const status = await PaymentService.removePaymentCard(
        cardId,
        this.subscriptionType
      );
      if (status == 200) {
        this.getPaymentCards();
      }
    },
    async depositIntoWallet(amount, transactionId) {
      const deposit = await PaymentService.depositBalance(
        amount,
        transactionId
      );
      console.log(deposit);
      if (deposit == 200) {
        this.loadingItems = [
          { status: "done", text: "تهيئة عملية الدفع من خلال البنك" },
          { status: "done", text: "تأكيد عملية الدفع من خلال دفاتر" },
          { status: "doing", text: "تهيئة الأشتراك" },
        ];
        await this.postSubscribe(transactionId);
      } else {
        this.$toast.error("there is an issue with deposit with dafater");
        this.showLoadingScreen = false;
      }
    },
    async handlePurchaseResponse(purchaseResponse) {
      console.log(purchaseResponse);
      this.loadingItems = [
        { status: "doing", text: "تهيئة عملية الدفع من خلال البنك" },
        { status: "hold", text: "تأكيد عملية الدفع من خلال دفاتر" },
        { status: "hold", text: "تهيئة الأشتراك" },
      ];
      if (Object.keys(purchaseResponse).length > 0) {
        this.showLoadingScreen = true;
        if (purchaseResponse.merchant_reference != this.transactionId) {
          this.showLoadingScreen = false;
          this.$toast.error("عملية الدفع ليست لنفس الطلب");
          console.log(
            "this is refrence:>",
            purchaseResponse.merchant_reference
          );
          console.log("this is transId:>", this.transactionId);
          return;
        }
        if (purchaseResponse.status == 14) {
          // complete process
          const { merchant_reference } = purchaseResponse;
          this.loadingItems = [
            { status: "done", text: "تهيئة عملية الدفع من خلال البنك" },
            { status: "doing", text: "تأكيد عملية الدفع من خلال دفاتر" },
            { status: "hold", text: "تهيئة الأشتراك" },
          ];

          const { Id, deposit_status } =
            await SubscriptionService.getSubscriptionTransactions(
              merchant_reference,
              this.subscriptionType
            );
          console.log("this is deposit_status:> ", deposit_status);
          // if deposit_status is success
          this.loadingItems = [
            { status: "done", text: "تهيئة عملية الدفع من خلال البنك" },
            { status: "done", text: "تأكيد عملية الدفع من خلال دفاتر" },
            { status: "hold", text: "تهيئة الأشتراك" },
          ];
          await this.postSubscribe(Id);
        } else {
          console.log(purchaseResponse);
          this.$toast.error(purchaseResponse.response_message);
          this.showLoadingScreen = false;
        }
      }
    },
    async handleTokenizationQuery(tokenizeResponse) {
      const { status, response_message } = tokenizeResponse;
      console.log(tokenizeResponse);
      this.$router.replace({ query: null });
      this.showLoadingScreen = true;
      this.loadingItems = [
        { status: "doing", text: "ارسال بيانات البطاقة إلى بوابة الدفع" },
        { status: "hold", text: "التأكد من صحة بيانات البطاقة" },
        { status: "hold", text: "تأكيد بيانات البطاقة مع دفاتر" },
      ];
      if (status == "18") {
        this.loadingItems = [
          { status: "done", text: "ارسال بيانات البطاقة إلى بوابة الدفع" },
          { status: "doing", text: "تأكيد بيانات البطاقة مع دفاتر" },
        ];
        let {
          token_name: alias,
          card_number,
          expiry_date: expiryDate,
          card_holder_name: holderName,
          card_bin,
        } = tokenizeResponse;
        let cardData = {
          alias,
          holderName: holderName,
          number: card_number.replace(card_bin, "******"),
          expiryDate,
          CreditCardType: getCardType(card_bin),
        };
        this.postPaymentCard(cardData);
      } else if (status == "00") {
        this.showLoadingScreen = false;
        this.$toast.error("نأسف لوجود مشكلة");
        console.log("tokenization error:>", response_message);
        this.showForm = true;
      }
    },
    async postPaymentCard(cardData) {
      const response = await PaymentService.postPaymentCard(cardData);
      if (response == 200) {
        this.getPaymentCards();
        setTimeout(() => {
          this.$toast.success("تم إضافة البطاقة بنجاح!");
          this.loadingItems = [
            {
              status: "done",
              text: "ارسال بيانات البطاقة إلى بوابة الدفع",
            },
            { status: "done", text: "تأكيد بيانات البطاقة مع دفاتر" },
          ];
        }, 1000);

        setTimeout(() => {
          this.showLoadingScreen = false;
        }, 1500);
      } else {
        this.showLoadingScreen = false;
        this.$toast.error("نأسف لوجود مشكلة");
        console.log("setpaymentcard error:>", response);
        this.showForm = true;
      }
    },
    closeCVCModal() {
      this.cancelCVC = true;
      this.showCVCModal = false;
    },
  },
};
</script>

<style lang="scss">
.modal-thank-you {
  .modal {
    border-radius: 10px;
  }
  .modal__header {
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
  }
  .modal__body {
    text-align: center;
    // margin: 3rem auto 6rem;
    img {
      width: 20rem;
      height: 20rem;
    }
    h3 {
      font-size: 2.5rem;
      margin: 2rem 0 3rem;
    }
    .btn {
      font-size: 1.8rem;
      width: 60%;
    }
  }
}
</style>