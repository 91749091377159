<template>
  <div class="subscriptions container">
    <div class="add-subscription">
      <router-link to="/subscribe/creation" class="btn btn-info btn-icon-r">
        <i class="fas fa-plus"></i>
        إشتراك جديد
      </router-link>
    </div>
    <div class="section">
      <subscriptions-tab-section :subscriptions="subscriptions" />
    </div>
  </div>
</template>

<script>
import SubscriptionsTabSection from "@/components/subscriptions/SubscriptionsTabSection.vue";
import Service from "@/services/SubscriptionService";
export default {
  components: {
    SubscriptionsTabSection,
  },
  data() {
    return {
      subscriptions: [],
      post: {
        // replace this with your one
        Id: "1", // Id
        type: "باقة أساسية/شهرية", // ServicePlan.Name[ArabicValue, EnglishValue]
        company_name: "شركة الاحلام الكبيرة", // Subscriber
        subscription_url: "https://engineering-duaa.dafater.biz/app.html", // Url
        active: true,
        auto_renew: true,
        status: "active", // Status => as code convert it to status
        subscription_no: "223224689", //Number
        start_date: Date.now(), //StartBillingDate
        renew_date: Date.now(), //NextBillingDate
        remaining_days: Date.now(), //RemainingDays
      },
    };
  },
  mounted() {
    this.getSubscriptions();
  },
  methods: {
    async getSubscriptions() {
      this.subscriptions = false;
      const res = await Service.getAllSubscriptions();
      this.subscriptions = res.map(
        ({
          Id,
          ServicePlan,
          Subscriber,
          Url,
          Status, // -> 7, 5
          Number,
          StartBillingDate,
          NextBillingDate,
          RemainingDays,
        }) => {
          return {
            Id,
            ServicePlan,
            Subscriber,
            Url,
            Status,
            Number,
            StartBillingDate,
            NextBillingDate,
            RemainingDays,
          };
        }
      );
    },
  },
};
</script>

<style>
</style>