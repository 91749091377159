<template>
  <div class="subscription__card sub-card">
    <div class="sub-card__header">
      <div class="flex-center-y">
        <h4>
          {{ name.ArabicValue }}
          <!-- billing cycle -->
        </h4>

        <div class="sub-card__status">
          <subscription-status :status="data.Status" />
        </div>
      </div>
      <!-- <span class="fs-18 fw-b">
        تجديد تلقائي
        <a @click="toggleRenew(data)">
          <toggle-swich-btn :value="data.auto_renew" primary />
        </a>
      </span> -->
    </div>
    <div class="sub-card__body grid grid3-1">
      <div class="sub-card__info">
        <router-link :to="'/subscriptions/' + data.Id">
          <h3 class="c-primary pointer">
            {{ name.ArabicValue }}
          </h3>
        </router-link>
        <div
          class="sub-card__link"
          :style="!data.Url ? 'visibility: hidden' : ''"
        >
          <copy-url label="رابط الباقة" :url="data.Url" />
        </div>
        <div class="sub-card__footer-info">
          <div class="sub-card__kval">
            <div class="sub-card__key">رقم الاشتراك</div>
            <div class="sub-card__val">{{ data.Number }}</div>
          </div>
          <div class="sub-card__kval">
            <div class="sub-card__key">تاريخ بداية الاشتراك</div>
            <div class="sub-card__val">
              {{ formatDate(data.StartBillingDate) }}
            </div>
          </div>
          <div class="sub-card__kval">
            <div class="sub-card__key">تاريخ التجديد</div>
            <div class="sub-card__val">
              {{ formatDate(data.NextBillingDate) }}
            </div>
          </div>
        </div>
      </div>
      <div class="sub-card__action">
        <div class="sub-card__expiration" v-if="data.Status == 2">
          <radial-progress
            v-if="hasTimer"
            :progress="(data.RemainingDays / 360) * 100"
            :angle="-90"
            color="#916AAB"
            :size="150"
            :thickness="15"
            emptyThickness="15"
            lineMode="normal 10"
            animation="reverse 700 400"
            :gap="0"
            dot="15 #916AAB"
          >
            <template #legend-caption>
              <div class="progress__left">متبقي</div>
              <div class="progress__days-left">
                <span class="days-left__no">
                  {{ data.RemainingDays }}
                </span>
                <div class="days-left__d">يوم</div>
              </div>
            </template>
          </radial-progress>
          <div v-else style="height: 150px"></div>
        </div>
        <!-- <button class="btn btn-info">تجديد الباقة</button> -->
      </div>
    </div>
  </div>
</template>

<script>
// import ToggleSwichBtn from "@/components/common/ToggleSwichBtn.vue";
import CopyUrl from "@/components/common/CopyUrl.vue";
import SubscriptionStatus from "@/components/subscriptions/SubscriptionStatus.vue";
import Services from "@/services/SubscriptionService";
import { formatDate } from "@/util/date";
export default {
  components: { CopyUrl, SubscriptionStatus },
  props: {
    subscription: Object,
    hasTimer: Boolean,
  },
  data() {
    return {
      data: {},
      name: "",
    };
  },
  mounted() {
    this.data = this.subscription;
    console.log("this is data:>", this.data);
    this.name = this.data.ServicePlan.Name;
  },
  methods: {
    toggleRenew(subscription) {
      this.data.auto_renew = !subscription.auto_renew;
      Services.mutateSubscription(this.data);
    },
    formatDate(date) {
      return formatDate(date);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>