<template>
  <div class="invoice-summary__card">
    <h3>الفاتورة</h3>
    <div class="invoice-card__details">
      <div
        class="invoice-card__row"
        v-if="subscriptionType !== 'configuration'"
      >
        <div class="invoice-card__row-key">سعر الباقة</div>
        <div
          class="invoice-card__row-val"
          v-if="Object.keys(transaction).length > 0"
        >
          {{ transaction.ServicePlanTotalPrice }}
        </div>
        <div class="invoice-card__row-val" v-else>
          <skeleton-text effect="wave">24000</skeleton-text>
        </div>
      </div>
      <template v-for="feature in features" :key="feature.Id">
        <div
          class="invoice-card__row"
          v-if="feature.AddionalQuotaPackTotalPrice"
        >
          <div class="invoice-card__row-key">
            {{ feature.StepName.ArabicValue }}
            <template v-if="feature.AppFeatureType == 3">
              <span style="display: inline-flex">
                <span>x</span>
                {{ feature.AddionalQuotaPacks }}
              </span>
            </template>
          </div>
          <div class="invoice-card__row-val">
            {{ feature.AddionalQuotaPackTotalPrice }}
          </div>
        </div>
      </template>
      <div
        class="invoice-card__row"
        v-if="subscriptionType !== 'configuration'"
      >
        <div class="invoice-card__row-key">سعر الاشتراك</div>
        <div
          class="invoice-card__row-val"
          v-if="Object.keys(transaction).length > 0"
        >
          {{ transaction.NetTotalBeforeDiscount }}
        </div>
        <div class="invoice-card__row-val" v-else>
          <skeleton-text effect="wave">24002400</skeleton-text>
        </div>
      </div>
      <div
        class="invoice-card__row"
        v-if="transaction.NetTotal !== transaction.NetTotalBeforeDiscount"
      >
        <div class="invoice-card__row-key">قيمة الخصم</div>
        <div class="invoice-card__row-val">
          <template v-if="Object.keys(transaction).length > 0">
            {{ transaction.DiscountAmount }}
          </template>
          <template v-else>
            <skeleton-text effect="wave">3400</skeleton-text>
          </template>
        </div>
      </div>
      <div class="invoice-card__row">
        <div
          class="invoice-card__row-key"
          v-if="Object.keys(transaction).length > 0"
        >
          ضريبة القيمه المضافة {{ transaction.ServicePlan.Tax }}%
        </div>
        <div class="invoice-card__row-val">
          <template v-if="Object.keys(transaction).length > 0">
            {{ transaction.TaxAmount }}
          </template>
        </div>
      </div>
    </div>
    <!-- <div
      class="invoice-card__row"
      v-if="transaction.NetTotal !== transaction.NetTotalBeforeDiscount"
    >
      <div class="invoice-card__row-key">السعر قبل الخصم</div>
      <div class="invoice-card__row-val">
        <template v-if="Object.keys(transaction).length > 0">
          {{ transaction.NetTotalBeforeDiscount }}
        </template>
        <template v-else>
          <skeleton-text effect="wave">3400</skeleton-text>
        </template>
      </div>
    </div> -->
    <div class="invoice-card__row">
      <div class="invoice-card__row-key">الإجمالي</div>
      <div class="invoice-card__row-val">
        <template v-if="Object.keys(transaction).length > 0">
          {{ transaction.GrandTotal }}
        </template>
        <template v-else>
          <skeleton-text effect="wave">350000</skeleton-text>
        </template>
      </div>
    </div>

    <div class="invoice-card__row">
      <div class="invoice-card__row-key">الرصيد الحالي</div>
      <div class="invoice-card__row-val">
        <template v-if="Object.keys(transaction).length > 0">
          {{
            (transaction.GrandTotal - transaction.TransactionPaymentAmount) %
              1 !=
            0
              ? (
                  transaction.GrandTotal - transaction.TransactionPaymentAmount
                ).toFixed(2)
              : transaction.GrandTotal - transaction.TransactionPaymentAmount
          }}
        </template>
        <template v-else>
          <skeleton-text effect="wave">3400</skeleton-text>
        </template>
      </div>
    </div>

    <template v-if="transaction.ServicePlan">
      <div
        class="invoice-card__row"
        v-if="transaction.ServicePlan.HasDiscountCoupon && enableDicountInput"
      >
        <div class="key" style="white-space: nowrap; margin-left: 20px">
          قسيمة خصم!
        </div>
        <div class="val flex">
          <input type="text" v-model="discountCode" />
          <button
            class="btn btn-primary"
            type="button"
            :disabled="!discountCode.length"
            style="width: auto; padding: 1rem 1.5rem; font-size: 1.5rem"
            @click="$emit('applyCoupon', discountCode)"
          >
            أضف
          </button>
        </div>
      </div>
    </template>
    <!-- {{GrandTotal}} -->
    <div class="invoice-card__row total-price">
      <div class="key">المطلوب سداده</div>
      <div class="val">
        <div
          class="loader"
          style="top: 20px; left: 30px"
          v-if="loadingUpdate && Object.keys(transaction).length > 0"
        ></div>
        <span class="price">
          <template v-if="Object.keys(transaction).length > 0">
            {{ transaction.TransactionPaymentAmount }}
          </template>
          <template v-else>
            <skeleton-text effect="wave">240000</skeleton-text>
          </template>
        </span>
        <!-- TransactionPaymentAmount -->
        {{ currency }}
      </div>
    </div>
    <!-- {{ transaction.ServicePlan }} -->
    <template v-if="Object.keys(transaction).length">
      <a
        @click="$emit('pay')"
        class="btn btn-primary"
        v-if="
          transaction.ServicePlan.HasPromotionCode &&
          transaction.ServicePlan.TrialPlan
        "
      >
        اشترك</a
      >
      <a v-else @click="pay()" class="btn btn-primary"> سدد </a>
    </template>
  </div>
</template>

<script>
import { SkeletonText } from "skeleton-elements/vue";
import { getCurrency, Currencies } from "@/util/utils";

export default {
  components: { SkeletonText },
  props: {
    transaction: Object,
    features: Array,
    loadingUpdate: Boolean,
    disabled: Boolean,
    subscriptionType: String,
    enableDicountInput: Boolean,
  },
  data() {
    return {
      currency: null,
      displayedTransaction: null,
      discountCode: "",
    };
  },
  mounted() {},
  watch: {
    transaction(newVal) {
      this.displayedTransaction = newVal;
      if (!this.displayedTransaction.ServicePlan) {
        this.displayedTransaction.ServicePlan =
          this.displayedTransaction.Subscription.ServicePlan;
      }
      console.log("from watched");
      this.getCurrency();
    },
    loadingUpdate(newValue) {
      console.log("this is loadingupdate changed:>", newValue);
      console.log("this is disabled from watch:>", this.disabled);
    },
    disabled(newValue) {
      console.log("this is disabled from changed:>", newValue);
    },
  },
  methods: {
    pay() {
      console.log("this is displayed transaction:>", this.displayedTransaction);
      if (this.disabled || this.displayedTransaction == null) return;
      this.$emit("pay");
    },
    getCurrency() {
      const { ServicePlan } = this.displayedTransaction;
      if (!ServicePlan) {
        this.displayedTransaction.ServicePlan =
          this.displayedTransaction.Subscription.ServicePlan;
      }
      this.currency = Currencies[ServicePlan.Currency].ar;
      // return getCurrency();
    },
  },
};
</script>

<style>
</style>