<template>
  <div class="carousel grid grid-1x4">
    <div v-for="img in images" :key="img" class="carousel-img">
      <img :src="require('@/assets/images/addons/' + img)" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AddonScreensCarousel",
  data() {
    return {};
  },
  props: {
    images: Array,
  },
};
</script>

<style lang="scss">
.carousel {
  &-img {
    img {
      width: 100%;
    }
  }
  &__next,
  &__prev {
    display: none;
  }
  &__pagination {
    &-button {
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      background-color: #bcbcbc;
      &--active {
        background-color: #916aab;
      }
    }
  }
}
</style>