<template>
  <div class="status-label active" v-if="active">
    <i class="fas fa-circle"></i>
    <span>مفعل</span>
  </div>
  <div :class="`status-label ${statusString.sign}`">
    <i class="fas fa-circle"></i>
    <span>{{ statusString.ar }}</span>
    <!-- don't forget to check this  -->
  </div>
</template>

<script>
import Service from "@/services/SubscriptionService";

export default {
  props: {
    status: Number,
  },
  data() {
    return {
      active: false,
      statusString: "",
    };
  },
  watch: {
    status() {
      this.getStatus();
    },
  },
  mounted() {
    this.getStatus();
  },
  methods: {
    getStatus() {
      this.statusString = Service.getStatus(this.status);

      console.log(this.statusString);
      if (this.statusString == "Active") {
        this.active = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.status-label {
  border-radius: 40px;
  border: 1px solid rgba(#acacac, 0.5);
  padding: 0.5rem 1.5rem;
  margin: 0 3rem;
  font-size: 1.6rem;
  font-weight: bold;
  span {
    margin-right: 0.5rem;
  }
  i {
    font-size: 1.4rem;
    color: #09c202;
  }
  &.active {
    i {
      color: #09c202;
    }
  }
  &.warning {
    i {
      color: #ffcc00;
    }
  }
  &.danger {
    i {
      color: #f32013;
    }
  }
}
</style>
