import http from "./httpService";

const postSubscription = async (transactionId) => {
  const response = await http.post(
    `/subscriptions?inputId=${transactionId}&configRequired=true`
  );
  return response;
};
// const updateSubscription = async (servicePlanId) => {
//   const { status } = await http.post(
//     `/subscriptions?inputId=${servicePlanId}&configRequired=true`
//   );
//   return status;
// };
const getServicePlan = async () => {
  const { data: service } = await http.get("/service?ServiceId=1");
  return service;
};
// getServicePlan();
const getServicePlans = async () => {
  const { data } = await http.get(
    "service-plans?includeTrialPlans=true&includePromoPlans=true"
  );
  return data;
};
// getServicePlans(); to get the promotioncode plans
const getServicePlanDetails = async (Id) => {
  const { data } = await http.get(`service-plans/${Id}`);
  return data;
};

const getAllSubscriptions = async () => {
  const sessionSubscriptions = JSON.parse(
    sessionStorage.getItem("subscriptions")
  );
  if (sessionSubscriptions) return sessionSubscriptions;
  const { data: subscriptions } = await http.get("/subscriptions");
  sessionStorage.setItem("subscriptions", JSON.stringify(subscriptions));
  return subscriptions;
};

const getSubscription = async (id) => {
  const { data: subscriptions } = await http.get(`/subscriptions/${id}`);
  return subscriptions;
};

const mutateSubscription = async (subscription) => {
  const { data: updatedSubscription } = await http.put(
    `/subscriptions/${subscription.id}`,
    subscription
  );
  return updatedSubscription;
};

const getStatus = (code) => {
  const enums = {
    0: { ar: "تحت التنفيذ", en: "Processing", sign: "warning" },
    1: { ar: "تحت التجهيز", en: "Provisioning", sign: "success" },
    2: { ar: "مفعل", en: "Active", sign: "success" },
    3: { ar: "تحت التعليق", en: "Suspending", sign: "danger" },
    4: { ar: "معلّق", en: "Suspended", sign: "danger" },
    5: { ar: "تحت التفعيل", en: "Activating", sign: "danger" },
    6: { ar: "تحت الإلغاء", en: "Deprovisioning", sign: "warning" },
    7: { ar: "ملغى", en: "Deprovisioned", sign: "danger" },
    8: { ar: "غير مدفوع", en: "Unpaid", sign: "danger" },
    9: { ar: "غير مدفوع - رفض", en: "UnpaidDeclined", sign: "danger" },
    10: { ar: "غير مدفوع - فشل", en: "UnpaidFailed", sign: "danger" },
    11: { ar: "تغيير الباقة", en: "UnderChangingPlan", sign: "danger" },
    12: {
      ar: "فشل في تنفيذ الإشتراك",
      en: "OperationFailed",
      sign: "danger",
    },
    13: { ar: "منتهي", en: "Expired", sign: "danger" },
  };
  // if (code > 6) {
  //   if (code <= 12 && code >= 9) {
  //     return "Active";
  //   }
  //   return "Canceled";
  // } else if (code <= 3) {
  //   return "Active";
  // } else {
  //   return "Suspended";
  // }
  return enums[code];
};

const postSubscriptionTransactions = async (
  subscriptionId,
  type = "creation",
  couponCode = null
) => {
  const query =
    type == "creation"
      ? `servicePlanId=${subscriptionId}`
      : `subscriptionId=${subscriptionId}`;
  const endpoint = couponCode
    ? `/subscription-${type}-transactions?${query}&promoCode=${couponCode}`
    : `/subscription-${type}-transactions?${query}`;
  const response = await http.post(endpoint);
  return response.data;
};

const getSubscriptionTransactions = async (
  transactionId,
  type = "creation",
  couponCode = null
) => {
  const response = await http.get(
    `subscription-${type}-transactions/${transactionId}`
  );
  return response.data;
};
const addNonAppFeature = async (
  transactionID,
  featureStepId,
  type = "creation"
) => {
  const { data } = await http.post(
    `/subscription-${type}-transactions/${transactionID}/non-application-features/${featureStepId}`
  );
  return data;
};
const removeNonAppFeature = async (
  transactionId,
  featureStepId,
  type = "creation"
) => {
  const { data } = await http.delete(
    `/subscription-${type}-transactions/${transactionId}/non-application-features/${featureStepId}`
  );
  return data;
};
const updateQuotaAppFeature = async (
  transactionID,
  featureStepId,
  additionalCount,
  type = "creation"
) => {
  const { data } = await http.put(
    `/subscription-${type}-transactions/${transactionID}/quota-features/${featureStepId}?quota=${additionalCount}`
  );
  return data;
};
const addModuleFeature = async (
  transactionID,
  featureStepId,
  type = "creation"
) => {
  const { data } = await http.post(
    `/subscription-${type}-transactions/${transactionID}/module-features/${featureStepId} `
  );
  return data;
};

const removeModuleFeature = async (
  transactionID,
  featureStepId,
  type = "creation"
) => {
  const { data } = await http.delete(
    `/subscription-${type}-transactions/${transactionID}/module-features/${featureStepId} `
  );
  return data;
};

const postConfigurationSubscriptionTransactions = async (subscriptionId) => {
  const { data } = await http.post(
    `/subscription-configuration-transactions?subscriptionId=${subscriptionId}`
  );
  return data;
};

const updateSubscription = async (transactionId) => {
  const response = await http.put(
    `/subscriptions?subscriptionConfigurationTransactionId=${transactionId}`
  );
  return response;
};

const shareSubscriptionUrl = async (obj) => {
  const response = await http.post(
    `${process.env.VUE_APP_Email_Service_BaseUrl}`,
    { data: obj, name: "EmailService" }
  );
  return response;
};

const getApiCredentials = async (clientID, Method) => {
  const response = await http.post(process.env.VUE_APP_API_CRED_BASEURL, {
    clientID,
    Method,
  });
  console.log("this is response from api credentials:>>", response);
  return response;
};
// getApiCredentials();

const applyDiscountCoupon = async (transactionId, discountCoupon) => {
  const { data: transaction, status } = await http.put(
    `/subscription-creation-transactions/${transactionId}/discount-coupon-code?discountCode=${discountCoupon}`
  );
  return { transaction, status };
};
const searchForUrl = async (url) => {
  return await http.get(
    `/subscriber-identifier/valid?subscriberIdentifier=${url}`
  );
};
const changeSubscriptionUrl = async (subscriptionId, url) => {
  return await http.put(
    `/subscriptions/${subscriptionId}/subscriber-identifier?subscriberIdentifier=${url}`
  );
};

const getCurrentCompFeatures = async (subscriptionId) => {
  return await http.get(`/subscriptions/${subscriptionId}/comp-features`);
};
export default {
  getAllSubscriptions,
  getSubscription,
  mutateSubscription,
  updateSubscription,
  postSubscription,
  getServicePlan,
  getServicePlans,
  getStatus,
  postSubscriptionTransactions,
  getSubscriptionTransactions,
  addNonAppFeature,
  removeNonAppFeature,
  updateQuotaAppFeature,
  addModuleFeature,
  removeModuleFeature,
  getServicePlanDetails,
  shareSubscriptionUrl,
  getApiCredentials,
  applyDiscountCoupon,
  searchForUrl,
  changeSubscriptionUrl,
  getCurrentCompFeatures,
};
