<template>
  <tabs-section :tabs="3">
    <template #tab0>نبذة مختصرة</template>
    <template #tab1>البدء في الاستخدام و التنصيب</template>
    <template #tab2>البدء في الاستخدام و التنصيب</template>
    <template #tab0-body>
      <p>
        تقدم فودكس نظام إدارة مطعم متكامل قائم على السحابة على iPad باللغتين
        الإنجليزية والعربية. مع العديد من تطبيقات iOS الإضافية والبناء على
        المزيد ، تعمل الآلاف من المطاعم وشاحنات الطعام والمقاهي وسلاسل الوجبات
        السريعة في جميع أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات مع
        رواد المطعم.
      </p>
      <h4>فودكس + دفاتر</h4>
      <p>
        يوفر تكامل dafater طريقة لمزامنة مبيعات Foodics ومعاملات المخزون
        ومعاملات حساب المنزل مع حساب dafater الخاص بك. إنه يلغي الحاجة إلى
        الإدخالات اليدوية ويرسل البيانات بتنسيق يسمح لك باستخدام تقارير dafater
      </p>
    </template>
    <template #tab1-body>
      <p>
        تقدم فودكس نظام إدارة مطعم متكامل قائم على السحابة على iPad باللغتين
        الإنجليزية والعربية. مع العديد من تطبيقات iOS الإضافية والبناء على
        المزيد ، تعمل الآلاف من المطاعم وشاحنات الطعام والمقاهي وسلاسل الوجبات
        السريعة في جميع أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات مع
        رواد المطعم.
      </p>
      <h4>فودكس + دفاتر</h4>
      <p>
        يوفر تكامل dafater طريقة لمزامنة مبيعات Foodics ومعاملات المخزون
        ومعاملات حساب المنزل مع حساب dafater الخاص بك. إنه يلغي الحاجة إلى
        الإدخالات اليدوية ويرسل البيانات بتنسيق يسمح لك باستخدام تقارير dafater
      </p>
    </template>
    <template #tab2-body>
      <p>
        تقدم فودكس نظام إدارة مطعم متكامل قائم على السحابة على iPad باللغتين
        الإنجليزية والعربية. مع العديد من تطبيقات iOS الإضافية والبناء على
        المزيد ، تعمل الآلاف من المطاعم وشاحنات الطعام والمقاهي وسلاسل الوجبات
        السريعة في جميع أنحاء الشرق الأوسط على زيادة إيراداتها وبناء علاقات مع
        رواد المطعم.
      </p>
      <h4>فودكس + دفاتر</h4>
      <p>
        يوفر تكامل dafater طريقة لمزامنة مبيعات Foodics ومعاملات المخزون
        ومعاملات حساب المنزل مع حساب dafater الخاص بك. إنه يلغي الحاجة إلى
        الإدخالات اليدوية ويرسل البيانات بتنسيق يسمح لك باستخدام تقارير dafater
      </p>
    </template>
  </tabs-section>
</template>

<script>
import TabsSection from "@/components/common/TabsSection.vue";
export default {
  components: { TabsSection },
};
</script>

<style>
</style>