<template>
  <div class="subscribe">
    <form class="subscribe-form container">
      <!-- steps row -->
      <div class="subscribe-form__steps-row">
        <div class="steps-row__step active" ref="step1">
          <span class="steps-row__step-name step1" v-if="currentStep == 1"
            >إختر باقة</span
          >
        </div>
        <div class="steps-row__step" ref="step2">
          <span class="steps-row__step-name step2" v-if="currentStep == 2"
            >إختر الإضافات</span
          >
        </div>
        <div class="steps-row__step" ref="step3">
          <span class="steps-row__step-name step3" v-if="currentStep == 3"
            >الدفع</span
          >
        </div>
      </div>
      <!-- ////steps row -->

      <div class="subscribe-form__steps">
        <div class="subscribe-form__step" v-if="currentStep == 1">
          <subscribe-form-step-1
            @getSelectedPlanId="setPlanId"
            @setCouponCode="(c) => (couponCode = c)"
          />
        </div>
        <div class="subscribe-form__step" v-if="currentStep == 2">
          <subscribe-form-step-2
            :subPackage="subPackage"
            :servicePlan="servicePlanId"
            :transactionId="transactionId"
            :couponCode="couponCode"
            @backStep="goStep(currentStep - 1)"
            @forwardStep="goStep(currentStep + 1)"
            @setTransactionId="setTransactionId"
            @showThanksModal="showThanksModal"
            :subscriptionType="subscriptionType"
          />
        </div>
        <div class="subscribe-form__step" v-if="currentStep == 3">
          <subscribe-form-step-3
            :transactionId="transactionId"
            :payfortResponse="payfortResponse"
            @showThanksModal="showThanksModal"
            :subscriptionType="subscriptionType"
          />
        </div>
      </div>
      <div
        class="subscribe-form__footer"
        v-if="currentStep != 1 && subscriptionType == 'creation'"
      >
        <!-- v-if="transactionId" -->
        <a
          @click="goStep(currentStep - 1)"
          class="btn btn-grey btn-wide fs-20"
          v-if="currentStep != 1"
        >
          السابق
        </a>
        <!-- <a
          @click="goStep(currentStep + 1)"
          class="btn btn-info btn-wide fs-20"
          style="float: left"
          v-if="currentStep != 3"
        >
          التالي
        </a> -->
      </div>
    </form>
  </div>
  <m-modal
    class="modal-thank-you"
    v-show="showThankuModal"
    @close="redirectHome"
  >
    <template #header>
      <span style="display: none"></span>
    </template>
    <template #body>
      <img src="@/assets/images/thank-you.svg" alt="thank you" />
      <h3>تم إتمام عملية الدفع بنجاح</h3>
      <router-link
        :to="`/subscriptions/${Subscription.Id}`"
        class="btn btn-primary btn-wide"
        >ذهاب لاشتراكي</router-link
      >
    </template>
  </m-modal>
</template>

<script>
import SubscribeFormStep1 from "@/components/subscriptions/SubscribeFormStep1.vue";
import SubscribeFormStep2 from "@/components/subscriptions/SubscribeFormStep2.vue";
import SubscribeFormStep3 from "@/components/subscriptions/SubscribeFormStep3.vue";
import MModal from "@/components/common/MModal.vue";
export default {
  components: {
    SubscribeFormStep1,
    SubscribeFormStep2,
    SubscribeFormStep3,
    MModal,
  },
  data() {
    return {
      currentStep: 1,
      servicePlanId: null,
      transactionId: null,
      purchaseing: false,
      payfortResponse: {},
      subPackage: {
        name: "الباقة المتقدمة",
        price: "2,450",
      },
      showThankuModal: false,
      Subscription: {},
      subscriptionType: "creation",
      couponCode: null,
      allPlans: null,
    };
  },
  watch: {
    $route(newval) {
      const params = newval.params;
      console.log("this is params:>", params);
      if (
        params.id != this.servicePlanId ||
        params.transactionId != this.transactionId
      ) {
        this.redirectToStep();
      }
    },
    currentStep(to) {
      if (to == 1) {
        this.transactionId = null;
        this.servicePlanId = null;
        sessionStorage.removeItem("transaction");
        // this.$router.push({ path: `/subscribe/${this.$route.params.type}`});
      }
    },
  },
  mounted() {
    this.redirectToStep();
  },
  methods: {
    redirectToStep() {
      const query = this.$route.query;
      const params = this.$route.params;
      if (params.type == "configuration") {
        const transactionId = params.transactionId;
        this.transactionId = transactionId ? transactionId : null;
        this.purchaseing = true;
        this.goStep(2);
        this.subscriptionType = "configuration";
      } else if (
        params.type == "creation" &&
        Object.keys(params).includes("id") &&
        params.id != ""
      ) {
        if (params.transactionId != "") {
          this.setTransactionId(params.transactionId);
        } else {
          this.setPlanId(params.id);
        }
      }
      this.handleQueryRedirection();
    },
    handleQueryRedirection() {
      const query = this.$route.query;
      const params = this.$route.params;
      if (Object.keys(query).length !== 0) {
        this.subscriptionType = params.type;
        this.servicePlanId = params.id;
        this.transactionId = params.transactionId;
        this.purchaseing = true;
        this.payfortResponse = query;
        this.$router.replace({ query: null });
        this.goStep(2);
        this.goStep(3);
      }
    },
    setPlanId(Id) {
      this.goStep(2);
      if (this.servicePlanId !== Id) {
        this.servicePlanId = Id;
        this.goStep(2);
      }
    },
    setTransactionId(id) {
      const query = this.$route.query;
      this.$router.push({
        path: `/subscribe/${this.$route.params.type}/${this.$route.params.id}/${id}`,
        query: { ...query },
      });
      this.servicePlanId = this.$route.params.id;
      this.transactionId = id;
      this.goStep(2);
    },
    goStep(step) {
      if (!this.servicePlanId && !this.purchaseing) return;
      if (this.currentStep > step) {
        this.$refs[`step${step}`].classList.remove("prev-active");
        this.$refs[`step${step + 1}`].classList.remove("active");
      } else {
        this.$refs[`step${step - 1}`].classList.add("prev-active");
      }
      this.$refs[`step${step}`].classList.add("active");
      this.currentStep = step;
    },
    showThanksModal({ show, subscription }) {
      this.Subscription = subscription;
      this.showThankuModal = show;
    },
    redirectHome() {
      this.$router.push({ name: "Dashboard" });
    },
    // async getAllPlans() {
    //   this.allPlans = await SubscriptionService.getServicePlans();
    // },
  },
};
</script>