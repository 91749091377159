<template>
  <form @submit.prevent="submitform()">
    <span style="color: red">{{ $route.query.response_message }}</span>
    <div class="form-row grid">
      <div class="form-group">
        <label>رمز الحماية(cvv)</label>
        <input
          :class="'form-control ' + (cardErrors.cardCvc ? 'error' : '')"
          name="card_security_code"
          ref="cardCvc"
          :data-error="cardErrors.cardCvc ? true : false"
          v-model="cardCvc"
          v-cardformat:formatCardCvc
          placeholder="CVV"
        />
        <!-- validation error -->
        <div v-if="cardErrors.cardCvc" class="error">
          <small>{{ cardErrors.cardCvc }}</small>
        </div>
      </div>
    </div>
    <div class="submit" style="text-align: center; margin-top: 2rem">
      <button type="button" class="btn btn-info btn-wide" @click="submitform()">
        أضف
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      cardCvc: null,
      cardErrors: {},
      errorMessage: false,
    };
  },
  watch: {
    cardCvc(newval) {
      this.cardErrors.cardCvc = null;
    },
  },
  mounted() {},
  methods: {
    submitform() {
      if (this.validateForm()) {
        this.$emit("getCVC", this.cardCvc);
      }
    },
    formChange(e) {
      // console.log(e);
    },
    validateForm() {
      this.cardErrors = {};
      // validate card CVC
      if (!this.$cardFormat.validateCardCVC(this.cardCvc)) {
        this.cardErrors.cardCvc = "Invalid CVC.";
      }
      const isValid = Object.keys(this.cardErrors).length === 0;
      return isValid;
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: red;
}
input.error {
  border-color: red;
}
</style>