import { createRouter, createWebHistory } from "vue-router";

// Gaurds
import { registerGuard } from "./Guard";
import { registerTitleToPage } from "./Pages";

// routes
import SubscriptionsRoute from "../pages/SubscriptionsRoute.vue";
import WelcomeRoute from "../pages/WelcomeRoute.vue";

// pages
import DashboardPage from "../pages/DashboardPage.vue";
import SubscriptionsPage from "../pages/SubscriptionsPage.vue";
import SubscriptionDetailsPage from "../pages/SubscriptionDetailsPage.vue";
import ManageApiPage from "../pages/ManageApiPage.vue";
import ChangeSubscriptionUrl from "@/pages/ChangeSubscriptionUrl.vue";
import SubscribePage from "../pages/SubscribePage.vue";
import ProfilePage from "../pages/ProfilePage.vue";
import InvoicesPage from "../pages/InvoicesPage.vue";
import PaymentsPage from "../pages/PaymentsPage.vue";
import AddonsPage from "../pages/AddonsPage.vue";
import AddonDetailsPage from "../pages/AddonDetailsPage.vue";
import PayDebt from "../pages/PayDebt.vue";
import LogoutPage from "../pages/LogoutPage.vue";
import NotFound from "../pages/NotFound.vue";

// this is exception and i'll roll it back after create the form
// import SignUpView from "../components/welcome/SignUpView.vue";
import LoginView from "../components/welcome/LoginView.vue";

const routes = [
  // {
  //   path: "/welcome",
  //   name: "Welcome",
  //   component: WelcomeRoute,
  //   children: [
  //     {
  //       path: "/login",
  //       component: LoginView,
  //     }
  //   ],
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardPage,
    meta: {
      layout: "dashboard",
      requiresAuth: true,
      title: "Go Dafater - لوحة التحكم",
    },
  },
  {
    path: "/subscribe",
    name: "Subscribe",
    // meta: { layout: "dashboard" },
    component: SubscriptionsRoute,
    children: [
      {
        path: "/subscribe/:type/:id?/:transactionId?",
        name: "SubscribePage",
        component: SubscribePage,
        meta: {
          requiresAuth: true,
          title: "Go Dafater - عملية الاشتراك",
        },
      },
    ],
  },
  {
    path: "/subscriptions",
    name: "SubscriptionsRoute",
    meta: { layout: "dashboard" },
    component: SubscriptionsRoute,
    children: [
      {
        path: "/subscriptions",
        name: "Subscriptions",
        meta: {
          layout: "dashboard",
          requiresAuth: true,
          title: "Go Dafater - الإشتراكات",
        },
        component: SubscriptionsPage,
      },
      {
        path: "/subscriptions/:id",
        name: "SubscriptionDetails",
        meta: {
          layout: "dashboard",
          requiresAuth: true,
          title: "Go Dafater - تفاصيل الإشتراك",
        },
        component: SubscriptionDetailsPage,
      },
      {
        path: "/subscriptions/manage-api/:id",
        name: "ManageApi",
        meta: {
          layout: "dashboard",
          requiresAuth: true,
          title: "Go Dafater - إدارة الـ Api",
        },
        component: ManageApiPage,
      },
      {
        path: "/subscriptions/change-url/:id",
        name: "ChangeSubscriptionUrl",
        meta: {
          layout: "dashboard",
          requiresAuth: true,
          title: "Go Dafater - تغيير رابط الاشتراك",
        },
        component: ChangeSubscriptionUrl,
      },
      {
        path: "/subscriptions/profile",
        name: "Profile",
        component: ProfilePage,
        meta: {
          layout: "dashboard",
          requiresAuth: true,
          title: "Go Dafater - الحساب",
        },
      },
    ],
  },
  {
    path: "/invoices",
    name: "Invoices",
    meta: {
      layout: "dashboard",
      requiresAuth: true,
      title: "Go Dafater - الفواتير",
    },
    component: InvoicesPage,
  },
  {
    path: "/payments",
    name: "Payment",
    meta: {
      layout: "dashboard",
      requiresAuth: true,
      title: "Go Dafater - خيارات الدفع",
    },
    component: PaymentsPage,
  },
  {
    path: "/addons",
    name: "Addons",
    meta: {
      layout: "dashboard",
      requiresAuth: true,
      title: "Go Dafater - سوق الإضافات",
    },
    component: AddonsPage,
  },
  {
    path: "/addons/:id",
    name: "AddonDetails",
    meta: {
      layout: "dashboard",
      requiresAuth: true,
      title: "Go Dafater - تفاصيل الإضافة",
    },
    component: AddonDetailsPage,
  },
  {
    path: "/paydebt",
    name: "PayDebt",
    meta: { requiresAuth: true, title: "Go Dafater - ادفع مديوناتك" },
    component: PayDebt,
  },
  {
    path: "/",
    name: "Dashboard",
    meta: {
      layout: "dashboard",
      requiresAuth: true,
      title: "Go Dafater - لوحة التحكم",
    },
    component: DashboardPage,
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutPage,
  },
  {
    path: "/not-found",
    name: "NotFound",
    meta: { title: "Go Dafater - Not Found" },
    component: NotFound,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/not-found",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

registerGuard(router);
registerTitleToPage(router);

export default router;
