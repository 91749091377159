<template>
  <div :class="collapsed ? 'sidebar collapsed' : 'sidebar'">
    <a @click="$emit('side-toggle')" class="sidebar__side-toggle">
      <i class="fal fa-times"></i>
    </a>
    <router-link to="/" class="sidebar__logo">
      <img v-if="!collapsed" src="@/assets/images/logo.png" alt="Dafater Sa" />
      <img
        v-if="collapsed"
        src="@/assets/images/small-logo.png"
        alt="Dafater Sa"
        style="width: 50%"
    />
    </router-link>
    <div class="sidebar__content">
      <!-- <span v-if="!isAuthed">please sign in or up</span> for testing is authed  -->
      <ul class="sidebar__menu list list-ver">
        <li v-for="(link, i) in links" :key="i">
          <router-link :to="link.target" class="sidebar__menu-btn">
            <template v-html="link.icon" v-hoist />
            <span>{{ link.name }}</span>
          </router-link>
        </li>
        <!-- <hr ref="btnBg" /> -->
      </ul>
      <div class="sidebar__help-btn version-bar">{{ version }} Version</div>
    </div>
  </div>
</template>

<script>
// import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
export default {
  props: {
    collapsed: Boolean,
  },

  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      isAuthed: false,
      links: [
        {
          name: "لوحة التحكم",
          icon: '<i class="fas fa-th-large"></i>',
          target: "/",
        },
        {
          name: "اشتراكاتي",
          icon: '<i class="fas fa-award"></i>',
          target: "/subscriptions",
        },
        {
          name: "فواتيري",
          icon: '<i class="fas fa-file-invoice"></i>',
          target: "/invoices",
        },
        {
          name: "طرق الدفع",
          icon: '<i class="fas fa-archive"></i>',
          target: "/payments",
        },
        // {
        //   name: "سوق الإضافات",
        //   icon: '<i class="fas fa-file-plus"></i>',
        //   target: "/addons",
        // },
      ],
      orderOfActive: 0,
      activeLink: "",
      path: "",
    };
  },
  mounted() {
    // this.activeLink = this.getActiveElement();
    // this.orderOfActive = +this.activeLink.getAttribute("order");
    // this.$refs.btnBg.style.top = `${this.orderOfActive * 8 + 1}rem`;
    // for testing is authed
    // const isAuthed = useIsAuthenticated();
    // this.isAuthed = isAuthed;
  },
  watch: {
    
    // $route: function (to) {
    // fix this first
    // console.log(to);
    // this.path = to.path;
    // this.activeLink = this.getActiveElement();
    // this.orderOfActive = this.links.findIndex(
    //   (link) => link.target == this.path
    // );
    // this.$refs.btnBg.style.top = `${this.orderOfActive * 8 + 1}rem`;
    // },
  },
  methods: {
    // hoverIn(i) {
    //   this.$refs.btnBg.style.top = `${i * 8 + 1}rem`;
    //   if (i !== this.orderOfActive) {
    //     this.activeLink.classList.add("not-hoverd");
    //   }
    //   console.log("this is active link:>", this.activeLink);
    // },
    // hoverOut() {
    //   const i = this.orderOfActive;
    //   this.$refs.btnBg.style.top = `${i * 8 + 1}rem`;
    //   this.activeLink.classList.remove("not-hoverd");
    // },
    // getActiveElement() {
    //   return this.$el.querySelector(".router-link-active");
    // },
  },
};
</script>

<style>
.version-bar {
  width: 100%;
  padding: 1rem 1.5rem;
  color: #808080;
}
</style>